import axios from "axios"
import React, { useEffect, useState, useRef } from "react";
import { baseUrl } from "../baseURL";
import "../js/productDetails";
import ReactStars from "react-rating-stars-component";
import ProductDetailsSlider from "./ProductdetailsSlider";
import ProductSlider from "./ProductSliderComponent";
import { Helmet } from 'react-helmet';
export default function ProductDetails(props) {
  // Initital States
  const [productType, setPT] = useState();
  const [sample_price, setsample] = useState();
  const [Avg, setAvg] = useState('a');
  const [RvCount, setRvCount] = useState(0);
  const [productPrice, setPP] = useState(null);
  const [productMRP, setMRP] = useState(null);
  const [Tag, setTag] = useState('');
  const sample = 1;
  const s_qty = 1;
  const Items = 5;
  const [productDetail, setPD] = useState([]);
  const [Reviews, setReviews] = useState([]);
  const ref = useRef(null);
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  // Getting id from page url
  const api_id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    async function fetchData() {
      try {
        // alert("Product Details",api_id)
        const data = await axios(
          baseUrl + "Apicontroller/get_all_products_detail/" + api_id
        );
        console.log("product details", data.data.data);
        setMRP(data.data.data[0].product_type[0].MRP);
        setPP(data.data.data[0].product_type[0].Price);
        setPT(data.data.data[0].product_type[0].type_id);
        setsample(data.data.data[0].product_type[0].sample_price);
        setPD(data.data.data);
        setReviews(data.data.reviews);
        setAvg(data.data.avg);
        setRvCount(data.data.rv_count);
        setTag(data.data.data[0].tag);
        // alert(Avg)
      } catch {
        console.log("Issue in get product detail.");
      }
    }
    fetchData();
  }, [api_id]);
  const [qty, setQty] = useState(1);
  const setQuantity = (e) => {
    let value = parseInt(e.target.value);
    const regExQTY = /^[\d]+/i;
    if (regExQTY.test(value)) {
      setQty(value);
    }
  };
  const decrementQTY = () => {
    let decreasedValue = qty - 1;
    if (decreasedValue > 0) {
      setQty(decreasedValue);
    }
  };
  const incrementQTY = () => {
    let increasedValue = qty + 1;
    setQty(increasedValue);
  };
  const [reviewToShow, setReviewsToShow] = useState(Items);
  const showMoreReviews = () => {
    let totalNewReview = reviewToShow + Items;
    let num = Reviews.length;
    if (totalNewReview < num) {
      setReviewsToShow(totalNewReview);
    } else {
      setReviewsToShow(num);
      document.getElementById("showmorereview").style.display = "none";
    }
  };
  console.log("detail =====");
  const setProductType = (e) => {
    setPT(productDetail[0].product_type[e.target.value].type_id);
    setPP(productDetail[0].product_type[e.target.value].Price);
    setMRP(productDetail[0].product_type[e.target.value].MRP);
    setsample(productDetail[0].product_type[e.target.value].sample_price);
  };
  const [relatedproducts, setRP] = useState([]);
  useEffect(() => {
    async function fetchRelatedProducts() {
      try {
        const response = await axios(
          baseUrl + "Apicontroller/related_products/" + api_id
        );
        // console.log("Related product",response.data)
        if (response.status === 200) {
          console.log("Related", response.data);
          setRP(response.data.data);
        }
      } catch {
        console.log("Issue in fetching related products data");
      }
    }
    fetchRelatedProducts();
  }, [api_id]);
  const [readMoreShippingAndReturns, setReadMoreShippingAndReturns] = useState(false);
  const [readMoreArchitect, setReadMoreArchitect] = useState(false);
  const [readMoreWhyCreateSpace, setReadMoreWhyCreateSpace] = useState(false);
  const [readMoreContactShowroom, setReadMoreContactShowroom] = useState(false);
  const [readMoreProductDescription, setReadMoreProductDescription] = useState(false);
  const [readMoremaintenance, setReadMoremaintenance] = useState(false);
  const [readMorewarranty, setReadMorewarranty] = useState(false);
  const whyCreateSpaceContent = <>Our Essential Elements- <br />Quality <br />Affordability <br />Functionality <br />Aesthetic appeal <br />Create Spaces was started with the sole purpose of providing luxury at affordable prices. <br />Our theory is to provide a piece that not only looks good but is comfortable and will last long. We use high quality raw materials all the way through whether visible in the furniture piece or not. The few examples of such things are using high quality dense foam, 100% virgin braided water repellent rope, fabric with high technical specifications (Martindale - 35000 rubs or above) & luxurious feel. <br />Materials & Care- <br />Metal Care - Just use a soft cloth to wipe dust off. Use a liquid cleaner to remove any imprints. Products sold for outdoor use have special coating that can be used outdoors. <br />Fabric - All the fabric is tested and has Martindale above 35000 rubs. It can be cleaned by soap water and clean cloth. <br />Foam - We use high density foam that ensures good back support ensuring a healthy lifestyle. <br />Wood - All the wood used is chemically treated to remove any bacteria. Any wood except Teak Wood should be used indoors only. <br />Variation- Slight variation can always occur from the photos due to lighting conditions, batch difference etc.</>;
  const whyCreateSpaceContentShort = <>Our Essential Elements- <br />Quality <br />Affordability <br />Functionality</>;
  const ArchitectContent = <>We can provide free steel and wood swatches to architects to make there work on projects easier. We also supply to independent furniture showrooms, and they can contact us on WhatsApp for any further queries.We also provide customised solutions for hotel and cafe furniture items.</>;
  const ArchitectContentShort = <>We can provide free steel and wood swatches to architects to make there work on projects easier</>;
  const shippingAndReturnsContent = <>
    <ul className="warranty">
      <li>Delivering Many Happy Customers Throughout India We will send an automatic email with the dispatch details and tracking link. Orders can be cancelled before dispatch. </li>
      <li>Free Returns of goods will be accepted only for Damaged/Defective products.</li>
      <li>If you are not happy with the product we can accept a return for a slight charge to set off postage and restocking charges.</li>
      <li>Replacement shall be considered only if the damage/defect is reported to us with photographic evidence in writing within 3 working days from the receipt of the product.</li>
      <li>To return or replace the goods, customer has to pack the goods on the same package on which the goods were received and a hard copy of the document provided by the customer executive.</li>
      <li>A refund will be provided in case a replacement for the same product is not available.</li>
      <li>We normally process the refund in 7-9 days from the date of receipt of material at our end</li>
    </ul>
    <b>Any customised products cannot be returned.</b>
  </>
  const shippingAndReturnsContentShort = <>Delivering Many Happy Customers Throughout India We will send an automatic email with the</>
  const ContactShowroomContent = <>We are based in Jaipur and deliver to most postcodes throughout India from our manufacturing unit in Jaipur.<br />Our factory and display is located in Jaipur and can be visited by appointment only. Live photos and videos can be shared on request.<br /><b>Address- G-77, RICCO Industrial Area, Bagru, Jaipur, 303007.</b><br />We also have an office at Jaipur city centre which can be visited. The location can be found on google maps for the city centre office.<br />Our biggest markets are Delhi NCR, Bangalore, Hyderabad, Mumbai, Kochi, Pune, Jaipur & Chennai.</>;
  const ContactShowroomContentShort = <>We are based in Jaipur and deliver to most postcodes throughout India from our manufacturing unit in Jaipur.</>;
  const maintenanceContent = <>
    <ul className="warranty">
      <li>CARE FOR YOUR WOOD: Each piece in our collection is crafted from solid hardwood harvested from responsibly managed forest. Natural variations in the wood grain and coloring are to be expected. In order to keep your product looking at its best follow the following care instructions and avoid direct sunlight.</li>
      <li>IMPORTED ITALIAN KERAMIK MARBLE - These are stain, scratch & heat resistant and do not require re-polishing like natural marble.</li>
      <li>MARBLE - It comes with a protective coating but due to nature of natural marble this will wear off and repolishing might be needed every 1-2 years.</li>
      <li>CLEANING: Clean with damp soft cloth only, avoid using harsh chemicals or abrasives as these may damage the surface. Wipe up any spills quickly Use coasters on dining and coffee table and avoid direct contact of hot and cold items on the surface.</li>
      NOTE: Slight variation in color may occur, test on an inconspicuous area first.
      <li> Avoid using harsh chemical cleaners or polishes on your new item, to keep it clean just a quick dust with soft cotton towel or cloth will do the trick and will avoid damaging the finish or scratching the surface.</li>
      <li> METAL CARE: Avoid using harsh chemical cleaners or polishes on your new item, to keep it clean just a quick dust with a soft cotton towel or cloth will do the trick and avoid damaging the finish or scratching the surface. Smudges or smears can be removed by using a window cleaner or soft paper towel . Product are made for indoor use only.</li>
    </ul>
  </>
  const maintenanceContentShort = <>CARE FOR YOUR WOOD: Each piece in our collection is crafted from solid</>
  const warrantyContent = <>This product comes with a 3-year warranty which covers manufacturing defects.<br />
    <ul className="warranty">
      <br />  <li> IMPORTED ITALIAN KERAMIK MARBLE - These are stain, scratch & heat resistant and do not require re-polishing like natural marble. Manufacturing Defects can be checked and should be reported within 3 days of delivery.
      </li>
    </ul>This limited warranty does not apply to: <br />
    <ul className="warranty">
      <li>Small cuts, scratches or damage during the cleaning process<br /></li>
      <li>Loss of coating on metal due to improper cleaning & handling.</li>
      <li>Damaged caused by the incorrect installation of a product<br /></li>
      <li>Cracks during the random displacement<br /> </li>
      <li>Product kept in contact to direct sunlight and water, resulting in decaying of wood or metal.<br /></li>
      <li>With time solid woods are encountered by minor cracks, called as checks, which are not included in faults and warranty as these checks do not hamper the life of a product.<br /></li>
      <li>The Shades displayed across the range of products may slightly vary from the actual color. This may happen due to multiple settings in your monitor or viewing device (Laptop/ Mobile/Tab) or impact of our digital photoshoots. We request you to consider these minor variations as it would not be considered as a defect and hence would not be eligible for return.<br /></li>
    </ul>
    <br /> The warranty will not apply where:
    <ol type="a" className="warranty">
      <li> The alleged defect in the Product is within acceptable industry variances such as movement of up to 4% in some fabrics;</li>
      <li> The Product has been used for purposes other than those for which it was designed;</li>
      <li> The customer failed to clean, use, and maintain the Product properly;</li>
      <li> The product has been subjected to abnormal conditions such as the environment, temperature, water, fire, humidity, pressure, stress, or something similar;</li>
      <li> The flaw was caused by abuse, misuse, neglect, or an accident; or</li>
      <li> The flaw was caused by chemical damage.</li>
    </ol>
    <br />  The warranty does not extend to:
    <ol type="a" className="warranty">
      <li> Damage or defects caused by normal wear and tear;</li>
      <li> Damage or defects caused by after treatments; or</li>
      <li> Minor variations in colour.</li>
      (All our Italian Keramik Marble is like real stone with added benefits of longer life, stain resistance, heat resistance & scratch resistant unlike real marble or plastic marble).
    </ol>
    <ul className="warranty">
      <br />  <li>Customisation can be done to real marble for no additional cost.</li>
      <li>We provide a variety of coatings from pvd coating, electroplating, polishing to special powder coating. </li>
      <li>PVD coating & Electroplating (gloss gold & rose gold) is said to have the highest life but all coatings have a lifetime after which it can wear off. </li>
      <li>Powder coating (matt gold & matt black) has a benefit that even though it might have slightly lower life than pvd coating it can be repaired easily at home by metal spray paint at minimal cost to give it a new look again. This makes powder coating the most practical choice.</li>
    </ul>
  </>
  const warrantyContentShort = <>This product comes with a 3-year warranty which covers manufacturing defects.</>
  return (
    <section>
      <button
        className={"addtoCart-mb btn p-3"}
        style={{
          background: "#6B9CB3",
          fontWeight: "500",
          fontSize: "13px",
          color: "white",
        }}
        onClick={() =>
          props.addingToCartFunction(
            productDetail[0].product_id,
            qty,
            productType
          )
        }
      >
        ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
      </button>
      <section className="mt-5 pt-md-5 pt-lg-5 pt-sm-5 mt-sm-5 details_page">
        <div className="">
          <div className="row">
            {productDetail
              ? productDetail.map((elem, i) => {
                var imgarry = [elem.productimage, elem.productimage1, elem.productimage2, elem.productimage3, elem.productimage4, elem.productimage5, elem.video];
                const array22 = imgarry.filter(
                  (item) => item !== Array.isArray(item) && item.length
                );
                return (
                  <>
                    <Helmet>
                      <title>{elem.title?elem.title:'Create Spaces'}</title>
                      <meta name="description" content={elem.description?elem.description:''} />
                      <meta name="keywords" content={elem.keyword?elem.keyword:''}/>
                    </Helmet>
                    <div className="col-md-8 p-0 mb-5 ">
                      <ProductDetailsSlider
                        productImages={array22}
                      />
                    </div>
                    <div className="col-md-4 mr-auto det_text gapt fixedElement" style={{ padding: "0 30px" }} >
                      <div className="col-md-12 d-flex p-0 ">
                        <h4 >
                          {elem.productname}
                        </h4>
                      </div>
                      <div className="col-md-12 p-0 d-flex ">
                        <small
                          className=""
                          style={{
                            fontSize: "1rem",
                            fontWeight: "300",
                          }}
                        >
                          {elem.model_no}
                        </small>
                      </div>
                      <div className="col-md-12 p-0 d-flex justify-content-between  mt-1 ">
                        <small
                          className=""
                          style={{
                            fontSize: "1.27rem",
                            fontWeight: "600",
                          }}
                        >
                          <i className="fa fa-inr "></i> {productPrice}
                        </small>
                        {Tag ?
                          <span className="ship_tag" style={{background:"#000000"}}>{Tag}</span>
                          : null}
                      </div>
                      {/* <div className="qty-block d-flex mt-3 mb-4"> */}
                      <div className="col-md-12 p-0 mt-2  ">
                        <div className="col-md-6 p-0 d-flex mb-2">
                          <label
                            for="qty"
                            style={{
                              fontSize: "1.1rem",
                              padding: "4px 14px 0px 0px",
                            }}
                          >
                            Qty:
                          </label>
                          <div className="cart-plus-minus d-flex" style={{width:"100%"}}>
                            <span
                              type="button"
                              className="btn btn-primary cart-minus-1"
                              onClick={decrementQTY}
                              style={{
                                background: "white",
                                color: "gray",
                                border: "1px solid #e8e8e8",
                                borderRadius: "unset",
                              }}
                            >
                              -
                            </span>
                            <input
                              style={{ height: "42px", textAlign: "center", padding: "0px", width: "100%" }}
                              type="text"
                              name="qty"
                              id="qty"
                              maxlength="12"
                              value={"QTY " + qty}
                              title="Qty"
                              onChange={setQuantity}
                              className="input-text qty"
                            />
                            <span
                              type="button"
                              className="btn btn-primary cart-plus-1"
                              onClick={incrementQTY}
                              style={{
                                background: "white",
                                color: "gray",
                                borderRadius: "unset",
                                border: "1px solid #e8e8e8",
                              }}
                            >
                              +
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 p-0 d-flex " >
                          <label
                            for="type"
                            style={{
                              fontSize: "1.1rem",
                              padding: "4px 10px 0px 0px",
                            }}
                          >
                            Type:
                          </label>
                          <select
                            className="form-control d-flex "
                            aria-label="Default select example"
                            onChange={(e) => setProductType(e)}
                            style={{
                              marginright: "15px", borderColor: "#fff",
                              boxShadow: "0 0 0 0.2rem rgb(229 231 234 / 25%)"
                            }}
                          >
                            {elem
                              ? elem.product_type.map((elem, i) => {
                                return (
                                  <option
                                    value={i}
                                    select={i === 0 ? true : false}
                                  >
                                    {elem.type_name}
                                  </option>
                                );
                              })
                              : null}
                          </select>
                        </div>
                      </div>
                      {!isNaN(sample_price) && (sample_price!=0) &&
                        <div className="col-md-8 p-0 d-flex mb-3 mt-3 ml1r">
                          <button
                            className={"btn icn_label"}
                            style={{
                              background: "white",
                              color: "black",
                              borderRadius: "unset",
                              border: "1px solid #e8e8e8",
                              padding: "1rem,1rem",
                              // width:"50px"
                              fontSize: "12px"
                            }}
                            onClick={() =>
                              props.addingToCartFunction(
                                productDetail[0].product_id,
                                s_qty,
                                productType,
                                sample
                              )
                            }
                          >Fabric Sample - <i className="fa fa-inr "></i>{sample_price} </button>
                        </div>
                      }
                      <div className="d-flex align-items-center ml1r" onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {Avg !== 'a' ?
                          <>
                            <ReactStars
                              // value={Avg}
                              value={Avg}
                              isHalf={true}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                            />
                            <span className="ml-2">{RvCount} Reviews <i className="fa fa-angle-down ml-1"></i></span>
                          </>
                          : null}
                      </div>
                      <div className="col-md-12 p-0 addtoCart">
                        <div>
                          <button
                            className={"btn ml-auto "}
                            style={{
                              background: "rgb(0, 0, 0)",
                              fontWeight: "500",
                              fontSize: "13px",
                              color: "white",
                              // minWidth: "217px",
                              width: "100%",
                              "border-radius": "11px",
                              height: "52px",
                              boxShadow: "none"
                            }}
                            onClick={() =>
                              props.addingToCartFunction(
                                productDetail[0].product_id,
                                qty,
                                productType
                              )
                            }
                          >
                            ADD TO CART{" "}
                            <i className="fa fa-shopping-bag ml-2"></i>
                          </button>
                        </div>
                        {localStorage.getItem("create_outdoor_email") ? (
                          <div className="col-md-12 p-0 mt-3 ml-auto">
                            <button
                              className="btalign btn  "
                              style={{
                                background: "rgb(0, 0, 0)",
                                fontWeight: "500",
                                fontSize: "13px",
                                color: "white",
                                width: "100%",
                                "border-radius": "11px",
                                height: "52px",
                              }}
                              onClick={() =>
                                props.addingToWishlist(
                                  productDetail[0].product_id,
                                  qty,
                                  productType
                                )
                              }
                            >
                              ADD TO WISHLIST
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className="accordion "
                        id="accordionExample"
                      >
                        <div
                          className="card cardtext"
                          style={{
                            border: "0",
                            borderRadius: "0",
                            outline: "0",
                            borderTop: "1px solid rgba(0,0,0,.125)",
                            borderBottom: "1px solid rgba(0,0,0,.125)",
                            // width:"380px"
                          }}
                        >
                          <div
                            className="card-header"
                            id="headingOne"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              background: "white",
                            }}
                          >
                            <h2
                              className="mb-0"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                                marginLeft: "-2rem"
                              }}
                            >
                              <button
                                className="btn justify-content-between d-flex w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                  boxShadow: "none"
                                }}
                              >
                                <span>Product Description</span>
                                <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse "
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div
                              className="card-body"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                              }}
                            // style={{paddingLeft: '-2rem!important', paddingTop: '0', padding:'unset',fontSize:'14px'}}
                            >
                              {/* {readMoreProductDescription? elem.productdescription.includes("<p>")?elem.productdescription.replace(/<p>|&nbsp;|<\/p>?/gi, ""):elem.productdescription: elem.productdescription.includes("<p>")?elem.productdescription.replace(/<p>|&nbsp;|<\/p>?/gi, "").substring(0, 10000):elem.productdescription.substring(0, 10000)}
                              {" "}{elem.productdescription>107?<span onClick={()=>setReadMoreProductDescription(!readMoreProductDescription)} style={{letterSpacing: '1px', textDecoration: "underline", cursor: "pointer"}}><b>{!readMoreProductDescription?"Read more":"Read less"}</b></span>:null} */}
                              {/* dangerouslySetInnerHTML={{ __html: elem.productdescription }} */}
                              <div dangerouslySetInnerHTML={{ __html: elem.productdescription }} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="card cardtext"
                          style={{
                            border: "0",
                            borderRadius: "0",
                            outline: "0",
                            borderTop: "1px solid rgba(0,0,0,.125)",
                            borderBottom: "1px solid rgba(0,0,0,.125)",
                            // width:"380px"
                          }}
                        >
                          <div
                            className="card-header"
                            id="headingTwoo"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              background: "white",
                            }}
                          >
                            <h2
                              className="mb-0"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                              }}
                            >
                              <button
                                className="btn justify-content-between d-flex w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseTwoo"
                                aria-expanded="true"
                                aria-controls="collapseTwoo"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                  boxShadow: "none",
                                }}
                              >
                                <span style={{ marginLeft: "-2rem" }}>Contact Us & Showroom Visit Enquiry</span>
                                <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseTwoo"
                            className="collapse"
                            aria-labelledby="headingTwoo"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body" style={{ paddingLeft: '-2rem', paddingTop: '0', padding: 'unset', fontSize: '14px' }}>
                              {readMoreContactShowroom ? ContactShowroomContent : ContactShowroomContentShort}
                              {" "}<span onClick={() => setReadMoreContactShowroom(!readMoreContactShowroom)} style={{ letterSpacing: '2px', textDecoration: "underline", cursor: "pointer" }}><b>{!readMoreContactShowroom ? "Read more" : "Read less"}</b></span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card cardtext"
                          style={{
                            border: "0",
                            borderRadius: "0",
                            outline: "0",
                            borderTop: "1px solid rgba(0,0,0,.125)",
                            borderBottom: "1px solid rgba(0,0,0,.125)",
                            // width:"380px"
                          }}
                        >
                          <div
                            className="card-header"
                            id="headingTwo"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              background: "white",
                            }}
                          >
                            <h2
                              className="mb-0"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                              }}
                            >
                              <button
                                className="btn justify-content-between d-flex w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                  boxShadow: "none",
                                }}
                              >
                                <span style={{ marginLeft: "-2rem" }}>Why Create Space?</span>
                                <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseTwo"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body" style={{ paddingLeft: '-2rem', paddingTop: '0', padding: 'unset', fontSize: '14px', fontWeight: 400, color: '#212a2f' }}>
                              {readMoreWhyCreateSpace ? whyCreateSpaceContent : whyCreateSpaceContentShort}
                              {" "}<span onClick={() => setReadMoreWhyCreateSpace(!readMoreWhyCreateSpace)} style={{ letterSpacing: '2px', textDecoration: "underline", cursor: "pointer" }}><b>{!readMoreWhyCreateSpace ? "Read more" : "Read less"}</b></span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card cardtext"
                          style={{
                            border: "0",
                            borderRadius: "0",
                            outline: "0",
                            borderTop: "1px solid rgba(0,0,0,.125)",
                            borderBottom: "1px solid rgba(0,0,0,.125)",
                            // width:"380px"
                          }}
                        >
                          <div
                            className="card-header"
                            id="headingThree"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              background: "white",
                            }}
                          >
                            <h2
                              className="mb-0"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                              }}
                            >
                              <button
                                className="btn justify-content-between d-flex w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="true"
                                aria-controls="collapseThree"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                  boxShadow: "none",
                                }}
                              >
                                <span ref={ref} style={{ marginLeft: "-2rem" }}>Product Specifications</span>
                                <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body" style={{ paddingLeft: '-2rem', paddingTop: '0', padding: 'unset', fontSize: '14px' }}>
                              {/* {readMoreWhyCreateSpace? whyCreateSpaceContent: whyCreateSpaceContentShort}
                              {" "}<span onClick={()=>setReadMoreWhyCreateSpace(!readMoreWhyCreateSpace)} style={{letterSpacing: '2px', textDecoration: "underline", cursor: "pointer"}}><b>{!readMoreWhyCreateSpace?"Read more":"Read less"}</b></span> */}
                              <div dangerouslySetInnerHTML={{ __html: elem.specification }} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="card cardtext"
                          style={{
                            border: "0",
                            borderRadius: "0",
                            outline: "0",
                            borderTop: "1px solid rgba(0,0,0,.125)",
                            borderBottom: "1px solid rgba(0,0,0,.125)",
                            // width:"380px"
                          }}
                        >
                          <div
                            className="card-header"
                            id="headingNew"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              background: "white",
                            }}
                          >
                            <h2
                              className="mb-0"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                              }}
                            >
                              <button
                                className="btn justify-content-between d-flex w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseNew"
                                aria-expanded="true"
                                aria-controls="collapseNew"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                  boxShadow: "none",
                                }}
                              >
                                <span ref={ref} style={{ marginLeft: "-2rem" }}>Architects & Business Customers</span>
                                <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseNew"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordionExample"
                          >
                           <div className="card-body" style={{ paddingLeft: '-2rem', paddingTop: '0', padding: 'unset', fontSize: '14px' }}>
                              {readMoreArchitect ? ArchitectContent : ArchitectContentShort}
                              {" "}<span onClick={() => setReadMoreArchitect(!readMoreArchitect)} style={{ letterSpacing: '2px', textDecoration: "underline", cursor: "pointer" }}><b>{!readMoreArchitect ? "Read more" : "Read less"}</b></span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card cardtext"
                          style={{
                            border: "0",
                            borderRadius: "0",
                            outline: "0",
                            borderTop: "1px solid rgba(0,0,0,.125)",
                            // width:"380px"
                          }}
                        >
                          <div
                            className="card-header"
                            id="headingFour"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              background: "white",
                            }}
                          >
                            <h2
                              className="mb-0"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                              }}
                            >
                              <button
                                className="btn justify-content-between d-flex w-100"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseFour"
                                aria-expanded="true"
                                aria-controls="collapseThree"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                  boxShadow: "none"
                                }}
                              >
                                <span style={{ marginLeft: "-2rem" }}>Shipping & Returns</span>
                                <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                              </button>
                            </h2>
                          </div>
                          <div
                            id="collapseFour"
                            className="collapse"
                            aria-labelledby="headingFour"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body" style={{ paddingLeft: '-2rem', paddingTop: '0', padding: 'unset', fontSize: '14px' }}>
                              {readMoreShippingAndReturns ? shippingAndReturnsContent : shippingAndReturnsContentShort}
                              {" "}<span onClick={() => setReadMoreShippingAndReturns(!readMoreShippingAndReturns)} style={{ letterSpacing: '2px', textDecoration: "underline", cursor: "pointer" }}><b>{!readMoreShippingAndReturns ? "Read more" : "Read less"}</b></span>
                            </div>
                          </div>
                          <div
                            className="card cardtext"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              borderTop: "1px solid rgba(0,0,0,.125)",
                              // width:"380px"
                            }}
                          >
                            <div
                              className="card-header"
                              id="headingfive"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                                background: "white",
                              }}
                            >
                              <h2
                                className="mb-0"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                }}
                              >
                                <button
                                  className="btn justify-content-between d-flex w-100"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapsefive"
                                  aria-expanded="true"
                                  aria-controls="collapsefive"
                                  style={{
                                    border: "0",
                                    borderRadius: "0",
                                    outline: "0",
                                    boxShadow: "none",
                                  }}
                                >
                                  <span ref={ref} style={{ marginLeft: "-2rem" }}>Maintenance</span>
                                  <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapsefive"
                              className="collapse"
                              aria-labelledby="headingfive"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body" style={{ paddingLeft: '-2rem', paddingTop: '0', padding: 'unset', fontSize: '14px' }}>
                                {readMoremaintenance ? maintenanceContent : maintenanceContentShort}
                                {" "}<span onClick={() => setReadMoremaintenance(!readMoremaintenance)} style={{ letterSpacing: '2px', textDecoration: "underline", cursor: "pointer" }}><b>{!readMoremaintenance ? "Read more" : "Read less"}</b></span>
                              </div>
                            </div>
                          </div>
                          <div
                            className="card cardtext"
                            style={{
                              border: "0",
                              borderRadius: "0",
                              outline: "0",
                              borderTop: "1px solid rgba(0,0,0,.125)",
                              borderBottom: "1px solid rgba(0,0,0,.125)",
                              // width:"380px"
                            }}
                          >
                            {/* <div
                              className="card-header"
                              id="headingsix"
                              style={{
                                border: "0",
                                borderRadius: "0",
                                outline: "0",
                                background: "white",
                              }}
                            >
                              <h2
                                className="mb-0"
                                style={{
                                  border: "0",
                                  borderRadius: "0",
                                  outline: "0",
                                }}
                              >
                                <button
                                  className="btn justify-content-between d-flex w-100"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapsesix"
                                  aria-expanded="true"
                                  aria-controls="collapsesix"
                                  style={{
                                    border: "0",
                                    borderRadius: "0",
                                    outline: "0",
                                    boxShadow: "none",
                                  }}
                                >
                                  <span ref={ref} style={{ marginLeft: "-2rem" }}>Warranty</span>
                                  <i className="fa fa-chevron-down" style={{ marginRight: '-28px' }}></i>
                                </button>
                              </h2>
                            </div> */}
                            <div
                              id="collapsesix"
                              className="collapse"
                              aria-labelledby="headingsix"
                              data-parent="#accordionExample"
                            >
                              <div className="card-body" style={{ paddingLeft: '-2rem', paddingTop: '0', padding: 'unset', fontSize: '14px' }}>
                                {readMorewarranty ? warrantyContent : warrantyContentShort}
                                {" "}<span onClick={() => setReadMorewarranty(!readMorewarranty)} style={{ letterSpacing: '2px', textDecoration: "underline", cursor: "pointer" }}><b>{!readMorewarranty ? "Read more" : "Read less"}</b></span>
                              </div>
                            </div>
                          </div>
                          <div >
                            <img src={"/icon.png"} alt="icons" className="img-fluid " />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
              : null}
          </div>
          {RvCount !== 0
            ?
            <div className="container-fluid mt-5" >
              <h5 className="px-4 line" style={{ color: 'black', }}><b>Product Reviews</b></h5>
              <div className="d-flex mt-2 px-4" >
                <span className="rate" style={{ fontWeight: 600, fontSize: '45px', marginRight: '5px', color: '#637381', }}>{Math.round(Avg * 10) / 10}</span>
                <div className="d-flex align-items-center ml-3">
                  <ReactStars
                    value={Avg}
                    isHalf={true}
                    edit={false}
                    size={24}
                    activeColor="#ffd700"
                  />
                  <span className="ml-2">{RvCount} Reviews </span>
                </div>
              </div>
              <div className="p-4" style={{ background: '#f7f7f7' }}>
                {Reviews
                  ? Reviews.slice(0, reviewToShow).map((elem, i) => {
                    return (
                      <>
                        <div className="reviews">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              {/* /* <img src={elem.image} alt="Avatar" className="avatar" />  */}
                              <span className="inti">{elem.intials}
                                {elem.verify == 1 ?
                                  <span className="verify_buy">
                                    <i className="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                  </span>
                                  : null}
                              </span>
                              <p style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontWeight: 800, fontSize: '20px', marginLeft: '10px', color: '#29275b' }}>{elem.name}</span>
                                {elem.verify == 1 ?
                                  <span className="veri_tag">Verified Buyer</span>
                                  : null}
                              </p>
                            </div>
                            <div>
                              <span style={{ color: '#637381' }}>{elem.rv_date}</span>
                            </div>
                          </div>
                          <div className="mt-2">
                            <ReactStars
                              value={elem.rating}
                              isHalf={true}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                            />
                          </div>
                          <p className="mt-2" style={{ fontWeight: 400, fontSize: '17px', color: '#637381', }}>{elem.description}</p>
                        </div>
                      </>
                    );
                  })
                  : null}
                <div className="row">
                  {Reviews ? Reviews.length > Items ? (
                    <div className="col-12 col-md-12 text-center">
                      <button
                        className="btn"
                        id="showmorereview"
                        onClick={() => showMoreReviews()}
                        style={{ background: "rgb(0, 0, 0)", color: "white" }}
                      >
                        Show more
                      </button>
                    </div>
                  ) : null : null}
                </div>
              </div>
            </div>
            : null}
          {/* <div className="iconImgContainer">
          <img src={"/services.png"} alt="icons" className="iconImg" />
          </div> */}
          {relatedproducts?.length > 0 ?
            <div className="row mt-5 pt-5 " >
              <div className=" col-12 text-center mb-4">
                <h2>RELATED PRODUCTS</h2>
                {/* <p>The furniture our customers love:</p> */}
              </div>
              <div style={{ marginLeft: '2px' }}>
                <ProductSlider
                  products={relatedproducts}
                  addingToCartFunction={props.addingToCartFunction}
                  isMostPopular={false}
                />
              </div>
            </div>
            :
            null
          }
          {/* <div className="row mt-5">
            <video className="img-fluid" controls autoplay>
              <source src='https://www.w3schools.com/html/mov_bbb.mp4' alt="video" />{" "}
            </video>
          </div> */}
        </div>
      </section>
    </section>
  );
}
