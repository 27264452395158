import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../App.css";
// import "../js/rotate";
import "../js/productDetails";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Home from "./HomeComponent";
import ProductDetails from "./ProductDetailsComponent";
// import TopnavComponent from "./TopnavComponent";
import Header from "./HeaderComponent";
import Footer from "./FooterComponent";
import AllProduct from "./AllProductsComponent";
import WishList from "./WishlistComponent";
import Register from "./RegisterComponent";
import CustomOrder from "./CustomOrderComponent";
import Checkout from "./CheckoutComponent";
import Corporate from "./CorporateComponent";
import { Cart } from "./CartComponent";
import Next from "./NextComponent";
import Place from "./PlaceOrderComponent"; // replaced place order comp with new place order comp
import NewPlaceOrderComp from "./newPlaceOrder"; // new place order comp
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import MyOrders from "./MyOrdersComponent";
import OrderDetails from "./OrderDetailsComponent";
// import TopPopUpBox from "./TopPopUpBox";
import Profile from "./ProfileComponent";
import SeachedProducts from "./SeachedProductsComponent";
import Fail from "./FailComponent";
import ScrollToTop from "./ScrollToTop";
// import MessageModal from "./MessageModalComponent";
import CategoryProduct from "./CategoryProductComponent";
import Gallary from "./GallaryComponent";
import Gallary2 from "./Gallary2Component";
import ForgetPassword from "./ForgetPasswordComponent";
import ResetPassword from "./ResetPasswordComponent";
import { baseUrl } from "../baseURL";
import Term from "./TermComponent";
import Aboutus from "./AboutComponent";
import Createspace from "./CreateSpaceComponent";
import Shippingreturn from "./ShippingReturnComponent";
import PrivacyPolicyComp from './privacyPolicyComp'
import SuccessSrcComp from "./successSrcComp";
import AllReviewsComp from "./AllReviews";
import Blogs from "./Blogs";
import BlogDetails from "./BlogDetails";
import Showroom from "./Showroom"
import PhonepeCheck from "./PhonepeCheck"
function Main() {
  let history = useHistory();
  // Initital States
  const [cartLength, setCL] = useState(0);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [popUpMessage, setPUM] = useState("");
  const [loader,setLoader] = useState("false");
  // const [modalMessage, setMM] = useState("");
  // Setting email, password and token from local storage
  // console.log("hi...")
  const checkAndSetCredentials = () => {
    // Getting email, password and token from local storage
    const user_token = localStorage.getItem("user_token");
    const user_email_id = localStorage.getItem("create_outdoor_email");
    const user_password = localStorage.getItem("create_outdoor_password");
    // If login/Registered
    if (user_email_id && user_password) {
      // alert("Email and password are all set");
      setToken(user_token);
      setEmail(user_email_id);
      setPassword(user_password);
      // alert()
      // history.push("/cart"); // sanv
      // return <Redirect to="cart" />;
    }else{
      // alert("else");
      setToken(user_token);
    }
    // If no token in local storage
    if (!user_token) {
      // alert("check and set creadentials if block")
      fetch(
        baseUrl+"/Token/get_token"
      ).then((resp) => {
        resp.json().then((result) => {
          // alert("this is token api then block")
          localStorage.setItem("user_token", result.token);
          setToken(result.token);
          console.log("token", token);
        });
      })
      .catch((error) => {
        // alert("hi!! im error of get token api");
        console.error('Error:', error);
      });
    }
    //  if token in local storage
    else {
      // alert("we are i the else block of checkAndSetCredentials")
      console.log("user_tok", user_token);
      console.log("token", token);
      // this.setState({ token: user_token });
      setToken(()=>{
      // alert("inside set state call")
        return  user_token
      });
    }
  };
  useEffect(() => {
    checkAndSetCredentials();
    try {
      checkAndSetCredentials();
    } catch (error) {
      setToken('');
      setEmail('');
      setPassword('');
      console.log("Local storage permission is  blocked");
      console.error(error);
    }
  }, [token, email, password]);
  const logoutUser = () => {
    setEmail("");
    setPassword("");
    localStorage.setItem("create_outdoor_email", "");
    localStorage.setItem("create_outdoor_password", "");
    localStorage.setItem("create_outdoor_name", "");
    // setRenderPg(!renderPg);
    window.location.reload(true);
    // alert("hello")
    return <Redirect to="/" />;
  };
  // counting cart length
  const countCartLength = async () => {
    try {
      var getCartData = new FormData();
      getCartData.append("token_id", token);
      getCartData.append("email_id", email);
      getCartData.append("password", password);
      // for (var pair of getCartData.entries()) {
      //   console.log("getCartData is heres", pair[0] + ", " + pair[1]);
      // }
      const response = await axios.post(
        baseUrl + "Apicontroller/cart_count",
        getCartData
      );
      console.log("Cart Length waala aagaya", response.data.data);
      // if cart is not empty
      if (response.data.data !== undefined) {
        setCL(response.data.data);
      }
      // if cart is empty
      else {
        setCL(0);
      }
      console.log("Count cart", response.data.data);
      return response.data.data;
    } catch {
      console.log("Issue in count cart.");
    }
  };
  // Adding to cart
  const addingToCart = async (product_id, quantity, type_id,sample="") => {
    // alert(typeof(quantity))
    if (quantity === 0) {
      changePopupText("Quantity must be greater than 0.");
      return;
    }
    console.log(
      "product_id, quantity, type_id, email_id, password",
      product_id,
      quantity,
      type_id
    );
    // alert("at 1", product_id, quantity, type_id, password);
    try {
      var postCartData = new FormData();
      postCartData.append("product_id", product_id);
      postCartData.append("quantity", quantity);
      postCartData.append("type_id", type_id);
      postCartData.append("email_id", email);
      postCartData.append("password", password);
      postCartData.append("token_id", token);
      postCartData.append("sample", sample);
      for (let [key, value] of postCartData) {
        console.log("Cart ka data", key, ":", value);
      }
      setLoader("true")
      const response = await axios.post(
        baseUrl + "Apicontroller/add_to_cart",
        postCartData
      );
      setLoader("false")
      console.log("hogaya na post cart", response);
      if (response.data.status === 201 || response.data.code === 201) {
        changePopupText(response.data.message);
      } else if (response.data.status === 200 || response.data.code === 200) {
        changePopupText("Success! Item Successfully added to your cart");
        let responseOfCDL = countCartLength();
        console.log("responseOfCDL", responseOfCDL);
      }
    } catch {
      console.log("Issue in add to cart");
    }
  };
  // Adding to wishlist
  const addingToWishlist = async (product_id, quantity, type_id) => {
    console.log(
      "product_id, quantity, type_id, email_id, password",
      product_id,
      quantity,
      type_id
    );
    try {
      var postWishlistData = new FormData();
      postWishlistData.append("product_id", product_id);
      postWishlistData.append("quantity", quantity);
      postWishlistData.append("type_id", type_id);
      postWishlistData.append("email", email);
      postWishlistData.append("password", password);
      postWishlistData.append("token_id", token);
      for (let [key, value] of postWishlistData) {
        console.log("Wishlist ka data", key, ":", value);
      }
      const response = await axios.post(
        baseUrl + "Apicontroller/add_to_wishlist",
        postWishlistData
      );
      console.log("hogaya na post cart", response);
      if (response.data.status === 201 || response.data.code === 201) {
        changePopupText(response.data.message);
        // setTimeout(()=> {
        //   setPUM("")
        // }, 3000)
        // alert(response.data.message);
      } else if (response.data.status === 200 || response.data.code === 200) {
        // alert(response.data.message);
        changePopupText("Success! Item Successfully added to your wishlist");
        // setTimeout(()=> {
        //   setPUM("")
        // }, 3000)
        // let responseOfCDL = countCartLength();
        // console.log("responseOfCDL", responseOfCDL);
      }
    } catch {
      console.log("Issue in add to wishlist.");
    }
  };
  // Change the popup text
  const changePopupText = (text) => {
    setPUM(text);
    setTimeout(() => {
      setPUM("");
    },1000);
  };
  // const changeMessageModalText = (text) => {
  //   setMM(text);
  // }
  return (
    <div>
      {/* <TopPopUpBox
        popUpMessage={popUpMessage}
      /> */}
      {/*<TopnavComponent />*/}
      <Header
        cartLength={cartLength}
        countCartLengthFunction={countCartLength}
        popUpMessage={popUpMessage}
        email={email}
        logoutUser={logoutUser}
      />
      <div
        className="midsection"
        // style={{ paddingTop: "160px" }}
      >
        {/* <MessageModal modalMessage={modalMessage} /> */}
        <ScrollToTop />
        <Switch>
          {/* <Route exact path="/test" component={Test} /> */}
          <Route
            exact
            path="/"
            render={() => (
              <Home
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                addingToCartFunction={addingToCart}
                loader = {loader}
              />
            )}
          />
          <Route
            path="/product-details"
            render={() => (
              <ProductDetails
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                addingToCartFunction={addingToCart}
                addingToWishlist={addingToWishlist}
              />
            )}
          />
          <Route
            exact
            path="/cart"
            component={()=> (
              <Cart
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                token={token}
                email={email}
               changePopupText={changePopupText}
                password={password}
              />
            )}
          />
          <Route
            path="/all-product"
            render={() => (
              <AllProduct
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                token={token}
                email={email}
                password={password}
                addingToCartFunction={addingToCart}
              />
            )}
          />
          <Route
            path="/category"
            render={() => (
              <CategoryProduct
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                token={token}
                email={email}
                password={password}
                addingToCartFunction={addingToCart}
              />
            )}
          />
          <Route
            path="/Gallary"
            render={() => (
              <Gallary
              />
            )}
          />
          <Route
            path="/Gallary2"
            render={() => (
              <Gallary2
              />
            )}
          />
          <Route
            exact
            path="/wishlist"
            component={() => (
              <WishList
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                token={token}
                email={email}
                password={password}
                changePopupText={changePopupText}
                addingToCartFunction={addingToCart}
              />
            )}changePopupText={changePopupText}
          />
          <Route exact path="/fail" component={Fail} />
          <Route exact path="/myorders" component={()=><MyOrders changePopupText={changePopupText} />} />
          <Route path="/order-details" component={OrderDetails} />
          <Route exact path="/export" component={()=><CustomOrder changePopupText={changePopupText} />} />
          <Route exact path="/corporate-order" component={Corporate} />
          {/* <Route exact path="/place" component={Place} /> */}
          <Route exact path="/place" component={()=><NewPlaceOrderComp countCartLengthFunc={countCartLength}/>} />
          <Route exact path="/placed" component={SuccessSrcComp} />
          <Route exact path="/phonepe-check" component={PhonepeCheck} />
          <Route
            path="/search-result"
            component={() => (
              <SeachedProducts addingToCartFunction={addingToCart} />
            )}
          />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route
            exact
            path="/register"
            component={() => (
              <Register
                token={token}
                checkAndSetCredentials={checkAndSetCredentials}
                changePopupText={changePopupText}
              />
            )}
          />
          <Route
            exact
            path="/next"
            component={() => (
              <Next
                cartLength={cartLength}
                countCartLengthFunction={countCartLength}
                token={token}
                email={email}
                password={password}
              />
            )}
          />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/term" component={Term} />
          <Route exact path="/about" component={Aboutus} />
          <Route exact path="/createspace" component={Createspace} />
          <Route exact path="/shippingreturn" component={Shippingreturn} />
          <Route exact path="/privacy&policy" component={PrivacyPolicyComp} />
          <Route exact path="/AllReviews" component={AllReviewsComp} />
          <Route exact path="/Blogs" component={Blogs} />
          <Route exact path="/Showroom" component={Showroom} />
          {/* <Route exact path="/BlogDetails" component={BlogDetails} /> */}
          <Route
            exact
            path="/blog-details"
            component={()=> (
              <BlogDetails/>
            )}
          />
          {/* <Route exact path="/BlogDetails" component={BlogDetails} /> */}
        </Switch>
      </div>
      <Footer changePopupText={changePopupText} />
    </div>
  );
}
export default Main;
