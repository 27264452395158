import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseUrl, baseUrl2 } from "../baseURL";

const ProductDetailsSlider = ({ productImages }) => {
  const play = () => {

    if(document.querySelector("#video").paused){ 
      document.querySelector("#video").play();
      document.querySelector("#playPause").style.backgroundImage = '';
    }else{
      document.querySelector("#video").pause();
      document.querySelector("#playPause").style.backgroundImage = `url(${baseUrl2+ "/assets/img/play2.png"})`;
    }
    }
  // productImages=productImages]
  return (
    <div className="container-fluid ">
      <div className="">
        <div className="col-md-12 p-0">
          <div
            id="custCarousel"
            className="carousel slide"
            data-ride="carousel"
            align="center"
          >
            {/* <!-- slides --> */}
            <div className="carousel-inner"  id="">
              {productImages
                ? productImages.map((elem, i) => {
                    if(i === 0) {
                      return (
                        <div className=" carousel-item active mt-5 mt-md-0">
                          {" "}
                          <img src={elem} alt=""  className="img-fluid" />{" "}
                        </div>
                      );
                    } else{
                       if(elem.includes('.webm') || elem.includes('.mp4'))  {
                        return (
                          <div className="carousel-item">
                            {" "}
                            <video className="slider-img" id="video"
                             controls loop width="100%">
                            <source src={elem} alt="video" />{" "}
                          </video>
                          <div className="playPause" id="playPause" style={{ backgroundImage:`url(${baseUrl2+ "/assets/img/play2.png"})`}} onClick={play}></div>
                          </div>
                        );                
                    }
                    else{
                      return (
                        <div className="carousel-item">
                          {" "}
                          <img src={elem} alt=""  className="img-fluid" />{" "}
                        </div>
                      );
                    }
                  }
                  })
                : null}
            </div>
            {/* <!-- Left right -->  */}
            <a 
              className="carousel-control-prev"
              href="#custCarousel"
              data-slide="prev"
              style={{width:'37%!important;'}}
            >
              {" "}
              <span className="carousel-control-prev-icon "></span>{" "}
            </a>{" "}
            <a
              className="carousel-control-next"
              href="#custCarousel"
              data-slide="next"
            >
              {" "}
              <span className="carousel-control-next-icon"></span>{" "}
            </a>
            {/* <!-- Thumbnails --> */}
            <ol className="onMedia600Class carousel-indicators list-inline ">
              {productImages
                ? productImages.map((elem, i) => {
                    if (i===0) {
                      return (
                        <li className="list-inline-item active">
                          {" "}
                          <a
                            id="carousel-selector-0"
                            className="selected"
                            data-slide-to={i}
                            data-target="#custCarousel"
                          >
                            {" "}
                            <img src={elem} className="img-fluid" />{" "}
                          </a>{" "}
                        </li>
                      );
                    } else{
                       if(elem.includes('.webm') || elem.includes('.mp4')) {
                      return (
                        <li className="list-inline-item" style={{textIndent:'0px'}}>
                          {" "}
                          <a
                            id="carousel-selector-1"
                            data-slide-to={i}
                            
                            data-target="#custCarousel"
                          >
                            {" "}
                            <img src={baseUrl2 + "/assets/img/play.png"} className="img-fluid" />{" "}
                          </a>{" "}
                      </li>
                      );
                    }else{
                      return (
                        <li className="list-inline-item" style={{textIndent:'0px'}} >
                          {" "}
                          <a
                            id="carousel-selector-1"
                            data-slide-to={i}
                            
                            data-target="#custCarousel"
                          >
                            {" "}
                            
                            <img src={elem} className="img-fluid" />{" "}
                          </a>{" "}
                      </li>
                      );
                    }
                  }
                  })
                : null}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetailsSlider;
