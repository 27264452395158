import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { baseUrl } from "../baseURL";
import { Redirect } from "react-router";

export default function PhonepeCheck() {
  const history = useHistory()
  useEffect(() => {
    setTimeout(() => {
      checkPaymentStatus();
    }, 1000);
  });
  let checkPaymentStatus = async () => {
    var order_id = localStorage.getItem("phonepe_order")
    try {
      let response = await axios.post(baseUrl + "Apicontroller/checkPhonepePayment/" + order_id)
      if (response.data.status == 200) {
        history.push("/placed")
      } else {
        history.push("/fail");
      }
    } catch (error) {
      history.push("/fail");
    }
  }
  // if (localStorage.getItem("user_token")) {
  return (

    <>  <div className="container pt-5 mt-4 waitingSrcCont">
      <h1>Please Wait....</h1>
    </div>
    </>
  );
  // } else {
  //   return <Redirect to="/register" />;
  // }
}


