import React from 'react'

function Term() {
    return (

        <div className="container">
        <div className="row heading-div mt-5 pt-4">
        <div className="col-md-12 text-center">
          <h1 className="heading mt-5">Term & Conditions</h1>
        </div>
      </div>
      <div className="about"  
        style={{fontWeight:"20px" ,color:"black", fontFamily:"'Lato', sans-serif", }}
      >
        
        <div className="col-md-12 ml-3">
        <h4 className='text-center'>This website is owned by Reva Creation (08BYRPB8122J1Z5)</h4>
        <p className="pt-1 mb-0 mt-3">
        TERMS OF USE - CREATE SPACES (Reva Creation)
        </p>
        <li className="mt-2"
        style={{listStyleType:"none",}}>
        Please read the following terms and conditions carefully. The use of our
       services is subject to your compliance and acceptance with the following
       statements.
      </li>

      <li className="mt-2"
      style={{listStyleType:"none",}}>
      Please read the following terms and conditions carefully. The use of our
     services is subject to your compliance and acceptance with the following
     statements.
    </li>
    <li className="mt-2"
    style={{listStyleType:"none",}}>
    Once you subscribe or use any of our services, you agree that you have
read and understood the terms and conditions and ready to bound by the
below statements.
  </li>
  <li className="mt-2"
  style={{listStyleType:"none",}}>
  Note: In these Terms, references to 'you', 'your’, ‘user' shall mean
  the end user accessing the website, and 'we', 'us', 'our', 'Company'
  shall mean Wooden Street.
  
  </li>

      
      </div>
      <div className="col-md-12 ml-3">
      <p className="pt-1 mb-0 mt-3">
     GENERAL
      </p>
      <li className="mt-2"
      style={{listStyleType:"none",}}>
      The website c [1]reatespaces.com [2] is an e-commerce portal, owned and
      operated by the Company. Use of the website is subject to your
      acceptance without any modifications to all the terms and conditions
      made by the Company, as its sole discretion and stated on the website.
    </li>

    <p className="mt-4">
    User will agree to the terms and privacy policy of the Company by:
    </p>
    
  <li className="mt-2 ml-4"
  style={{listStyleType:"none",}}>
  * Using the services and facilities provided by the website in any way.
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Browsing the content on the website.
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>
The Company shall not be required to notify you about the modifications
made in the terms; the revised terms should be determined by checking
the website on a regular basis. Use of services and facilities on the
website is subject to the current version of the policy, hence to know
about any updates in the policy shall be the sole responsibility of the
user.
</li>
</div>
  
  <div className="col-md-12 ml-3">
  <p className="pt-3 mb-0">
  PRODUCT POLICY
  </p>
 <li className=" mt-2"
style={{listStyleType:"none",}}>
The product shown in the image is a reference product and the new
manufactured product can look different from the one shown in the image
due to various factors like lighting, manual polish, wood's texture,
etc.
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>

Wooden Street products are available in a fixed set of Finishes. So
please discuss the finish you need with our customer support team once
in order to receive the best quality product.
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>

Please note that in case of Solid Wood products, slight grains would be
visible. There may be a slight variation in finish between the actual
product and the 3-D designs which are being displayed on our website.
</li>
</div>
  
  <div className="col-md-12 ml-3">
  <p className="pt-3 mb-0">
  SERVICES
  </p>
  
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  
  createspaces.in [3] provides an Internet based platform to sell their
products and merchandise on the website. Such services enable users to
purchase home furnishing products upon placing an order. Once an order
placed, the Company will ship the product, and the user will be entitled
to pay for it.
  </li>

  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  Persons barred under the laws of India are not permitted to use our
services.
 
  </li>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  The Company reserves the end right to reject or grant the access to a
  new user or terminate the access of existing users, at any time. Also,
  the Company shall not be required to furnish any reason for it.
 
  </li>
  
  </div>
  
  <div className="col-md-12 ml-3 ">
  <p className="pt-3 mb-0">
  ELIGIBILITY TO USE
  </p>
  
  According to the Indian Constitution, a person under the age of 18 is
minor. Services and facilities offered on our website shall not be
available for minors. Please note if you are living outside of India
then it's your responsibility to make sure whether you are eligible or
not for our services.
  
  </div>
  
  <div className="col-md-12 ml-3 ">
  <p className="pt-4 mb-0">
  USER ACCOUNT, PASSWORD, AND SECURITY
  </p>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
 
  The website registration process enables a user to access the services.
  Certain user information is required to create an account at our
  website. It is the sole responsibility of the user to maintain the
  confidentiality of the User Id and password received upon completion of
  the registration. Users are also responsible for any activity occurred
  under the account, thus make sure to exit at the end of each session to
  prevent unauthorized access of your account or breach of security. The
  Company shall not be responsible for any loss or damage emerged if you
  fail to obey the terms
  </li>

  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  User is responsible for the validity of account information provided
  during the website registration process. In case of submission of
  untrue, incomplete or inaccurate information the Company has the end
  rights to suspend or terminate your account.
 
  </li>

  
  </div>

  <div className="col-md-12 ml-3 ">
  <p className="pt-4 mb-0">
  USER ACCOUNT, PASSWORD, AND SECURITY
  </p>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
 
  The website registration process enables a user to access the services.
  Certain user information is required to create an account at our
  The Pricing Information relating to the Product shall be as displayed on
the Website and as disclosed to You at the time of Your purchase/order.
We do not guarantee that the price will be the lowest for similar
products within any given locality, city, region or geography. Prices
and availability are subject to change without notice or any
consequential liability to You. While Wooden Street strives to provide
accurate information relating to Products and Services including pricing
information or availability, but typographical and other errors may
occur. In the event that a Product or Service is listed at an incorrect
price or with incorrect information due to an error, Create Spaces (Reva Creation) will
have the right to modify the price of the Product or Services and
contact You for further instructions via e-mail address provided by You
at the time of registration, or cancel the order and notify You of such
cancellation. If Create Spaces (Reva Creation)  cancels the order after the payment has
been processed, the said amount will be remitted to Your account from
which the payment was made.
  </li>

  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  Create Spaces (Reva Creation) reserves the right to change the discounts offered at any
time during the sale and without prior notice..
 
  </li>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  Discount values on various coupons and offers have been rounded off to
  avoid conflicts due to decimal values in internal calculations.For
  example:  A value of ₹99.95 will be rounded off to be ₹100
 </li>

  
  </div>
  <div className="col-md-12 ml-3">
  <p className="pt-1 mb-0 mt-3">
  USER OBLIGATIONS
  </p>
  


<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* Your access to the website is a non-exclusive limited privilege,
which is subject to compliance with the Terms of Use.
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* The Services, Website and the materials offered to a user can only be
used for purposes that are permitted by: (a) the Terms; and (b) any
applicable law, regulation or relevant jurisdictions.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* You agree to adhere to the limitations on circulation, use and
reproduction of any material accessed from us in accordance to the Terms
mentioned in the 'Use of Material' section.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* You agree not to access the website, the material or the services
through any other interface, which is not provided by the Company. Use
of deep-link, robot, spider or other automatic device, program,
algorithm or methodology, or any other similar process to access or
attempt or access the website or content (or any portion thereof) shall
be strictly prohibited.

</li>

<li className=" ml-4"
style={{listStyleType:"none",}}>

* The Company disclaims all the liabilities arising in relation to
offensive, indecent or otherwise objectionable content that is exposed
to a user while accessing the website.

</li>

<li className=" ml-4"
style={{listStyleType:"none",}}>

* The content posted or uploaded on the website should not be offensive
and should be in accordance to the applicable laws. Users are legally
liable for the content they post. Hence you accept not to:

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Discredit, harass, abuse or violate the legal rights

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Impersonate any person or entity, or misrepresent your affiliation
with a person

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Publish, post or distribute any inappropriate, defamatory, obscene,
indecent or unlawful material or information
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* Upload the files, software or other material protected by
intellectual property laws that are not owned by you

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Upload files containing virus or similar program that may damage the
website

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Impersonate any person or entity, or misrepresent your affiliation
with a person

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Undertake any activity that disrupts the website, the servers or
networks connected to the website

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Seek unauthorized access to the system or network or to any server of
the website, or any attempt to gain access via illegitimate means such
as hacking

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Scan the vulnerability or breach the authentication measure of the
website or networks connected to the website

</li>

<li className=" ml-4"
style={{listStyleType:"none",}}>

* Harm the website, resources, accounts, servers, networks connected to
the websites, or any links affiliated to it
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* Collect or share data about other users
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Use of any device to interfere the working of the website

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Use of the website content or material that is unlawful. Or to
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Use of the website content or material that is unlawful. Or to
practice any illegal activity that violates the rights of the Company
</li>

<li className=" ml-4"
style={{listStyleType:"none",}}>

* Conduct surveys, pyramid schemes, contests or chain letters
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Download files of another, users posted on the website that is
illegal to be distributed in such manner;
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Delete any author attributions, legal notices, proprietary
designations, labels of the origin of the software contained in the
uploaded file
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Infringe code of conduct applicable for to or any particular service
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Breach applicable laws or regulations in force within or outside
India and
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* Reverse engineer, copy, dispense, exhibit, reproduce, publish,
license, transfer or sell any information acquired from the Website.
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* You agree that you are solely responsible for any loss or damage or
any breach of your obligations under the terms.
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* Acknowledge that Create Spaces (Reva Creation) is facilitating the products from
various vendors. Hence the Company shall not be supposed to be the
seller of such services. The Company shall not be responsible for any
certifications, warranty or guarantee offered by the vendors.
</li>

</div>
<div className="col-md-12 ml-3">
<p className="pt-1 mb-0 mt-3">
USE OF MATERIAL
</p>
* The Company grants you a non-exclusive, revocable, non-transferable
right to access the product catalog or any other material available on
the website. In accordance with the terms, you may:
<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* Access the material for personal, internal an informational purpose.
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Not alter the material available on the website.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Not remove any copyright or proprietary notices available on the
website.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* The design, layout and look, and feel of the website are protected
under intellectual property rights, thus copying of the same shall not
be granted here in.

</li>

<li className=" ml-4"
style={{listStyleType:"none",}}>

* Unless or otherwise permitted, users are not allowed to download or
install any of the software available on the website.

</li>






</div>

<div className="col-md-12 ml-3">
<p className="pt-1 mb-0 mt-3">
USE OF MATERIAL
</p>
* The Company grants you a non-exclusive, revocable, non-transferable
right to access the product catalog or any other material available on
the website. In accordance with the terms, you may:
<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* Access the material for personal, internal an informational purpose.
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Not alter the material available on the website.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Not remove any copyright or proprietary notices available on the
website.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* The design, layout and look, and feel of the website are protected
under intellectual property rights, thus copying of the same shall not
be granted here in.

</li>

<li className=" ml-4"
style={{listStyleType:"none",}}>

* Unless or otherwise permitted, users are not allowed to download or
install any of the software available on the website.

</li>






</div>

<div className="col-md-12 ml-3">
<p className="pt-1 mb-0 mt-3">
USAGE CONDUCT
</p>

<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* Basic requirements to access and transact on the website, such as
computer and Internet connection, shall be the sole responsibility of
the user.
</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* The website should be used for the sensible purpose only, users shall
not indulge in activities that are not foreseen by the website.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Loss or damage arises out of decaf authorization for a transaction
shall not be the liability of the Company.

</li>


</div>
 
<div className="col-md-12 ml-3">
<p className="pt-3 mb-0">
INTELLECTUAL PROPERTY RIGHTS
</p>
<li className=" mt-2"
style={{listStyleType:"none",}}>
The website, texts, graphics, UI, visual interfaces, images, sounds and
music, codes on the website is owned and controlled by Create Spaces (Reva Creation) and
the design, structure, selection, coordination, expression,
look-and-feel and arrangement of such Content is protected by copyright,
patent and trademark laws, and various other intellectual property
rights.
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>

The trademarks, logos and service marks displayed on the Website are the
property of Create Spaces (Reva Creation). You are not permitted to use these Marks
without the prior consent of Create Spaces (Reva Creation).
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>

Create Spaces (Reva Creation) owns all intellectual property rights to the trademark
"Create Spaces (Reva Creation) ." and variants thereof, and the domain name, including,
without limitation, any and all rights, title and interest in and to
copyright, related rights, patents, trade secrets and inventions (patent
pending), goodwill, source code, meta tags, databases, text, content,
graphics, icons, and hyperlinks.
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>

Except as expressly provided herein, You acknowledge and agree that You
shall not copy, republish, post, display, translate, transmit, reproduce
or distribute any Content through any medium without obtaining the
necessary authorization from Wooden Street.
</li>
</div>

<div className="col-md-12 ml-3">
<p className="pt-3 mb-0">
INTELLECTUAL PROPERTY RIGHTS
</p>
<li className=" mt-2"
style={{listStyleType:"none",}}>
We make sure that all the information posted on the website is correct,
however the Company never warrants the accuracy, quality and
completeness of data, product, and services.
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>

The Company shall not be liable for any direct, indirect, incidental or
consequential damages caused by:
</li>
<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* The use or inability of use of services;
</li>
<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* Unauthorized access to the user's data; and
</li>
<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* Any matter is related to the services.

</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>
The Company shall not be liable for delay or inability to use of the
website or services, or any information, software and graphics acquired
through the website. Further, we are not responsible for non-
availability of the website during periodic maintenance or accidental
suspension caused due to technical reasons.
</li>
<li className=" mt-2"
style={{listStyleType:"none",}}>
You acknowledge that data obtained from the website is done entirely
through your wisdom. Thus you agree that you will be responsible for any
damage and loss caused to your computer with such data.
</li>

</div>
<div className="col-md-12 ml-3">
  <p className="pt-4 mb-0">
  VIOLATION OF THE TERMS OF USE
  </p>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
 
  The Company, in its sole and absolute discretion, may terminate the
access to the website of any user in case of violation of the terms or
additional terms. You consent to the injunctive or equitable remedy
taken by us after the contravention of the terms of use that causes
irreparable loss to the Company.
  </li>

  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  You agree to indemnify and hold harmless Create Spaces  (Reva Creation), its affiliate,
directors, employees from and against any and all losses, liabilities,
damages, claims and expenses incurred by the Company that emerged of
  </li>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  infringement, non-performance of any warranty or obligation performed by
  you.
 </li>
 <li className=" mt-2"
  style={{listStyleType:"none",}}>
  Further, you agree to hold the Company harmless against any claims made
by any third party due to your use of the website that caused damaged to
a third party. The Company will also be entitled to redeem from you and
you agree to pay any cost and fees of such action.
 </li>

  
  </div>

  <div className="col-md-12 ml-3">
<p className="pt-1 mb-0 mt-3">
TERMINATION
</p>

<li className="mt-2 ml-4"
style={{listStyleType:"none",}}>
* The term will continue to apply until terminated by you or Create
</li>
<li className=" ml-4 "
style={{listStyleType:"none",}}>

Spaces. Further, you may terminate the agreement by:

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Not accessing the website

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Closing your account on our website

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Closing your account on our website

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>
* Create Spaces (Reva Creation) at its sole and absolute discretion may terminate the

</li>

<li className=" ml-4 "
style={{listStyleType:"none",}}>

terms of use with or without cause at any time if:

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Terms, conditions or any policy of the Company, applicable to you is

</li>

<li className=" ml-4 "
style={{listStyleType:"none",}}>

violated.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* The provision of the services to you becomes unlawful.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* The provision of the services to you is no longer commercially
viable.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* The Company chooses to discontinue the access to the website, in

</li>

<li className=" ml-4 "
style={{listStyleType:"none",}}>

terms of use with or without cause at any time if:

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Terms, conditions or any policy of the Company, applicable to you is

</li>

<li className=" ml-4 t-2"
style={{listStyleType:"none",}}>

general or specifically, for you.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* Termination or suspension due to technical reasons may include,

</li>
<li className=" ml-4 mt-2"
style={{listStyleType:"none",}}>
removal of access to the website, deletion of user material(including
    files and material of user with account information), exclude you from
    accessing our website.

</li>
<li className=" ml-4"
style={{listStyleType:"none",}}>

* You acknowledge that termination shall not affect your liabilities
and obligation.

</li>



</div>
<div className="col-md-12 ml-3">
  <p className="pt-3 mb-0">
  GOVERNING LAW
  </p>
  
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  * Terms, transactions and any mutual claims between you and the Company
  </li>

  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  shall be governed in accordance with the laws of India.
* All claims and disputes arising in connection with the website
 
  </li>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  be subject to the exclusive jurisdiction of the Jaipur, Rajasthan.eason for it.
 
  </li>
  
  </div>

  <div className="col-md-12 ml-3 ">
  <p className="pt-3 mb-0">
  REPORT ABUSE
  </p>
  
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
  If Create Spaces (Reva Creation) permits you to post any material from the website, you
  hereby undertake to ensure that it should not be:
  </li>

  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  * Offensive, Abusive and threating.
 
  </li>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  * Defame the applicable laws and rights.
 
  </li>
  <li className=" mt-2"
  style={{listStyleType:"none",}}>
  As per terms, users can be held legally liable for every content or
  material posted on the website, and legally accountable if the content
  or material is defamatory or protected by copyright and trademark. In
  case you come across any abuse or violation of the terms, please report
  to our Customer Support [4].
 
  </li>
  
  </div>

  <div className="col-md-12 ml-3">
  <p className="pt-3 mb-0">
  PRIVACY POLICY
  </p>
  
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
  You accept that you have read and fully understand the privacy policy of
the website, further you agree that the terms and conditions of the
policy are acceptable to you.
  </li>

  
  
  </div>

  
  <div className="col-md-12 ml-3 ">
  <p className="pt-3 mb-0">
  Newsletters and Communication
  </p>
  
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
  You agree to receive communication and newsletters via SMS and emails
  </li>
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
  from Create Spaces (Reva Creation). To unsubscribe kindly follow the procedure set forth
  </li>
 

  
  
  </div>

  {/* <div className="col-md-12 ml-3 ">
  <p className="pt-3 mb-0">
  Links:
  </p>
  <p className="pt-3 mb-0">
  ------
  </p>
  
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
  [1] https://www.woodenstreet.com/
  </li>
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
 [2] http://reatespaces.com
  </li>
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
  [3] http://createspaces.com
  </li>
  <li className=" mb-0"
  style={{listStyleType:"none",}}>
[4] https://www.woodenstreet.com/support-form
  </li>

  
  
  </div> */}

  </div>
      </div>
    )
}

export default Term



