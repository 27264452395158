import React from "react"
// import { baseUrl2 } from "../baseURL"

function Aboutus() {
  return (
    <div className="container">
  
    <div className="row">
    <div className=" col-xs-12 col-md-12 mt-4"></div>
    <div className=" col-xs-6 col-md-6  " >
    <img  className=" img-fluid mt-5" src="/assets/img/bed-home.jpg" alt="image1"/></div>
    <div className="col-xs-6 col-md-6 " >
    <img className=" img-fluid mt-5 " src="/assets/img/sofa6.jpg" alt="image1"/></div>
    </div>
      <div className="row heading-div  pt-4">
        <div className="col-md-12  text-center">
       
          <h1 className="heading">About Us</h1>
        </div>
      </div>
      <div
        className="about"
        style={{
          fontWeight: "20px",
          color: "black",
          fontFamily: "'Lato', sans-serif",
        }}
      >
        <div className="col-md-11 ml-md-5">
          <p>
            We have been a family run export oriented unit for over 25 years.
            The newer generation saw the gap in the Indian market for the
            availability of luxury furniture at an affordable price. After
            executing projects for hotels and restaurants we decided to make
            household spaces better looking and more functional.
          </p>
        </div>

        <div className="col-md-10 ml-md-5">
          <p className="pt-1 mb-0 fw-bold">Designed by Create Spaces -</p>
          Our in-house design and manufacturing team are tasked to create
          outdoor and indoor furniture that is not only comfortable but also
          pragmatic and visually appealing.
        </div>

        <div className="col-md-6 ml-md-5 mt-3">
          <p className="mb-1 fw-bold">Our Essential Elements-</p>
          <li style={{ listStyleType: "none" }}> Quality </li>
          <li style={{ listStyleType: "none" }}> Affordability</li>
          <li style={{ listStyleType: "none" }}>Functionality</li>
          <li style={{ listStyleType: "none" }}>Aesthetic appeal</li>
        </div>
        <div className="d-flex">
          {/*<div className="w-50">*/}
            <div className="col-md-10 ml-md-5 ">
              <p className="pt-3 mb-0 fw-bold">
                Balancing Luxury & Quality with affordability-
              </p>
              With experience we wanted to offer unique luxury furniture without
              drilling customers' pockets. Our In-house quality control team
              ensures that each piece is checked at every stage so that the
              quality is imbibed in every part of the furniture.
           </div>
         
        </div>

        <div className="col-md-10 ml-md-5">
          <p className="pt-3 mb-0 fw-bold">Affordability -</p>
          A question we have been asked in all our commercial projects in India
          is how do we ensure quality at such prices. The simple answer is our
          export orientation gives us the strength to order raw materials in
          bulk, make furniture pieces in bulk and have an inhouse design team to
          cater to all our customer needs.
        </div>

        <div className="col-md-10 ml-md-5">
          <p className="pt-3 mb-0 fw-bold">Functionality -</p>A product should
          not only look great but fulfill multiple purposes. All our designs are
          checked for comfort and usefulness before going into bulk production.
        </div>

        <div className="col-md-10 ml-md-5 ">
          <p className="pt-4 mb-0 fw-bold">Aesthetic Appeal -</p>
          All the essential elements are of no use if the furniture piece is not
          soothing to the eyes and ensures to beautify a space.
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
