import React from 'react'
import { Link } from "react-router-dom";
import { baseUrl, baseUrl2 } from "../baseURL";


function Showroom() {
  return (
    <div>
      <div className="container">
        <div className="row heading-div mt-5 pt-4">
          <div className="col-md-12 text-center">
            <h1 className="heading mt-5 color-l color-2">Expert Advise And Guidance</h1>
            <h2 className='color-l color-2'>Welcome to Createspaces furniture Showroom in Jaipur</h2>
            <p><b> Benefit from availability of both budget and customisable luxury furniture options </b></p>
          </div>
        </div>
        <div className="about"
          style={{ fontWeight: "20px", color: "black", fontFamily: "'Lato', sans-serif", }}
        >
          <div className="container">

            <div className="row">

              <div className=" col-xs-12 col-md-12  " >
                <div className="App mt-0" >

                  <video width="100%" height="450" controls poster='/assets/img/video_bg.jpg'>
                    <source src="/assets/img/video.MP4" type="video/mp4" />
                  </video>

                </div></div>

            </div>



          </div>

          <div className="container mt-5">

<div className="row">


  <div className="col-xs-6 col-md-6 p-0 " >
    <div className="col-md-12  p-0 ">
     
        <h1 className="heading color-l text-center heading-1 mb-4">Products at showroom</h1> 

        <p className="pt-1 mb-0 ">
          1. PVD coated stainless steel with marble/ wood <br/>
          2. Marble dining sets <br/>
          3. Marble dining sets <br/>
          4. Fabric catalogs for help in customisation <br/>
          5. Centre/ coffee tables <br/>
          6. Benches <br/>
          7. Marble washbasins <br/>
          8. Beds <br/>
          9. Sofas <br/>
          10. Extra Large Slabs <br/>
          11. Cafe furniture customisation options <br/>
          12. Orthopedic mattresses <br/>
          13. Back friendly mattresses <br/>
          14. Latex mattresses <br/>
          15. Smart grid mattresses <br/>
        </p>

      </div>
    </div>
  <div className=" col-xs-6 col-md-6  p-0 dsdgt2" >
    <img className=" img-fluid " src="/assets/img/Untitled-2.jpg" alt="image1" /></div>
</div>



</div>


          <div className="container mt-5">

            <div className="row">

              <div className=" col-xs-6 col-md-6 p-0  dsdgt " >
                <img className=" img-fluid " src="/assets/img/bed-home.jpg" alt="image1" /></div>
              <div className="col-xs-6 col-md-6 p-0  " >
                <div className="col-md-12 hthu  ">
                  <p className=" mb-0" style={{ textAlign: 'justify'  ,}}>
                    In our Jaipur furniture showroom you will find some marble dining tables on display along with different steel & wood base options.The size and finish can be customised and chosen through the swatches available at the showroom.Handcrafted marble and concrete washbasins are also on display at our Jaipur showroom.
                    We also Display and stock budget furniture and mattresses along with our luxury furniture.The fabrics can be selected from over 100 swatch options.
                    How can architects/ trade customers use showrooms-1. A wide range of wood, steel & marble swatches are available.2. Wide range of fabric catalogs available.3. Outdoor & Cafe furniture can be customised through discussions at the showroom.4. No MOQ required for any customisation on our running products.5. We supply coffee and side tables to a lot of independent showrooms so you can select them from just trade catalogs.
                  </p>
                </div></div>
            </div>



          </div>



         
          <div className="container dfgsg mt-5">
            <div className="row">

              <div className="col-xs-4 col-md-4 " >
                <div className="col-md-12  ">
                  <div className="col-md-12 ">
                    <h3 className=" mt-5 mb-5" style={{ color: "#6b9cb3" }}>Contact us -</h3>

                  </div>
                  <p>
                    <img src={baseUrl2 + "/assets/img/location (2).png"} alt="" className='icon-1' />
                    <b>  Showroom Address:</b>  <br /> 178 Heera Rd., near DCM. Jaipur, 302021
                  </p>
                  <p>
                    <img src={baseUrl2 + "/assets/img/location (2).png"} alt="" className='icon-1' />
                    <b> Factory Address: </b>  <br />  G-77 RICCO Industrial Area, Bagru, Jaipur
                  </p>
                  <p>
                    <img src={baseUrl2 + "/assets/img/email.png"} alt="" className='icon-1' />
                    <b> Email: </b>  <br /> <Link to="">info@createspaces.in</Link>
                  </p>
                  <p>
                    <img src={baseUrl2 + "/assets/img/telephone.png"} alt="" className='icon-1' />
                    <b>  Mob: </b>  <br /> <Link to="">+91 8233406386</Link>
                  </p>



                </div></div>
              <div className=" col-xs-8 col-md-8 " >

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.2747810404517!2d75.74324527450426!3d26.894773160863043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db32e4a84ae29%3A0x99beb611d22a4d72!2sCreate%20Spaces%20Furniture!5e0!3m2!1sen!2sin!4v1703948184925!5m2!1sen!2sin" width="100%" height="450" ></iframe>



              </div>
            </div>



          </div>



        </div>
      </div>

    </div>
  )
}

export default Showroom


