import React, { useState } from "react";
import { baseUrl2 } from "../baseURL";

export default function CartProducts({
  openModal,
  updateCart,
  cartProductData
}) {
  // Initital States
  const [qty, setQty] = useState(parseInt(cartProductData.quantity));
  // Set Quantitty function
  const setQuantity = (e) => {
    let value = parseInt(e.target.value);
    const regExQTY = /^[\d]+/i;
    if (regExQTY.test(value)) {
      setQty(value);
    }
  };

  // Decrease Quantitty function
  const decrementQTY = () => {
    let decreasedValue = qty - 1;
    if (decreasedValue > 0) {
      setQty(decreasedValue);
    }
  };

   // Increase Quantitty function
  const incrementQTY = () => {
    let increasedValue = qty + 1;
    setQty(increasedValue);
  };

  return (
    <tr
      id={
        "cart-product-" + cartProductData.product_id + "-" + cartProductData.type_id
      }
      className="mb-4 mb-lg-0"
    >
      <td className="pl-1">
        <button className="btn" onClick={() =>
        openModal(cartProductData.product_id, cartProductData.type_id,cartProductData.sample)
      }>
        <img 
          style={{ width: "25px" }}
          src={baseUrl2 + "/assets/img/cut.png"}
          alt=""
        /></button>

      </td>
      <td className="mt-5">
        <img className="pt-3 pb-3" src={cartProductData.product_image}  alt="" />
      </td>
      <td style={{ width: "228px" }}>{cartProductData.product_name}<br></br>{cartProductData.sample==1? <span style={{fontSize:"12px"}}>(Fabric Sample)</span>:""}</td>
      <td><span className="pisa">Price:</span>
        <span><i className="fa fa-inr price"></i>{" "}</span>
        {cartProductData.price}
      </td>
      <td>
        <div style={{display: "inline-block"}}>
        <div className="cart-plus-minus">
          <span
            type="button"
            className="btn btn-primary
                cart-minus-1"
            onClick={decrementQTY}
            style= {{background: "rgb(0, 0, 0)", color: "white"}}
          >
            -
          </span>
          <input
            style={{
              height: "42px",
              width: "56px",
              textAlign: "center",
            }}
            type="text"
            name="qty"
            id="qty"
            maxlength="12"
            value={qty}
            title="Qty"
            onChange={setQuantity}
            className="input-text qty"
          />
          <span
            type="button"
            className="btn btn-primary
                cart-plus-1"
                style= {{background: "rgb(0, 0, 0)", color: "white"}}
            onClick={incrementQTY}
          >
            +
          </span>
        </div>
        </div>
        {/* <ProductQuantity qtt={cartProductData.Quantity} /> */}
      </td>
      <td>
        <button
          onClick={(e) =>
            updateCart(cartProductData.product_id, qty, cartProductData.type_id,cartProductData.sample)
          }
          style={{ background: "rgb(0, 0, 0)", color: "white" }}
          className="btn"
        >
          Update
        </button>
      </td>
      <td><span className="tot">Total:</span>
        <span><i className="fa fa-inr price"></i>{" "}</span>
        {cartProductData["total="]}
      </td>
    </tr>
  );
}
