import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { baseUrl } from "../baseURL";
export default function BlogDetails(props) {
  const [BlogData, setBD] = useState([]);
  const [RelatedData, setRD] = useState([]);
  const location = useLocation();
  const [id, setId] = useState(location.state);
  // Getting id from page url
  const api_id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  // alert(JSON.stringify(location.state))
  useEffect(() => {
    fetchBlogsData()
  }, [id]);
  const fetchBlogsData = async () => {
    try {
      const data = await axios(
        baseUrl + "Apicontroller/get_blog_details/" + id
      );
      setBD(data.data.data);
      setRD(Object.values(data.data.related));
      console.log("BlogData", BlogData);
      console.log("RelatedData", RelatedData);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    } catch {
      console.log("Issue in get blog.")
    }
  }
  // alert(JSON.stringify(BlogData));
  return (
    <>
      <section>
        <div class="container-fluid" style={{ marginTop: '8rem' }}>
          <div class="center">
            <div class="">
              {/* <h1>hello</h1> */}
              <p style={{ textAlign: 'center' }}>{BlogData.article_date}</p>
              <h2 style={{ textAlign: 'center' }}><b>{BlogData.heading}</b></h2>
              <hr />
              <img loading="lazy" src={BlogData.image} alt={BlogData.name} className="img-fluid" width="100%" />
              <div class="text-justify mt-5">
                <div dangerouslySetInnerHTML={{ __html: BlogData.full_description }} />
                {/* <p>{BlogData.full_description}</p> */}
                {/* <p>Stokes Quartz - Santorini White &nbsp;Worktops paired with Navy Blue Kitchen Units: A Match Made in
                  Heaven</p>
                <p>If you're looking for a kitchen design that is both elegant and timeless, then look no further than
                  Santorini white quartz worktops. This pairing creates a stunning and chic look that will add a touch of
                  sophistication to any home. Here's why we think this combination is a match made in heaven.</p>
                <p>Firstly, white quartz worktops are a popular choice for modern kitchens due to their durability and low
                  maintenance. They are resistant to stains, scratches, and heat, making them the ideal surface for
                  preparing food. Santorini white quartz, in particular, has a beautiful marble-like appearance that will
                  add a touch of luxury to your kitchen.</p>
                <p>Pairing the white quartz with navy blue kitchen units creates a stunning contrast that is both bold and
                  elegant. Navy blue is a timeless color that never goes out of style, and it pairs perfectly with the cool
                  tones of the white quartz. It's a classic combination that will look great for years to come.</p>
                <p>Another reason why this pairing works so well is that it's versatile. You can add pops of color with
                  accessories or keep the look minimalistic with white and metallic accents. The navy blue units will also
                  complement a variety of other colors, making it easy to switch up your decor over time.</p>
                <p>If you're concerned about the navy blue units making your kitchen feel dark or small, don't be. The white
                  quartz worktops will reflect light and create a bright and airy feel, while the navy blue units will add
                  depth and dimension to the space.</p>
                <p>In conclusion, Santorini white quartz worktops with navy blue kitchen units are a match made in heaven.
                  It's a timeless and elegant combination that will add a touch of sophistication to any home. If you're
                  looking to update your kitchen, consider this stunning pairing for a fresh and modern look.</p>
                <p></p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {RelatedData?.length > 0 &&
        <section>
          <div className="container-fluid" style={{ marginTop: '4rem' }}>
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ textAlign: 'center' }}>Related Blogs</h2>
                <hr />
              </div>
            </div>
            <div className="row">
              {RelatedData.map((elem, i) => {
                console.log(elem);
                return (
                  <div className="col-md-4" onClick={() =>setId(elem?.id)} style={{cursor:'pointer'}}>
                    {/* <Link
                    
                      to={{
                        pathname: `/blog-details`,
                        state: elem.id,
                      }}
                    > */}
                      <img src={elem.image} alt={elem.name} className="img-fluid" width="100%" />
                      <div className="text-justify mt-4">
                        <p>{elem.article_date}</p>
                        <h4>{elem.heading}</h4>
                        <p style={{ fontSize: '14px' }}>{elem.description}</p>
                      </div>
                    {/* </Link> */}
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      }
    </>
  );
};
