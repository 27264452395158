import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";
import { Redirect } from "react-router";

function loadRazorPay(){
  return new Promise((resolve)=>{

const script = document.createElement("script")
script.src = "https://checkout.razorpay.com/v1/checkout.js"
script.onload = ()=>{
  resolve(true)
}
script.onerror = ()=>{
  resolve(false)
}  
document.body.appendChild(script)
})
}

const DEV = document.domain === "localhost"

export default function Place(props) {

  const displayRazorpay = async ()=>{

const res = await loadRazorPay()
if(!res){
  alert("falied to load razor Pay SDK")
  return
}


    var options = {
      key: DEV?"":"rzp_test_i9kuSqNYCuJXyj", // Enter the Key ID generated from the Dashboard
      amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Acme Corp",
      description: "Test Transaction",
      image: "https://www.createspaces.in/assets/img/logo.png",
      order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: "https://www.createspaces.in/",
      prefill: {
          name: "Gaurav Kumar",
          email: "gaurav.kumar@example.com",
          contact: "9999999999"
      },
      notes: {
          "address": "Razorpay Corporate Office"
      },
      theme: {
          "color": "#3399cc"
      }
  };
  // var rzp1 = new Razorpay(options);
  }
  useEffect(() => {
    const checkout = async () => {
      const calculatedData = JSON.parse(
        localStorage.getItem("create_outdoor_calculations")
      );
      var payment_type = localStorage.getItem("payment_type_input");
      const checkoutData = new FormData();
      checkoutData.append("token_id", localStorage.getItem("user_token"));
      checkoutData.append(
        "email",
        localStorage.getItem("create_outdoor_email")
      );
      checkoutData.append(
        "password",
        localStorage.getItem("create_outdoor_password")
      );
      checkoutData.append("txn_id", calculatedData.txn_id);
      checkoutData.append("payment_type", payment_type);
      for (let pair of checkoutData.entries()) {
        console.log("checkout data", pair[0], ":", pair[1]);
      }
      try {
        
        const response = await axios.post(
          baseUrl + "Apicontroller/checkout",
          checkoutData
        );
        // alert(JSON.parse(response));
        if (response.data.status === 201) {
          // alert('this is if statement....')
          props.history.push("/fail");
        }
          // alert("hi this is place",response.data.razor_id);
          if(response.data.razor_id){
            let token_id = localStorage.getItem("user_token");
            let email_id = localStorage.getItem("create_outdoor_email");
            let passowrd = localStorage.getItem("create_outdoor_password");
            let myData = {
              email: email_id,
              password: passowrd,
              token_id: token_id,
              razor_id: response.data.razor_id,
            }
            localStorage.setItem("myRazorData",JSON.stringify(myData));
            const razorData = new FormData();

for ( let key in myData ) {
  razorData.append(key, myData[key]);
}

            let myRazorResponse = await axios.post( baseUrl + "Apicontroller/check_payment",razorData);
            displayRazorpay()
            if(myRazorResponse.data.status === 201){
              props.history.push("/fail");
            }
          }
        
      } catch {
        alert("inside catch block!")
        console.log("Issue in checkout");
      }
    };
    checkout();
  });
  if (
    // localStorage.getItem("create_outdoor_email") !== "" &&
    // localStorage.getItem("create_outdoor_email") !== null &&
    // localStorage.getItem("create_outdoor_email") !== undefined
    localStorage.getItem("user_token")
  ) {
    return (
      <>
        {/* <br /> */}
        <div className="container pt-5 mt-4">
            <div className="row pt-5 mt-5">
            <div className="col-md-12">
              <h2 style={{ textAlign: "center" }}> &#9989;</h2>
              <h1 style={{ textAlign: "center", color: "green" }}>
                {" "}
                Thank you
              </h1>
              <p style={{ textAlign: "center", color: "green" }}>
                {" "}
                your order has been successfully placed and is on the way
              </p>
            </div>
          </div>
          <div className="row">
            <div style={{ display: "flex" }} className="col-md-12">
              <div className="col-md-6 text-center" style={{ width: "50%" }}>
                <Link to="myorders">View Order</Link>
              </div>

              {/* <a href="index.html"> */}
              <div className="col-md-6 text-center" style={{ width: "50%" }}>
                <Link to="/">Continue Shopping....</Link>
              </div>
              {/* </a> */}
            </div>
          </div>
        </div>
      </>
    );
  } else {

    return <Redirect to="/register" />;
  }
}
