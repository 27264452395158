import React, { Component } from "react";
import { baseUrl } from "./baseURL";
const ProductContext = React.createContext();

// Provider
// Consumer
class ProductProvider extends Component {
  state = {
    token: "",
    email: "",
    password: "",
  };
  componentDidMount() {
    const user_token = localStorage.getItem("user_token");
    const user_email_id = localStorage.getItem("create_outdoor_email");
    const user_password = localStorage.getItem("create_outdoor_password");
    // alert("This is email")
    if (user_email_id && user_password) {
      // alert("Email and password are all set");
      this.setState({ email: user_email_id, password: user_password, token: user_token });
    } else if (!user_token) {
      // alert("Something went wrong");
      fetch(
        baseUrl +  "Token/get_token"
      ).then((resp) => {
        resp.json().then((result) => {
          // console.log("token", result.token);
          localStorage.setItem("user_token", result.token);
          this.setState({ token: result.token });
        });
      });
    } else {
      console.log("user_tok", user_token);
      this.setState({ token: user_token });
    }
  }
  // setCartLength = (length) => {
  //   this.setState({ cartLength: length });
  // };
  render() {
    return (
      <ProductContext.Provider
        value={{
          ...this.state,
          // setCartLength: this.setCartLength,
        }}
      >
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}
const ProductConsumer = ProductContext.Consumer;
export { ProductProvider, ProductConsumer };
