import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Toast } from "react-bootstrap";
import { baseUrl, baseUrl2 } from "../baseURL";
const Header = (props) => {
  if (process.env.REACT_APP_API_KEY === 'true') {
    console.log = function () { }
  }
  //   const email_id = props.email === "" ? "" : props.email;
  const [categoryData, setCatD] = useState([]);
  const [cartLength, setCL] = useState(0);
  const [popUpMessage, setPUM] = useState("");
  const [email, setEmail] = useState("");
  const [showPopUp, setSPU] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  const toggleShowPopUp = () => setSPU(!showPopUp);
  const lookForSearch = (e) => {
    if (e.key === "Enter") {
      document.getElementById("searchbtn").click();
      setSearchText("")
    }
  };
  const [topbar, setTopbar] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await axios(baseUrl + "Apicontroller/get_topbar/");
        console.log("Top bar", data.data.data);
        setTopbar(data.data.data);
      } catch {
        console.log("Issue in Top bar.");
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await axios(baseUrl + "Apicontroller/get_all_category/");
        console.log("Header", data.data.data);
        setCatD(data.data.data);
      } catch {
        console.log("Issue in Header all categories.");
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await props.countCartLengthFunction();
        console.log("Length", data);
        setSPU(true);
        setPUM(props.popUpMessage);
        if (data !== undefined) {
          setCL(data);
        } else {
          setCL(0);
        }
        setEmail(props.email);
      } catch {
        console.log("Issue in getting header cart length.");
      }
    }
    fetchData();
  });
  const closeSideBar = () => {
    document.querySelector(".side_bar").style.transform = "translateX(-900px)";
  };
  const logoutFromSideBar = () => {
    closeSideBar();
    props.logoutUser();
  };
  return (
    <>
      {topbar ? (
        <div className="top-nav">
          <div className="navbar-top d-xs-none d-sm-none d-md-block d-none">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 col-12 text-white d-flex"
                  style={{ lineHeight: "2.5rem" }}
                >
                  {topbar
                    ? topbar.map((elem, i) => (
                      <a href={elem.link} key={elem.name} className="promo-bar-text">
                        {elem.name}
                      </a>
                    ))
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-top-mb pt-1 pb-2 d-xs-block d-sm-block d-md-none d-block">
            <div className="container-fluid">
              <div className="row">
                <div id="demo" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    {topbar
                      ? topbar.map((elem, i) => (
                        <div key={elem.link}
                          className={
                            "carousel-item text-center" +
                            (i === 0 ? " active" : "")
                          }
                        >
                          <a href={elem.link} className="promo-bar-text-mb">
                            {elem.name}
                          </a>
                        </div>
                      ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <header>
        <div className="container mw-100 pt-2">
          <div className="row">
            <div className="col-lg-3 col-4 side_link d-md-none d-flex align-items-center">
              {/* <div className=""> */}
              <img
                className="nav_bar_img"
                src={baseUrl2 + "/assets/img/navbar.png"}
                alt=""
               
              />
               <img
                    src={baseUrl2 + "/assets/img/loupe.png"}
                    alt="search"
                    id="searchbtn"
                    onClick={toggle}
                    style={{   margin: "0px 0px 0px 13px",width: "20px"}}

    
                  />
              {/* <span>Menu</span> */}
              {/* </div> */}
            </div>
            <div className="col-md-2 col-4 brand_logo text-center">
              <Link to="/">
                <img src={baseUrl2 + "assets/img/logo.png"} alt="" />
              </Link>
            </div>
            <div className="col-3 side_link d-md-none d-block ml-auto">
              <div className="d-flex text-center h-100 justify-content-around align-items-center">
                {/* <Link to={email === "" ? "/register" : "/profile"}>
                  <img src={baseUrl2+"/assets/img/user.png"} alt="" />
                </Link> */}
                   <Link to="/Showroom">
              <i class="bi bi-geo-alt pin"></i>
                </Link> 
                <Link to="/cart" className="mr-2">
                  <span className="cart-count">{cartLength}</span>
                  <img src={baseUrl2 + "/assets/img/cart.png"} alt="" />
                </Link>
                {/* </div> */}
                {email !== "" ? (
                  <Link to="/wishlist">
                    <img src={baseUrl2 + "/assets/img/heart.png"} alt="" />
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>

            {isOpen && <div className="col-md-6 mr-auto ml-auto mt-lg-auto mb-lg-auto mt-0 mt-lg-0 mb-1 hello-0">
              <div className="top_serch">
                <input
                  type="text"
                  name="search"
                  placeholder="Search for a product"
                  value={searchText}
                  onKeyPress={(e) => lookForSearch(e)}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Link
                  to={{
                    pathname: `/search-result/${searchText}`,
                  }}
                >
                  <img
                    src={baseUrl2 + "/assets/img/search.png"}
                    alt="search"
                    id="searchbtn"
                  />
                </Link>
              </div>
            </div>}

             <div className="col-md-6 mr-auto ml-auto mt-lg-auto mb-lg-auto mt-0 mt-lg-0 mb-1 hello-1">
              <div className="top_serch">
                <input
                  type="text"
                  name="search"
                  placeholder="Search for a product"
                  value={searchText}
                  onKeyPress={(e) => lookForSearch(e)}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Link
                  to={{
                    pathname: `/search-result/${searchText}`,
                  }}
                >
                  <img
                    src={baseUrl2 + "/assets/img/search.png"}
                    alt="search"
                    id="searchbtn"
                  />
                </Link>
              </div>
            </div>
            



            <div className="col-md-3 side_link d-none d-md-block pl-0 pr-0">
              <div className="d-flex text-center h-100 justify-content-around align-items-center">
              <Link to="Showroom">
              {/* <i class="bi bi-geo-alt pin"></i> */}
              <img src={baseUrl2 + "/assets/img/location.png"} alt="" />
              <span className="icn_label">Location</span>
                </Link> 
                {email === "" ? (
                  <Link to="/register">
                    <img src={baseUrl2 + "/assets/img/user.png"} alt="" />
                    <span className="icn_label">Login / Register</span>
                  </Link>
                ) : (
                  <Link to="/profile">
                    <img src={baseUrl2 + "/assets/img/user.png"} alt="" />
                    <div className="dropdown">
                      <span
                        className="dropdown-toggle icn_label"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {localStorage.getItem("create_outdoor_name")}
                      </span>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link className="dropdown-item icn_label" to="/profile">
                          My Account
                        </Link>
                        <Link className="dropdown-item icn_label" to="/myorders">
                          My Orders
                        </Link>
                        <span
                          className="dropdown-item icn_label"
                          onClick={() => props.logoutUser()}
                        >
                          Logout
                        </span>
                      </div>
                    </div>
                  </Link>
                )}
                  
                <Link to="/cart">
                  <span className="cart-count">{cartLength}</span>
                  <img src={baseUrl2 + "/assets/img/cart.png"} alt="" />
                  <span className="icn_label">Cart</span>
                </Link>
              
                {email !== "" ? (
                  <Link to="/wishlist">
                    <img src={baseUrl2 + "/assets/img/heart.png"} alt="" />
                    <span className="icn_label">Wishlist</span>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {popUpMessage !== "" ? (
            <Toast
              className="position-absolute popupboxInHeader"
              // style={{ top: "5rem", zIndex: 7, right: "10rem" }}
              show={showPopUp}
              onClose={toggleShowPopUp}
            >
              <Toast.Header
                style={{ background: "rgb(0, 0, 0)", color: "white" }}
              >
                <span>{popUpMessage}</span>
              </Toast.Header>
            </Toast>
          ) : (
            ""
          )}
        </div>
        <hr className="mt-0 mb-0" />
        {/* <!-- mobile side bar start --> */}
        <section className="side_bar d-block d-lg-none d-md-none">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <span className="close_side d-flex d-lg-none d-md-none">
                  <img src={baseUrl2 + "/assets/img/cut.png"} alt="" />
                </span>
              </div>
              <div className="col-md-12 mt-5 text-center w-100">
                <span className="d-flex d-lg-none d-md-none">
                  <img src={baseUrl2 + "/assets/img/logo.png"} alt=""  style={{width: "auto"}} />
                </span>
              </div>
              <div className="col-md-12 p-0 pt-2">
                <div className="accordion accdesc" id="header_sidebar">
                  {categoryData
                    ? categoryData.map((elem, i) => {
                      console.log("Check head", elem);
                      let iPlusOne = i + 1;
                      return (
                        <div className="card carddesc" key={elem.category_id}>
                          <div className="card-header  " id="heading1">
                            <h2
                              className="mb-0 d-flex w-100"
                              style={{ justifyContent: "space-around", fontSize: '13px', letterSpacing: '.15em', fontWeight: '400' }}
                            >
                              <Link
                                to={"/category/" + elem.category_id}
                                className="pt-2"
                                onClick={() => closeSideBar()}
                              >
                                <span
                                  style={{ fontSize: "1rem" }}
                                  className="d-block"
                                >
                                  {elem.name}
                                </span>
                              </Link>
                              <button
                                className="btn btn-link w-50 ml-auto text-end"
                                type="button"
                                data-toggle="collapse"
                                data-target={"#collapse" + iPlusOne}
                                aria-expanded="true"
                                aria-controls={"collapse" + iPlusOne}
                                style={{"padding-right":" 0px"}}
                              >
                                <i className="fa fa-chevron-circle-down"></i>
                                {/* Salon */}
                              </button>
                            </h2>
                          </div>
                          {elem.sub_category.length !== 0 ? (
                            <div
                              id={"collapse" + iPlusOne}
                              data-parent="#header_sidebar"
                              className="collapse"
                              aria-labelledby={"heading" + iPlusOne}
                            >
                              <div className="card-body">
                                {elem
                                  ? elem.sub_category.map((elem, i) => {
                                    return (
                                      <ul key={elem.sub_id} className="pl-0" style={{listStyle:'none'}}>
                                        {/* console.log("elemidin header", {elem.id}) */}
                                        <li key={elem.name}>
                                          <Link
                                            onClick={() => closeSideBar()}
                                            to={{
                                              pathname: `/all-product/${elem.sub_id}`,
                                              state: elem.sub_id,
                                            }}
                                          >
                                            {elem.name}
                                          </Link>
                                        </li>
                                      </ul>
                                    );
                                  })
                                  : null}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                    : ""}
                     <div className="card">
                    <div className="card-header " id="heading1">
                      <h2 className="mb-0" style={{ fontSize: '13px', letterSpacing: '.15em', fontWeight: '400' }}>
                        <Link onClick={() => closeSideBar()} to="/Showroom" className="pt-2">
                          <span style={{ fontSize: "1rem" }}>Showroom</span>
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header " id="heading1">
                      <h2 className="mb-0" style={{ fontSize: '13px', letterSpacing: '.15em', fontWeight: '400' }}>
                        <Link onClick={() => closeSideBar()} to="/export" className="pt-2">
                          <span style={{ fontSize: "1rem" }}>Bulk Order</span>
                        </Link>
                      </h2>
                    </div>
                  </div>
               
                  {/* <div className="card">
                    <div className="card-header " id="heading1">
                      <h2 className="mb-0" style={{fontSize: '13px', letterSpacing: '.15em', fontWeight: '400'}}>
                        <Link
                          onClick={() => closeSideBar()}
                          className="pt-2"
                          to="/corporate-order"
                        >
                          <span  style={{ fontSize: "1rem" }}>
                            Custom Order
                          </span>
                        </Link>
                      </h2>
                    </div>
                  </div> */}
                  
                  <div className="card">
                    <div className="card-header " id="heading1">
                      <h2 className="mb-0" style={{ fontSize: '13px', letterSpacing: '.15em', fontWeight: '400' }}>
                        <Link
                          onClick={() => closeSideBar()}
                          className="pt-2"
                          to="/Gallary2"
                        >
                          <span style={{ fontSize: "1rem" }}>
                            Image Gallery
                          </span>
                        </Link>
                      </h2>
                    </div>
                  </div>
                 
                  {email !== "" ? (<div className="card">
                    <div className="card-header " id="heading1">
                      <h2 className="mb-0" style={{ fontSize: '13px', letterSpacing: '.15em', fontWeight: '400' }}>
                        <a
                          onClick={() => logoutFromSideBar()}
                          className="pt-2"
                        >
                          <span style={{ fontSize: "1rem" }}>
                            Logout
                          </span>
                        </a>
                      </h2>
                    </div>
                  </div>) : null}
                  {email !== "" ? (
                    <>
                      <div className="text-center d-flex w-100">
                        <Link
                          className="icn_label text-center"
                          style={{
                            width: "90%",
                            marginLeft: "20%",
                            marginRight: "5%",
                          }}
                          to="/myorders"
                        >
                          <button
                            style={{
                              background: "rgb(0, 0, 0)",
                              color: "white",
                            }}
                            className="btn icn_label w-100"
                            onClick={() => closeSideBar()}
                          >
                            My Orders
                          </button>
                        </Link>
                        <Link
                          className="icn_label text-center"
                          style={{
                            width: "90%",
                            marginLeft: "5%",
                            marginRight: "5%",
                          }}
                          to="/profile"
                        >
                          <button
                            style={{
                              background: "rgb(0, 0, 0)",
                              color: "white",
                            }}
                            className="btn icn_label w-100"
                            onClick={() => closeSideBar()}
                          >
                            My Account
                          </button>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="text-center icn_label w-50">
                      <Link
                        className="icn_label text-center"
                        style={{
                          width: "90%",
                          marginLeft: "37%",
                          marginRight: "5%",
                        }}
                        to="/register"
                      >
                        <button
                          style={{
                            background: "rgb(0, 0, 0)",
                            color: "white",
                          }}
                          onClick={() => closeSideBar()}
                          className="btn icn_label w-100"
                        >
                          Login / Register
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- mobile side bar end --> */}
        <div className="container-fluid p-0 top_menu w-100 m-0  d-none d-lg-block d-md-block">
          <div className="row  p-0 w-100 m-0 h-100">
            <div className="col-md-12 p-0 w-100 m-0 h-100 pl-1 pr-1">
              <ul className="first_ul mt-5 mt-lg-0 ml-5 mr-5">
                {categoryData
                  ? categoryData.map((elem, i) => {
                    return (
                      <li  key={elem.category_id+'-'}>
                        <Link
                          to={"/category/" + elem.category_id}
                          className={i === 0 ? "" : ""}
                        >
                          {elem.name}
                        </Link>
                        {elem.sub_category.length !== 0 ? (
                          <div className="sub_menu">
                            <div className="container-fluid pb-3 pt-3">
                              <div
                                className="row"
                                style={{ flexDirection: "column" }}
                              >
                                {elem
                                  ? elem.sub_category.map((elem, i) => {
                                    return (
                                      <div className="col-md-3"  key={elem.sub_id+'-'}>
                                        <ul>
                                          <li>
                                            <Link
                                              to={{
                                                pathname: `/all-product/${elem.sub_id}`,
                                                state: elem.sub_id,
                                              }}
                                            >
                                              {elem.name}
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    );
                                  })
                                  : ""}

                                  
                                  
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })
                  : null}
                {/* <li>
                  <Link to="/export">Bulk Order</Link>{" "}
                </li> */}
                {/* <li>
                  {" "}
                  <Link to="/corporate-order">Custom Order</Link>
                </li> */}
                <li>
                  {" "}
                  <Link to="/Gallary2">Image Gallery</Link>
                </li>
               
              </ul>
            </div>
          </div>
        </div>
        <hr className="mt-0 mb-0" />
        <div className="marq hide1" >
          <div id="demo" className="carousel slide pointer-event" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item text-center">
                <ul className="hp-banner-top__text " style={{ paddingLeft: "0rem" }}>
                  <li className="hp-banner-top__trust ">
                    <a href="javascript:void(0)" className="boxLink"></a>
                    <i className="fa fa-thumbs-up"></i>
                    <span className="fontchange">
                      Quality Guaranteed
                    </span>
                  </li>
                  <li className="align">
                    <a href="javascript:void(0)" className="boxLink boxlinks"></a>
                    <i className="fa fa-wrench"></i>
                    <span className="fontchange">
                      Customisation
                    </span>
                  </li>
                </ul>
              </div>
              <div className="carousel-item text-center">
                <ul className="hp-banner-top__text " style={{ paddingLeft: "0rem" }}>
                  <li className="align">
                    <a href="javascript:void(0)" className="boxLink boxlinks"></a>
                    <i className="fa fa-credit-card"></i>
                    <span className="fontchange">
                      EMI Available
                    </span>
                  </li>
                </ul>
              </div>
              <div className="carousel-item text-center active">
                <ul className="hp-banner-top__text " style={{ paddingLeft: "0rem" }}>
                  <li className="hp-banner-top__trust">
                    <a href="javascript:void(0)" className="boxLink"></a>
                    <i className="fa fa-truck"></i>
                    <span className="fontchange">
                      Free Nationwide Delivery
                    </span>
                  </li>
                  <li className="hp-banner-top__trust">
                    <Link to="/AllReviews">
                      <div style={{ margin: "0 0 8px 7px" }}>
                        <i className="fa fa-star" style={{ fontSize: "20px" }}></i>
                        <i className="fa fa-star" style={{ fontSize: "20px" }}></i>
                        <i className="fa fa-star" style={{ fontSize: "20px" }}></i>
                        <i className="fa fa-star" style={{ fontSize: "20px" }}></i>
                        <i className="fa fa-star" style={{ fontSize: "20px",  }}></i></div>
                      <span className="fontchange ">
                      300+ Reviews
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="marq hide1" >
          <marquee behavior="" Scrollamount="12" direction="">
          <ul className="hp-banner-top__text ">
            <li className="hp-banner-top__trust">
              <a href="javascript:void(0)" className="boxLink"></a>
              <i className="fa fa-truck"></i>
              <span className="fontchange">
              Free Nationwide Delivery
              </span>
            </li>
         <li className="hp-banner-top__trust">
          <a href="javascript:void(0)"  className="boxLink"></a>
         <div><i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i></div>
          <span className="fontchange ">
          All Reviews
          </span>
        </li>
        <li className="hp-banner-top__trust ">
          <a href="javascript:void(0)" className="boxLink"></a>
          <i className="fa fa-thumbs-up"></i>
          <span className="fontchange">
          Quality Guaranteed
          </span>
        </li>
        <li className="align">
        <a href="javascript:void(0)" className="boxLink boxlinks"></a>
        <i className="fa fa-wrench"></i>
        <span className="fontchange">
        Customisation
        </span>
        </li>
        <li className="align">
        <a href="javascript:void(0)" className="boxLink boxlinks"></a>
        <i className="fa fa-credit-card"></i>
        <span className="fontchange">
        EMI Available
        </span>
        </li>
      </ul> */}
        <ul className="hp-banner-top__text hide2" >
          <li className="hp-banner-top__trust">
            <a href="javascript:void(0)" className="boxLink"></a>
            <i className="fa fa-truck"></i>
            <span className="fontchange">
              Free Nationwide Delivery
            </span>
          </li>
          {/* <li className="hp-banner-top__trust">
          <a href="javascript:void(0)" className="boxLink"></a>
         <div><i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i></div>
          <span className="fontchange box">
         Google Rating
          </span>
        </li> */}
          <li className="align">
            <Link to="/AllReviews">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <span className="fontchange">
               300+ Reviews
              </span>
            </Link>
          </li>
          <li className="hp-banner-top__trust ">
            <a href="javascript:void(0)" className="boxLink"></a>
            <i className="fa fa-thumbs-up"></i>
            <span className="fontchange">
              Quality Guaranteed
            </span>
          </li>
          <li className="align">
            <a href="javascript:void(0)" className="boxLink boxlinks"></a>
            <i className="fa fa-wrench"></i>
            <span className="fontchange">
              Customisation
            </span>
          </li>
          <li className="align">
            <a href="javascript:void(0)" className="boxLink boxlinks"></a>
            <i className="fa fa-credit-card"></i>
            <span className="fontchange">
              EMI Available
            </span>
          </li>
        </ul>
      </header>
    </>
  );
};
export default Header;
