import axios from "axios";
import React from "react";
import { baseUrl } from "../baseURL";

class Chair extends React.Component {
  constructor() {
    super();
    this.switchImage = this.switchImage.bind(this);
    this.switchImages = this.switchImages.bind(this);
    this.switchImagesByHover = this.switchImagesByHover.bind(this);
    this.stopSwitchImagesByHover = this.stopSwitchImagesByHover.bind(this);
    this.state = {
      chair: [],
      currentImage: 0,
    };
  }
  componentDidMount() {
    const fetchChairData = async () => {
      try {
        let response = await axios(
          baseUrl+"Apicontroller/get_chair"
        );
        console.log("chair data", response.data.data);
        this.setState({ chair: response.data.data });
      } catch {
        console.log("Issue in fetching chair data");
      }
    };
    const fetchChairText = async () => {
      try {
        let response = await axios(
          baseUrl+"Apicontroller/get_chair_text"
        );
        console.log("chair text:", response);
        this.setState({ chairText: response.data.data[0] });
      } catch {
        console.log("Issue in chair text");
      }
    };
    fetchChairData();
    fetchChairText();
  }
  switchImage() {
    if (this.state.currentImage < this.state.chair.length - 1) {
      this.setState({
        currentImage: this.state.currentImage + 1,
      });
    } else {
      this.setState({
        currentImage: 0,
      });
    }
    return this.currentImage;
  }
  switchImages() {
    if (!this.state.rotating) {
      this.myInterval = setInterval(this.switchImage, 100);
      this.setState({ rotating: true });
      document.getElementById("chair-rotate-btn").innerHTML =
        "Stop Auto Rotate";
    } else {
      clearInterval(this.myInterval);
      this.setState({ rotating: false });
      document.getElementById("chair-rotate-btn").innerHTML =
        "Start Auto Rotate";
    }
  }
  switchImagesByHover() {
    if (!this.state.rotating) {
      this.myInterval = setInterval(this.switchImage, 100);
      this.setState({ rotating: true });
    } else {
      clearInterval(this.myInterval);
      this.setState({ rotating: false });
    }
  }
  stopSwitchImagesByHover() {
    if (!this.state.rotating) {
      this.myInterval = setInterval(this.switchImage, 100);
      this.setState({ rotating: true });
    } else {
      clearInterval(this.myInterval);
      this.setState({ rotating: false });
    }
  }
  render() {
    return (
      <>
        {/* <!-- our products end -->

<!-- 360 start --> */}
            
            {/* <img className="img_ab" src="assets/img/overlay.png" alt="" /> */}
            <div className="row">
              <div className="col-md-6 d-flex align-items-center">
                <div className="p-3">
                  <span className="short_text">{this.state.chairText? this.state.chairText.Heading:""}</span>
                  <h1 className="mt-3 font_50">{this.state.chairText? this.state.chairText.Sub_Heading:""}</h1>
                  <p>
                  {this.state.chairText? this.state.chairText.paregraph:""}
                  </p>
                  <button
                    className="view_btn"
                    id="chair-rotate-btn"
                    onClick={this.switchImages}
                  >
                    Start Auto Rotate
                  </button>
                </div>
              </div>
              <div className="col-md-6 rfcerf">
                <div className="container">
                  {this.state.chair ? (
                    <div
                      className="slideshow-container"
                      onMouseEnter={this.switchImagesByHover}
                      onMouseLeave={this.stopSwitchImagesByHover}
                    >
                      <img
                        src={
                          this.state.chair.length > 0
                            ? this.state.chair[this.state.currentImage].image
                            : ""
                        }
                        alt="cleaning images"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

        {/* <!-- 360 end -->*/}
      </>
    );
  }
}

export default Chair;
