import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { baseUrl } from "../baseURL";
export default function Checkout(props) {
  // console.log("props in checkout", props)
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [states, setStates] = useState([]);

  useEffect(() => {
    const getStates = async () => {
      try {
        let response = await axios(baseUrl + "Apicontroller/all_state_get");
        console.log("states:", response.data.data);
        setStates(response.data.data);
      } catch {
        console.log("Issue in get states.");
      }
    };
    getStates();
  }, []);
  useEffect(() => {
    // Getting email, password and token from local storage
    const user_token = localStorage.getItem("user_token");
    const user_email_id = localStorage.getItem("create_outdoor_email");
    const user_password = localStorage.getItem("create_outdoor_password");

    // If login/Registered
    if (user_email_id && user_password) {
      // alert("Email and password are all set");
      setToken(user_token);
      setEmail(user_email_id);
      setPassword(user_password);
    } else {
      setEmail("");
      setPassword("");

    }

    // If no token in local storage
    if (!user_token) {
      fetch(baseUrl + "Token/get_token").then((resp) => {
        resp.json().then((result) => {
          localStorage.setItem("user_token", result.token);
          setToken(result.token);

        });
      });
    }
    //  if token in local storage
    else {
      console.log("user_tok", user_token);
      console.log("token", token);
      // this.setState({ token: user_token });
      setToken(user_token);
    }
    console.log("token", token);
  }, []);
  const [defaultAddress, setDefaultAddress] = useState([])
  const fetchAddress = async () => {
    const fetchAddressData = new FormData();
    fetchAddressData.append("email", localStorage.getItem("create_outdoor_email"));
    fetchAddressData.append("password", localStorage.getItem("create_outdoor_password"));
    fetchAddressData.append("token_id", localStorage.getItem("user_token"));
    for (var pair of fetchAddressData.entries()) {
      console.log("fetchAddressData is heres", pair[0] + ", " + pair[1]);
    }
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/get_address",
        fetchAddressData
      );
      console.log("Address Data", response);
      if (response.data.status === 200) {
        setDefaultAddress(response.data.data[0])
      }
    } catch {
      console.log("Issue in Checkout");
    }
  }
  useEffect(() => {
    fetchAddress()
  }, [])
  const Checkout = async (e) => {
    e.preventDefault(e.target);
    const CheckoutData = new FormData(e.target);
    CheckoutData.append("email", email);
    CheckoutData.append("password", password);
    CheckoutData.append("token", token);
    for (var pair of CheckoutData.entries()) {
      console.log("CheckoutData is heres", pair[0] + ", " + pair[1]);
    }
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/calculate",
        CheckoutData
      );
      console.log("Checkout", response);
      localStorage.setItem("checkoutApiRes", JSON.stringify(response))
      if (response.data.status === 201 || response.data.code === 201) {
        document.querySelector("#checkoutError").innerHTML =
          response.data.message;
      } else if (response.data.status === 200 || response.data.code === 200) {
        document.querySelector("#checkoutError").innerHTML =
          "User successfully Checkout";
        // alert(JSON.stringify(response.data.data))
        localStorage.setItem(
          "create_outdoor_calculations",
          JSON.stringify(response.data.data)
        );
        props.history.push("/next");
      }
    } catch {
      console.log("Issue in Checkout");
    }
  };

  const [post_code, setPost_code] = useState(defaultAddress ? defaultAddress.post_code : "");
  const [phone, setPhone] = useState("");
  const validateCheckoutForm = (e) => {
    if (e.target.name === "post_code") {
      const reg = /^\d+$/;
      if (reg.test(e.target.value) || e.target.value === "") {
        setPost_code(e.target.value);
      }
    } else if (e.target.name === "phone") {
      const reg = /^\d+$/;
      if (reg.test(e.target.value) || e.target.value === "") {
        setPhone(e.target.value);
      }
    }
  };
  if (
    // localStorage.getItem("create_outdoor_email") !== "" &&
    // localStorage.getItem("create_outdoor_email") !== null &&
    // localStorage.getItem("create_outdoor_email") !== undefined
    localStorage.getItem("user_token")

  ) {
    return (
      <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
          <div className="row heading-div">
            <div className="col-md-12 d-flex">
              <div className="text-center fs-2 col-md-4">
                <span>Cart</span>{" "}
                <i
                  style={{ color: "rgb(0, 0, 0)" }}
                  className="fa fa-check-circle"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="fs-2 text-center col-md-4">
                <span>Address</span>{" "}
                <i
                  style={{ color: "rgb(0, 0, 0)" }}
                  className="fa fa-check-circle"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="fs-2 col-md-4 text-center">
                <span>Next</span>{" "}
                <i
                  style={{ color: "rgb(0, 0, 0)" }}
                  className="fa fa-circle"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 checkout_colm">
              <p
                id="checkoutError"
                className="alert alert-secondary alert-dismissible fade show mb-4"
              >
                Please fill the required details.
              </p>
              <form className="d-block" onSubmit={(e) => Checkout(e)}>
                <p>
                  <span className="method_span">1</span>SHIPPING ADDRESS
                </p>
                <span>Fields marked with * are required.</span>
                <label className="d-block">
                  Email Address <sub>*</sub>
                </label>
                <input type="email" name="address_email" defaultValue={defaultAddress ? defaultAddress.email : ""} required />
                {/* <div className="d-inline-flex align-items-baseline ">
                  <input className="mr-3" type="checkbox" name="subscription" />
                  <label>
                    {" "}
                    Subscribe to receive newsletter updates about the latest
                    products and trends for outdoor living. Your personal
                    details will not be shared
                  </label>
                </div>
                <hr /> */}
                <label className="d-block">
                  First Name <sub>*</sub>
                </label>
                <input type="text" name="first_name" defaultValue={defaultAddress ? defaultAddress.first_Name : ""} required />
                <label className="d-block">
                  Last Name <sub>*</sub>
                </label>
                <input type="text" name="last_name" defaultValue={defaultAddress ? defaultAddress.Last_Name : ""} required />
                <label className="d-block">
                  Post code <sub>*</sub>
                </label>
                <input
                  type="text"
                  name="post_code"
                  value={post_code}
                  onChange={(e) => validateCheckoutForm(e)}
                  minLength="6"
                  maxLength="6"
                  defaultValue={defaultAddress ? defaultAddress.post_code : ""}
                  required
                />
                <label className="d-block">
                  Street Address <sub>*</sub>
                </label>
                {/* <label className="d-block">Street Address: Line 1</label> */}
                <textarea
                  cols="50"
                  rows="3"
                  name="street_address"
                  defaultValue={defaultAddress ? defaultAddress.Street_address : ""}
                  required
                ></textarea>
                <label className="d-block">
                  State <sub>*</sub>
                </label>
                <select name="state" defaultValue={defaultAddress ? defaultAddress.state : ""} required>
                  <option disabled selected>
                    {" "}
                    Select State
                  </option>
                  {states
                    ? states.map((elem) => {
                      return (
                        <option value={elem.state_id}>{elem.state}</option>
                      );
                    })
                    : null}
                </select>
                {/* <input type="text" name="state" /> */}
                <label className="d-block">
                  City <sub>*</sub>
                </label>
                <input type="text" name="city" defaultValue={defaultAddress ? defaultAddress.city : ""} />
                <label className="d-block">
                  Phone Number <sub>*</sub>
                </label>
                <input
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={(e) => validateCheckoutForm(e)}
                  minLength="10"
                  maxLength="10"
                  defaultValue={defaultAddress ? defaultAddress.phone_number : ""}
                  required
                />
                <br />
                <button
                  type="submit"
                  style={{
                    background: "rgb(0, 0, 0)",
                    color: "white",
                  }}
                  className="btn mt-3"
                >
                  Next
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <Redirect to="/register" />;
  }
}
