import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router';
import { baseUrl } from '../baseURL';

export default function OrderDetails() {
	// Getting id from page url
	const api_id = window.location.pathname.substring(
		window.location.pathname.lastIndexOf("/") + 1
	  );
	  const [orderDetail, setOrderDetail] = useState([]);
	  useEffect(() => {
		const fetchOrders = async () => {
		  const orderDetailData = new FormData();
		  orderDetailData.append("token_id", localStorage.getItem("user_token"));
		  orderDetailData.append(
			"email_id",
			localStorage.getItem("create_outdoor_email")
		  );
		  orderDetailData.append(
			"password",
			localStorage.getItem("create_outdoor_password")
		  );
		  orderDetailData.append(
			"order_id",
			api_id
		  );
		  try {
			const response = await axios.post(
				baseUrl+"Apicontroller/orderdetail",
			  orderDetailData
			);
			console.log("order detail", response);
			if (response.data.status === 200) {
				setOrderDetail(response.data.data);
			}
		  } catch {
			console.log("Issue in order detail");
		  }
		};
		fetchOrders();
	  }, []);
  if(localStorage.getItem("create_outdoor_email")!=="" || localStorage.getItem("create_outdoor_name")!=="" || localStorage.getItem("create_outdoor_password")!==""){
    return (
        
        <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
		<div className="row heading-div">
            <div className="col-md-12 text-center">
              <h1 className="heading">Ordered Product Details</h1>
            </div>
          </div>
			<div className="row">
				{/* <div className="col-md-12">
					<p>Your selection <b>( 1 item )</b></p>
				</div> */}
				<div className="col-md-9">
					<table className="cart_table text-center">
						<tr className="mb-4 mb-lg-0 not_show" style={{background: "#edeee6"}}>
							<th className="pt-2 pb-2"></th>
							<th className="pt-2 pb-2"></th>
							<th className="pt-2 pb-2">PRODUCT</th>
							<th className="pt-2 pb-2">PRICE</th>
							<th className="pt-2 pb-2">QUANTITY</th>
                            <th className="pt-2 pb-2">TYPE NAME</th>
							<th className="pt-2 pb-2"></th>
						</tr>
						{orderDetail?orderDetail.map((elem, i)=>{
						return(
						<tr className="mb-4 mb-lg-0">
							<td className="pl-1">
								{/* // <img   style={{width:"25px"}} src="assets\img/cut.png"/> */}
							</td>
							<td>
								<img className="pt-3 pb-3" alt="product_img" src={elem.product_image}/>
							</td>
							<td style={{width: "228px"}}>{elem.product_name}<br></br>{elem.sample==1? <span style={{fontSize:"12px"}}>(Fabric Sample)</span>:""}</td>
							<td><span><i className="fa fa-inr"></i>	</span>{elem.price}</td>
							<td>
								{/* <div className="cart-plus-minus"> */}
                                <span>{elem.quantity}</span>
								{/* </div> */}
							</td>
                            <td>{elem.type_name}</td>
						</tr>
						)}):""}
					</table>
				</div>
				{/* <div className="col-md-3 mt-5 mt-lg-0">
					<table className="cart_table side_cart">
						<tr style={{background: "#edeee6"}}>
							<th className="pt-2 pb-2 pl-2 pr-2">Order Summary <span>(1 item)</span></th>
							<th></th>
						</tr>
						<tr >
							<td>Subtotal</td>
							<td><span>$	</span>350.00</td>
						</tr>

						<tr>
							<td>VAT</td>
							<td><span>$	</span>50.00</td>
						</tr>

						<tr>
							<td>Order Total</td>
							<td><span>$	</span>400.00</td>
						</tr>
						<tr>
							<td className="bor_ra p-0" colspan="2">
								<a href="checkout.html">
									<button className="cart_btn h-auto mob_btn"><i className="fa fa-lock mr-3" aria-hidden="true"></i>SECURE CHECKOUT</button>
								</a>
							</td>
						</tr>
					</table>
					<div className="row mt-4">
						<div className="col-5 m-auto">
							<img src="assets\img/norton_logo.png"/>
						</div>
						<div className="col-5 m-auto">
							<img src="assets\img/ssl_logo.png"/>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	</section>

        
    ) 
			} else{
				return <Redirect to="/" />
			}
}
