import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";

const Gallary2 = () => {
  const [gallary2Data, setGD] = useState([]);
  useEffect( () => {
    const fetchGallary2Data = async () => {
    try {
      const data = await axios(
        baseUrl+"Apicontroller/get_gallery2"
      );
      setGD(Object.values(data.data.data));
      console.log("gallaryData", data.data.data, gallary2Data);
    } catch {
		console.log("Issue in get gallary.")
	}
}
fetchGallary2Data()
  }, []);
  return (
    <section>
    <div className="col-md-12 col-12" style={{marginTop: '5rem'}}>
    <div className="row">
      {gallary2Data.map((elem, i) => {
        console.log(elem);
        return (
          <div className="col-12 col-md-6 mb-5 text-center">
            <div>
              <a href={elem.link}>
                <img src={elem.image} alt={elem.name} className="img-fluid" />
              </a>
{/* <h4>{elem.name}</h4> */}
            </div>
            <h2 className="text-center p-0 " style={{fontSize: '1.5rem', marginTop: '1rem' ,textTransform: 'capital',  zIndex: 50, color: 'black'}}>{elem.name}</h2>
          </div>

        );
      })}
    </div>
    </div>
    </section>
  );
};

export default Gallary2;
