import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../Img/placeholder.webp";
import { baseUrl, baseUrl2 } from "../baseURL";

const ProductCard = (props) =>{
  const [Avg, setAvg] = useState('a');
  const [RvCount, setRvCount] = useState(0);
  //   useEffect(()=>{
  // alert(`hi loader: ${props.loader}`)
  //   },[props.loader])
  if (props.isSlider === "true") {
    return (
      <>
        <div key={props.product_id+"proo"} className="p-2 col-md-12 col-12 col-sm-12">
          <div className="bor_ra">
            <Link
              to={{
                pathname: `/product-details/${props.product_url}`,
                state: props.product_url,
              }}
            >
              <LazyLoadImage src={props.productimage}
                placeholderSrc={PlaceholderImage}
                alt={props.productname}
                srcSet={`${props.productimage}?q=70 1200w, 
                ${props.productimage}?w=200&q=70 200w, 
                ${props.productimage}?w=400&q=70 400w, 
                ${props.productimage}?w=800&q=70 800w, 
                ${props.productimage}?w=1024&q=70 1024w`}
              />
              {/* <img
                // style={{ height: "10rem" }}product-card-img
                className=" img-fluid imgresonsive"
                src="/assets/img/placeholder.webp"
                alt={props.productname}
              /> */}
              {props.tag ?
                <span className="tag">{props.tag}</span>
                : null}
            </Link>
            <p >
              <small>
                {props.productname}
                {/* {props.productname.length > 23
              ? props.productname.substring(0, 23) + "..."
              : props.productname} */}
              </small>
            </p>
            {/* <div className="price_box_cut">
          <del>
            <span>
              <i className="fa fa-inr"></i> {props.mrp}
            </span>
          </del>
        </div> */}
            <div className="price_box">
              <p>
                {/* Price: */}
                <i className="fa fa-inr"></i> {props.price}
                {/* {productPrice} */}
              </p>
            </div>
            {!props.isMostPopular ? (<div>
              <small>
                <button
                  className={"cart_btn"}
                  onClick={() =>
                    props.addingToCartFunction(props.product_id, 1, props.type_id)
                  }
                >
                  ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
                </button>
              </small>
              {/* <AddToCart className={"cart_btn"} productDetail={props} styles={{}} /> */}
            </div>) : null}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div key={props.product_id + 'pro'} className="col-lg-4 col-md-4 col-6 mb-4 p-2">
          <div className="bor_ra">
            <Link
              to={{
                pathname: `/product-details/${props.product_url}`,
                state: props.product_url,
              }}
            >
              <LazyLoadImage src={props.productimage}
                placeholderSrc={PlaceholderImage}
                alt={props.productname}
              />
              {/* <img
                // style={{ height: "10rem" }} product-card-img
                className="img-fluid"
                src={props.productimage}
                alt={props.productname}
              /> */}
              {props.tag ?
                <span className="tag">{props.tag}</span>
                : null}
            </Link>
            <p>
              <small>
                {props.productname}
                {/* {props.productname.length > 23
              ? props.productname.substring(0, 23) + "..."
              : props.productname} */}
              </small>
            </p>
            {/* <div className="price_box_cut">
          <del>
            <span>
              <i className="fa fa-inr"></i> {props.mrp}
            </span>
          </del>
        </div> */}
            <div className="price_box">
              <p>
                {/* Price:  */}
                <i className="fa fa-inr"></i> {props.price}
                {/* {productPrice} */}
                {/* { Avg !=='a'? */}
                <>
                  <div className="d-flex align-items-center"><ReactStars
                    value={props.avg}
                    edit={false}
                    isHalf={true}
                    size={20}
                    activeColor="#ffd700"
                  />
                    <span className="ml-2" style={{ color: 'grey' }}>({props.rvCount})</span>
                  </div>
                </>
                {/* :null} */}
              </p>
            </div>
            <div>
              <small>
                <button
                  className={"cart_btn"}
                  onClick={() =>
                    props.addingToCartFunction(props.product_id, 1, props.type_id)
                  }
                >
                  ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
                </button>
              </small>
              {/* <AddToCart className={"cart_btn"} productDetail={props} styles={{}} /> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default  React.memo(ProductCard);