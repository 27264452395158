import React from 'react'

function Shippingreturn() {
    return (
        <div className="container">
        <div className="row heading-div mt-5 pt-4">
        <div className="col-md-12 text-center">
          <h1 className="heading mt-5">Shipping, Return & Refunds</h1>
        </div>
      </div>
      <div>
      <div className="col-md-10 ml-3">
      <p className="pt-1 mb-0 mt-3">
        Delivering Many Happy Customers Throughout India
        </p>
        </div>
        <div className="col-md-10 ml-3">
        <p className="pt-1 mb-0 mt-1">
        Standard delivery time is 20-27 days from the date of order.
        In any case an item cannot be made or shipped due to some unforeseen circumstances than full refund will be made.
          </p>
          </div>
           <p className="pt-1 mb-0 mt-1 ml-5">
           How do I Cancel an item purchased on Create Spaces?
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Cancellation in Case of Damage: In case if you received a damaged or defective product, bring it in the notice of delivery personnel immediately at the time of delivery and report a complaint at customer care at info@createspaces.in.
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Damage & defect will be assessed in 72 hours’, and a solution will be provided. If in the opinion of Create Spaces, the product is broken, it may be returned/replaced, or any other corrective measure may be taken, as deemed appropriate by Create Spaces, to address the issue
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Cancellation in case of wrong product: If the product does not comply with the specifications as per your original order, raise the issue immediately & report it to support
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        For more details, please get in touch with us on our customer care number or write to us at info@createspaces.in
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Products purchased on discount or during sale can not be cancelled.
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Order cannot be cancelled once made to measure or shipped.
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Return & Refund:
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        If you are eligible for any refund, the same shall be given to you as per the following guidelines:
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        All refunds & replacement process initiation shall be subject to pick up of all cancelled items from your/customer's premises.
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Post receiving the products back to the warehouse, a refund shall be initiated within 5-7 days. Any postage cost for return postage will be deducted from refund.
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Refund will be initiated via NEFT, cash or by the way payment was originally made.
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        Depending on the orders, the processing charges might be deducted.
        </p>
        <p className="pt-1 mb-0 mt-1 ml-5">
        <b>Any customised products cannot be returned.</b>
        </p>
        </div>
        </div>
    )
}

export default Shippingreturn
