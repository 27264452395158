import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ProductCard from "./ProductCardComponent";
import { baseUrl } from "../baseURL";
import { Redirect } from "react-router";
export default function WishList(props) {
  const token = props.token === "" ? "" : props.token;
  const email_id = props.email === "" ? "" : props.email;
  const password = props.password === "" ? "" : props.password;
  const [wishlistDetails, setWD] = useState([]);
  // Get Cart Data function
  const getWishlistData = async () => {
    try {
      var getWishlistData = new FormData();
      getWishlistData.append("token_id", token);
      getWishlistData.append("email", email_id);
      getWishlistData.append("password", password);
      // alert("getWishlistData", getWishlistData);
      for (var pair of getWishlistData.entries()) {
        console.log("getWishlistData is heres", pair[0] + ", " + pair[1]);
      }
      const response = await axios.post(
        baseUrl+"Apicontroller/view_wishlist",
        getWishlistData
      );
      console.log("Wishlist Data:", response.data);
      setWD(response.data.data);
    } catch {
      console.log("Issue in getting wishlist data");
    }
  };
  useEffect(() => {
    getWishlistData();
  }, [email_id, password, token]);

  // delete product in cart
  const removeFromWishlist = async (id, type_id) => {
    try {
      const data = {
        product_id: id,
        type_id,
        email_id,
        password,
        token
      };
      const removewishlistData = new FormData();
      removewishlistData.append("product_id", data.product_id);
      removewishlistData.append("type_id", type_id);
      removewishlistData.append("email", email_id);
      removewishlistData.append("password", password);
      removewishlistData.append("token_id", token);
      for (let [key, value] of removewishlistData) {
        console.log("Wishlist ka data", key, ":", value);
      }
      // alert("Abhi remove cart call hua");
      const response = await axios.post(
        baseUrl+"Apicontroller/remove_wishlist_product/",
        removewishlistData
      );
      console.log("Hogaya remove from wishlist", response);
      if (response.data.status === 200) {
        props.changePopupText(
          "Success! product successfully deleted from wishlist."
        );
      }
    } catch {
      console.log("Issue in delete wishlist data API");
    }
  };
  const addToCartWithRemoveWishlist = async(pid, qty, tid) => {
    props.addingToCartFunction(
      pid,
      1,
      tid
    )
    try {
      const data = {
        product_id: pid,
        type_id: tid,
        email_id,
        password,
        token
      };
      const removewishlistData = new FormData();
      removewishlistData.append("product_id", data.product_id);
      removewishlistData.append("type_id", tid);
      removewishlistData.append("email", email_id);
      removewishlistData.append("password", password);
      removewishlistData.append("token_id", token);
      for (let [key, value] of removewishlistData) {
        console.log("Wishlist ka data", key, ":", value);
      }
      // alert("Abhi remove cart call hua");
      const response = await axios.post(
        baseUrl+"Apicontroller/remove_wishlist_product/",
        removewishlistData
      );
      console.log("Hogaya remove from wishlist", response);
      if (response.data.status === 200) {
        // props.changePopupText(
        //   "Success! product successfully deleted from wishlist."
        // );
      }
    } catch {
      console.log("Issue in delete wishlist data API");
    }
    
  }
  if(localStorage.getItem("create_outdoor_email")!=="" && localStorage.getItem("create_outdoor_email")!==null && localStorage.getItem("create_outdoor_email")!==undefined){
  return (
    <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
        <div className="row">
          <div className="col-md-12 text-center heading-div">
            <h1 className="heading">My Wishlist</h1>
          </div>
        </div>

        <div className="row">
          {wishlistDetails ? (
            wishlistDetails.map((elem, i) => {
              console.log(elem);
              return (
                // <>
                //   <ProductCard
                //     product_id={elem.product_id}
                //     productimage={elem.produt_image}
                //     productname={elem.product_name}
                //     mrp={elem.type_mrp}
                //     price={elem.Price}
                //     addingToCartFunction={props.addingToCartFunction}
                //     type_id={elem.type_id}
                //   />
                // </>
                <div className="col-lg-4 col-md-4 col-6 mb-4">
                  <a
                    className="remove_wish"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      removeFromWishlist(elem.product_id, elem.type_id)
                    }
                  >
                    <img src="assets/img/cut.png" />
                  </a>
                  <div className="bor_ra">
                    <Link
                      to={{
                        pathname: `/product-details/${elem.product_url}`,
                        state: elem.product_url,
                      }}
                    >
                      <img
                        src={elem.product_image}
                        className="product-card-img"
                        alt={elem.product_name}
                      />
                    </Link>
                    <p>
                      <small>{elem.product_name.length > 23
              ? elem.product_name.substring(0, 23) + "..."
              : elem.product_name}</small>
                    </p>
                    {/* <div className="price_box_cut">
                      <del>
                        <span>
                          <i className="fa fa-inr"></i> {elem.type_mrp}
                        </span>
                      </del>
                    </div> */}
                    <div className="price_box">
                      <p>
                        {/* Price: */}
                        <i className="fa fa-inr pl-0 "></i> {elem.price}
                      </p>
                    </div>
                    <div>
                      <small>
                        <button
                          className={"cart_btn"}
                          onClick={() =>addToCartWithRemoveWishlist( elem.product_id,
                            1,
                            elem.type_id)
                            
                          }
                        >
                          ADD TO CART{" "}
                          <i className="fa fa-shopping-bag ml-2 shop"></i>
                        </button>
                      </small>
                      {/* <AddToCart className={"cart_btn"} productDetail={props} styles={{}} /> */}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-12 col-md-12 text-center">
              Your wishlist is empty.
            </div>
          )}
        </div>
      </div>
    </section>
	)
} else {
	return(<Redirect to="/register" />)
}
}