import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { baseUrl } from "../baseURL";
import { Redirect } from "react-router";


function loadRazorPay() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const DEV = document.domain === "localhost";

export default function NewPlaceOrderComp(props) {

  // const [waitingScr,setWaitingScr] = useState(true)
  const [cartDetails, setCD] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const history = useHistory()
  localStorage.setItem("props",JSON.stringify(props))

//************************************* */ get cart data api ***********************************

const user_token = localStorage.getItem("user_token");
    const user_email_id = localStorage.getItem("create_outdoor_email");
    const user_password = localStorage.getItem("create_outdoor_password");

    
    useEffect(() => {
   
    const checkout = async () => {
      const calculatedData = JSON.parse(
        localStorage.getItem("create_outdoor_calculations")
      );
      var payment_type = localStorage.getItem("payment_type_input");
      const checkoutData = new FormData();
      checkoutData.append("token_id", localStorage.getItem("user_token"));
      checkoutData.append(
        "email",
        localStorage.getItem("create_outdoor_email")
      );
      checkoutData.append(
        "password",
        localStorage.getItem("create_outdoor_password")
      );
      checkoutData.append("txn_id", calculatedData.txn_id);
      checkoutData.append("payment_type", payment_type);

      try {
        const response = await axios.post(
          // baseUrl + "Apicontroller/checkout",//--razor pay
          baseUrl + "Apicontroller/PhonePeCheckout",//--phonepe
          checkoutData
          );
          // getCartData()
          if (response.data.status === 201) {
            history.push("/fail");
          }
          // setWaitingScr(false)
          // history.push("/placed")
          // props.countCartLengthFunc()
          let token_id = localStorage.getItem("user_token")
        let email = localStorage.getItem("create_outdoor_email")
        let password = localStorage.getItem("create_outdoor_password")
        let coUserData = {
            token_id: token_id,
            email: email,
            password: password,
        }
        // displayRazorpay(response.data.razor_id,response.data,coUserData);
        displayPhonePepay(response.data.data.url)
        localStorage.setItem("phonepe_order",response.data.data.order_id)
        // alert(response.data.data.url)
        
      } catch {
        // alert("inside catch block!");
        console.log("Issue in checkout");
      }
    
    };
    async function displayPhonePepay (url){
      // console.log(url)
      // window.open(url);

      window.location.href =url
    }
    checkout();
    // *********** get cart data function ***********************
    async function getCartData(){
      try {
        // alert("we are inside get carty")
        var getCartData = new FormData();
        getCartData.append("token_id", user_token);
        getCartData.append("email_id", user_email_id);
        getCartData.append("password", user_password);
        // alert("getCartData", getCartData);
        for (var pair of getCartData.entries()) {
          console.log("getCartData is heres", pair[0] + ", " + pair[1]);
        }
        const response = await axios.post(
          baseUrl+"Apicontroller/get_cart_data",
          getCartData
        );
        // alert("aftr getting cart api")
        console.log("Cart Data agaya", response.data);
        console.log("setSubTotal", response.data.subtotal);
        if (response.data.subtotal !== undefined) {
          setSubTotal(response.data.subtotal);
        } else {
          setSubTotal(0);
        }
        setCD(response.data.data);
      } catch {
        console.log("Issue in getting cart data");
      }
    };
    
    //************************************* */ get cart data api ***********************************
    
      async function displayRazorpay (myRazorPay_id,data,coData){
        const res = await loadRazorPay();
        if (!res) {
          alert("falied to load razor Pay SDK");
          return;
        }
    
         
        var options = {
          key: DEV ? "" : "rzp_test_i9kuSqNYCuJXyj", // Enter the Key ID generated from the Dashboard
          amount: parseInt(data.advance_amount)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Create Spaces",
          description: "Order Payment Transaction",
          image: "https://www.createspaces.in/assets/img/logo.png",
        //   order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          order_id: myRazorPay_id, 
          callback_url: "https://www.createspaces.in/",
          handler: function (response){
            
           
            let payData = {...coData, razor_id: response.razorpay_order_id,}
            console.log("payData: ",payData);
            localStorage.setItem("payData",JSON.stringify(payData))
            const payRzrData = new FormData();
    
            // getCartData() // updating cart
            for ( let key in payData ) {
              console.log(`key:${key} and value:${payData}`)
              payRzrData.append(key, payData[key]);
            }
            let sendResponse = async()=>{ 
              try {
                let response = await axios.post(baseUrl + "Apicontroller/check_payment",
                payRzrData)
                if(response.data.status === 201){
                  history.push("/fail");
                }
                localStorage.setItem("checkPaymentRes",JSON.stringify(response))
                props.countCartLengthFunc()
                // setWaitingScr(false)
                history.push("/placed")
                  
                } catch (error) {
                    // alert("hi error in check_payment")
                    history.push("/fail");
                  
                }
                
            }
            sendResponse()
        },
        modal: {
          ondismiss: function(){
              // alert('Checkout form closed');
              localStorage.setItem("history.push",props)
              // history.push("/fail")
              history.push("/fail")
          }
          },
          prefill: {
            name: data.name,
            email: data.email,
            contact: data.contact,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response){
          // alert("this is my custom failure alert")
          history.push("/fail");
          
    });
        rzp1.open();
      };
  }, [props,history,user_email_id, user_password, user_token]);
  if (
    // localStorage.getItem("create_outdoor_email") !== "" &&
    // localStorage.getItem("create_outdoor_email") !== null &&
    // localStorage.getItem("create_outdoor_email") !== undefined
    localStorage.getItem("user_token")
  ) {
    return (

      <>
      
        <div className="container pt-5 mt-4 waitingSrcCont">
        <h1>Sending Your Request....</h1>
        </div>
       
       
        
      </>
    );
  }else if(localStorage.getItem("user_token")){
    return (

      <>
      
        <div className="container pt-5 mt-4 waitingSrcCont">
        <h1>Sending Your Request....</h1>
        </div>
       
       
        
      </>
    );
  } else {
    return <Redirect to="/register" />;
  }
}


// <div className="container pt-5 mt-4">
// <div className="row pt-5 mt-5">
//   <div className="col-md-12">
//     <h2 style={{ textAlign: "center" }}> &#9989;</h2>
//     <h1 style={{ textAlign: "center", color: "green" }}>
//       {" "}
//       Thank you
//     </h1>
//     <p style={{ textAlign: "center", color: "green" }}>
//       {" "}
//       your order has been successfully placed and is on the way
//     </p>
//   </div>
// </div>
// <div className="row">
//   <div style={{ display: "flex" }} className="col-md-12">
//     <div className="col-md-6 text-center" style={{ width: "50%" }}>
//       <Link to="myorders">View Order</Link>
//     </div>

    
//     <div className="col-md-6 text-center" style={{ width: "50%" }}>
//       <Link to="/">Continue Shopping....</Link>
//     </div>
    
//   </div>
// </div>
// </div>