import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { baseUrl } from "../baseURL";

function TestimonialComponent() {
  const [data, setData] = useState([]);
  const getTestimonial = async () => {
    try {
      let response = await axios.get(
        baseUrl+"/Apicontroller/view_testimonials"
      );
      console.log("testimonial data:", response.data);

      setData(response.data.data);
    } catch {
      console.log("Issue in testimonial.");
    }
  };

  useEffect(() => getTestimonial(), []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      partialVisibilityGutter: 0 
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="row">
      <div className="col-md-12 ">
        <Carousel
          partialVisible={false}
          autoPlay={true}
          infinite={true}
          autoPlaySpeed={5000}
          centerMode={false}
          responsive={responsive}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
        >
            {data
              ? data.map((elem) => {
                  return (
                    <div className="pl-5 pr-5 col-md-12 col-12 col-sm-12 text-center">
                    <a href={elem.link}>
                      <img src={elem.image} alt=
                      "testimonial" height="300" style={{width: '13rem'}} />
                      </a>

                      <div className="quoted-text text-right pt-3">
                          <p className="text-left">{elem.description}</p>
                          <span style={{ fontWeight: 'bold'}}>{elem.name}</span>
                      </div>
                    </div>
                  );
                })
              : null}
        </Carousel>
      </div>
    </div>
  );
}

export default TestimonialComponent;