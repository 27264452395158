import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";

export default function Corporate() {
  const [corporateBrochers, setCorporateBrochers] = useState([]);
  const [corporateImage, setCorporateImage] = useState({});
  const [popUpMessage, setPUM] = useState("");
  const submitCorporateDetails = async (e) => {
    e.preventDefault();
    document.getElementById("ssubmit").disabled = true;

    const CorporateData = new FormData(e.target);
    // alert("CorporateData");
    try {
      const response = await axios.post(
        baseUrl+"/Apicontroller/corporate",
        CorporateData
      );
      console.log("Corporate", response);
      if (response.data.status === 201 || response.data.code === 201) {
        document.querySelector("#CorporateError").innerHTML =
          response.data.message;
      } else if (response.data.status === 200 || response.data.code === 200) {
      alert('Success! Request submitted successfully');
      changePopupText("Success! Request submitted successfully");

      document.getElementById("ccorporate").reset();
      document.getElementById("ssubmit").disabled = false;

        document.querySelector("#CorporateError").innerHTML =
          "User successfully Corporate";
      }
    } catch {
      console.log("Issue in Corporate");
    }
  };
  const changePopupText = (text) => {
    setPUM(text);
    setTimeout(() => {
      setPUM("");
    }, 3000);
  };
  useEffect(()=>{
    const fetchCorporateImages = async () => {
      try {
        let response = await axios(
          baseUrl+"Apicontroller/corporate_banner_image"
        );
        console.log("Corporate Images:", response.data.data);
        setCorporateImage(response.data.data);
      } catch {
        console.log("Issue in corporate brochers");
      }
    };
    fetchCorporateImages();
  }, [corporateImage])
  useEffect(() => {
    
    const fetchCorporateData = async () => {
      try {
        const response = await axios(
          baseUrl+"Apicontroller/corporate_brochers"
        );
        console.log("corporate brochers:", response.data.data);
        setCorporateBrochers(response.data.data);
      } catch {
        console.log("Issue in corporate brochers");
      }
    };
    
    fetchCorporateData();
  });
  return (
    <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="heading">Custom Order</h1>
          </div>
        </div>
        <div className="d-flex mobilerv col-md-12 ">
          <div className="col-md-6 mtc " style={{border:"5px solid #0000"}}>
            <form
              id="ccorporate"
              className=""
               method="post"
              onSubmit={(e) => submitCorporateDetails(e)}
            >
              <label for="firstname">
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter First Name"
                id="first_name"
                name="first_name"
                required
              />
              <div className="form-group">
                <label for="lastname">
                  Last Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Name"
                  id="last_name"
                  name="last_name"
                  required
                />
              </div>
              <div className="form-group">
                <label for="business_name">Business/company Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Business Name"
                  id="business_name"
                  name="business_name"
                  required
                />
              </div>
              <div className="form-group">
                <label for="email">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  id="email"
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label for="pwd">
                  Message<span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <input
                  id="ssubmit"
                  type="submit"
                  className="btn"
                  style={{
                    background: "rgb(0, 0, 0)",
                    color: "white",
                  }}
                />
              </div>
            </form>
          </div>
          {corporateImage?(
          <div className="col-md-6 mt-4">
            <div className="row">
              <div className="col-sm-12">
                <div className="">
                  <img
                    src={corporateImage.detail_image1} 
                    alt="corporateImage1"
                  />
                </div>
              </div>
              <div className="col-sm-12 mt-5">
                <div className="">
                  <img
                    src={corporateImage.detail_image2}
                    alt="corporateImage2"
                  />
                </div>
              </div>
            </div>
          </div>):null}
        </div>
        
        <div className="row heading-div">
          <div className="col-md-12 text-center">
            <h1 className="heading">Brochure</h1>
          </div>
        </div>
        <div className="row">
          {corporateBrochers
            ? corporateBrochers.map((item, i) => (
                <>
                  <div className="col-md-3 text-center ">
                    <a href={item.file}>
                      <img src={item.image} alt={item.title} />
                    </a>
                    <h5 className="mt-3">
                      <b>{item.title}</b>
                    </h5>
                  </div>
                </>
              ))
            : null}
        </div>
      </div>
    </section>
  );
}
