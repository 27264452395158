import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import ProductCard from "./ProductCardComponent";
export default function ProductSlider(props) {
  // const [productPrice, setPP] = useState(null);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  // useEffect(()=> {
  //   console.log("Props in product sliders",props)
  //   if(props.products.length>0){
  //   // setPP(props.products[0].product_type[0].Price)  
  // // }
  // }, [props])
  if(props.products.length>0){
    // alert( props.isMostPopular)
    return (
      <>
      {console.log("Props in product sliders",props)}
        {/* <div className="container"> */}
           <div className="row" >
             <div className="col-md-12">
            <Carousel autoPlay={true} infinite={true} autoPlaySpeed={5000} className="mt-1" responsive={responsive} >
              {props
                ? props.products.map((elem, i) => {
                    return (
                      <>
                      <ProductCard
                        key={elem.product_type[0].type_id}
                        product_id={elem.product_id}
                        productimage={elem.productimage}
                        productname={elem.productname}
                        product_url={elem.product_url}
                        mrp={elem.product_type[0].MRP}
                        price={elem.product_type[0].Price}
                        tag={elem.tag}
                        addingToCartFunction={props.addingToCartFunction}
                        loader = {props.loader}
                        type_id={elem.product_type[0].type_id}
                        isSlider="true"
                        isMostPopular={props.isMostPopular?true:false}
                      />
                    </>
                    );
                  })
                : null}
            </Carousel>
            </div>
            </div>
          {/* </div> */}
        {/* // </div> */}
      </>
    );
                } else {
                  return (
                    <>
                    {console.log("Props in product sliders",props)}
                      {/* <div className="container"> */}
                         <div className="row">
                          <Carousel autoPlay={true} autoPlaySpeed={5000} centerMode={false} partialVisbile={false} className="mt-5" responsive={responsive}>
                            {props
                              ? props.products.map((elem, i) => {
                                  return (
                                    <div key={elem.product_type[0].type_id} className="col-md-12 col-12 col-sm-12 ml-4">
                                      <div className="bor_ra">
                                        <Link
                                          to={{
                                            pathname: `/product-details/${elem.product_url}`,
                                            state: elem.product_url
                                          }}
                                        >
                                          <img
                                            style={{ height: "200px" }}
                                            src={elem.productimage}
                                            alt={elem.productname}
                                          />
                                        </Link>
                                        <p>
                                          <small>{elem.productname.length>39?elem.productname.substring(0,39)+"...":elem.productname}</small>
                                        </p>
                                        <div className="price_box_cut">
                                          {/* <!-- <del>
                                            <span>M.R.P: </span>
                                            <span>Rs.40.00</span>
                                          </del> --> */}
                                        </div>
                                        <div className="price_box">
                                          <p>
                                            Price: <i className="fa fa-inr"></i>{" "}
                                            {elem.product_type[0].Price}
                                            {/* {productPrice} */}
                                          </p>
                                        </div>
                                        <div>
                                          <small>
                                            <button
                                              className={"cart_btn"}
                                              onClick={() =>
                                                props.addingToCartFunction(
                                                  elem.product_id,
                                                  1,
                                                  elem.product_type[0].type_id
                                                )
                                              }
                                            >
                                              ADD TO CART <i className="fa fa-shopping-bag ml-2"></i>
                                            </button>
                                          </small>
                                          {/* <AddToCart className={"cart_btn"} productDetail={elem} styles={{}} /> */}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </Carousel>
                          </div>
                        {/* </div> */}
                      {/* // </div> */}
                    </>
                  );            
                }
  // }
}
