import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";
const Blogs = () => {
  const [BlogData, setBD] = useState([]);
  useEffect(() => {
    const fetchBlogsData = async () => {
      try {
        const data = await axios(
          baseUrl + "Apicontroller/get_blogs"
        );
        setBD(Object.values(data.data.data));
        console.log("BlogData", data.data.data, BlogData);
      } catch {
        console.log("Issue in get blog.")
      }
    }
    fetchBlogsData()
  }, []);
  return (
    <section>
      <div className="container-fluid" style={{ marginTop: '8rem' }}>
        <div className="row">
          <div className="col-md-12">
            <h2 style={{ textAlign: 'center' }}>Our Blogs</h2>
            <hr />
          </div>
        </div>
        <div className="row">
          {BlogData.map((elem, i) => {
            console.log(elem);
            return (
              <div className="col-md-4">
                <Link 
                to={{
                  pathname: `/blog-details`,
                  state: elem.id,
                }}
                >
                  <img src={elem.image} alt={elem.name} className="img-fluid" width="100%" />
                  <div className="text-justify mt-4">
                    <p>{elem.article_date}</p>
                    <h4>{elem.heading}</h4>
                    <p style={{ fontSize: '14px' }}>{elem.description}</p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Blogs;
