import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";

export default function CustomOrder() {
  const [customBrochers, setCustomBrochers] = useState([]);
  const [customImages, setCustomImages] = useState()
  const submitCustomOrderDetails = async (e) => {
    e.preventDefault();
    document.getElementById("ssubmit").disabled = true;
    try {
      const CustomOrderData = new FormData(e.target);
      // alert('CustomOrderData');
      const response = await axios.post(
        baseUrl + "Apicontroller/customorder/",
        CustomOrderData
      );
      console.log("CustomOrder", response);
      if (response.data.status === 201 || response.data.code === 201) {
        document.querySelector("#CustomOrderError").innerHTML =
          response.data.message;
      } else if (response.data.status === 200 || response.data.code === 200) {
        document.getElementById("ccustomorder").reset();
        alert('Success! Request submitted successfully');
        document.getElementById("ssubmit").disabled = false;

        document.querySelector("#CustomOrderError").innerHTML =
          "User successfully CustomOrder";

      }
    } catch {
      console.log("Issue in CustomOrder");
    }
  };
  useEffect(() => {
    const fetchCustomImages = async () => {
      try {
        let response = await axios(
          baseUrl + "Apicontroller/custom_banner_image"
        );
        console.log("Custom Images:", response.data.data);
        setCustomImages(response.data.data);
      } catch {
        console.log("Issue in custom brochers");
      }
    };
    const fetchCustomData = async () => {
      try {
        const response = await axios(
          baseUrl + "Apicontroller/custom_brochers"
        );
        console.log("custom brochers:", response);
        setCustomBrochers(response.data.data);
      } catch {
        console.log("Issue in custom images");
      }
    };
    fetchCustomImages();
    fetchCustomData();
  });
  return (
    <>{customImages ? (
      <div>
        <div className="container">
          <div className="row heading-div mt-5 pt-4">
            <div className="col-md-12 text-center">
              <h1 className="heading mt-5">Bulk Orders</h1>
            </div>
          </div>
          <div className="row" style={{alignItems:"center"}}>
            <div className=" col-xs-6 col-md-6  " >
            <img className=" img-fluid  " src="/assets/img/sofa6.jpg" alt="image1"/></div>
            <div className="col-xs-6 col-md-6 p-0  " >
              <div className="col-md-12 ">
                <p className=" mb-0  text-a">
                  We specialise in bulk production, export, and business supply of porcelain, marble, and sintered stone tables with steel and solid wood legs.
                </p>
                <p className=" mb-0  text-a">
                  We have been exporting for 25 years and have all required certificates, along with an experienced in-house quality check team.We also support third-party quality checks.
                  For more details, please contact us by email or WhatsApp.
                </p>
              </div></div>
          </div>



        </div>
        <div className="container mt-5">

          <div className="row" style={{alignItems:"center"}}>
            <div className="col-xs-6 col-md-6  " >
              <div className="col-md-12 p-0 ">
                <p className=" mb-0  text-a">
                  We specialise in bulk production, export, and business supply of marble & sintered stone tables with steel & solid wood legs.We also stock good quantities of dining table & coffee table bases for quick dispatch and can also supply in individual pieces to support buyers.
                </p>
              </div></div>
            <div className=" col-xs-6 col-md-6  " >
              <img className=" img-fluid " src="/assets/img/bed-home.jpg" alt="image1" /></div>
          </div>



        </div>
      </div>
    ) : null}
      <section className="cont_f">
        <div className="container">
          <div className="row heading-div">

          </div>
  
          <div className="row">
            <div className="col-md-12 mt-4 pt-lg-3 pt-md-3 text-center">
              <h1 className="heading">Brochure</h1>
            </div>
          </div>
          <div className="row">
            {customBrochers
              ? customBrochers.map((item, i) => (
                <>
                  <div className="col-md-3 text-center ">
                    <a href={item.file}>
                      <img src={item.image} alt={item.title} />
                    </a>
                    <h5 className="mt-3">
                      <b>{item.title}</b>
                    </h5>
                  </div>
                </>
              ))
              : null}
          </div>
        </div>
      </section>
    </>
  );
}
