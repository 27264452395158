import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import './MultiStep.css';
import './font/Brown-Regular.woff';
// import './js/rotate';
import './js/productDetails';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './Components/MainComponent';
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
Bugsnag.start({
  apiKey: '511951c8dfbb1bdab4e8a91f674bd510',
  plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const ErrorView = () =>
  <div>
    <p>Error ocurred. Technical team have been informed.</p>
  </div>
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
class App extends React.Component {
  render() {
    return (
      <Router>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <Main />
        </ErrorBoundary>
      </Router>
    );
  }
}
export default App;