import React, {useState} from "react";
import axios from "axios";
import { baseUrl } from "../baseURL";
export default function ResetPassword(props) {
    const forget_token = window.location.pathname.substring(
        window.location.pathname.lastIndexOf("/") + 1
      );
    const [responeMessage,setResponseMessage] = useState("Fill your new password")
    const ResetPassword= async (e)=> {
        e.preventDefault();
        const passwordData = new FormData(e.target);
        passwordData.append("token_id", localStorage.getItem("token_id"))
        passwordData.append("forget_token", forget_token)
        try {
            const response = await axios.post(baseUrl+"Apicontroller/forget_password_reset", passwordData);
            console.log("forget password:", response);
            if(response.data.status === 201) {
              setResponseMessage(response.data.message) 
            } else {
              setResponseMessage("Your new password is set")
            }
        } catch {
            console.log("Issue in forget password")
        }
    }
  return (
    <section className="mt-5">
      <div className="row heading-div">
        <div className="col-md-12 text-center">
          <h1 className="heading">Reset password</h1>
        </div>
      </div>
      <div className="row">
          <div className="col-1 col-md-2"></div>
          <div className="col-10 col-md-8">
				<p id="checkoutError" className="alert alert-secondary alert-dismissible fade show mb-4">
              {responeMessage}</p>
              <form className="form-block-holder" onSubmit={(e)=>ResetPassword(e)}>
                <div className="form-group">
                    <label for="reset_password">Password</label>
                    <input type="password" name="reset_password" className="form-control" />
                </div>
                <div className="form-group">
                    <label for="confirm_password">Confirm Password</label>
                    <input type="password" name="confirm_password" className="form-control" />
                </div>
                <div className="form-group">
                    <input type="submit" value="Reset Password" className="btn btn-primary" />
                </div>
              </form>
          </div>
      </div>
    </section>
  );
}
