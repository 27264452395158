import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";
import ProductCard from "./ProductCardComponent";



export default function SeachedProducts(props) {
  const [searchResult, setSR] = useState([]);
  const searchString = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  const searchForProducts = async (e) => {
    // alert(searchText)
    const searchData = new FormData();
    searchData.append("string", searchString);
    try {
      const response = await axios.post(
        baseUrl+"Apicontroller/search_product",
        searchData
        
      );
      // if (e.currentTarget.value.includes(" ")) {
      //   e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
      // }
      console.log("search response in header", response.data.status);
      if (response.data.status === 200) {
        setSR(response.data.data);
       
      }
      console(JSON.stringify(response.data.data));
      
    } catch {
      console.log("Issue in searching");
    }
  };
  useEffect(() => {
    searchForProducts();
  }, [searchString]);
  return (
    
    <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
        <div className="row heading-div">
          <div className="col-md-12 text-center">
          
            <h1 className="heading">{decodeURIComponent(searchString)}</h1>
           
          </div>
        </div>
        <div className="row">
            {
              searchResult
                ? searchResult.map((elem, i) => {
                    return (
                      <>
                      <ProductCard
                        product_id={elem.product_id}
                        productimage={elem.produt_image}
                        productname={elem.product_name}
                        product_url={elem.product_url}
                        tag={elem.tag}
                        mrp={elem.type_mrp}
                        price={elem.Price}
                        addingToCartFunction={props.addingToCartFunction}
                        type_id={elem.type_id}
                        avg={elem.avg}
                        rvCount={elem.rv_count}
                      />
                    </>
                      // <div
                      //   key={elem.product_id}
                      //   className="col-lg-3 col-md-3 col-6 mb-4"
                      // >
                      //   <div className="bor_ra">
                      //     <Link
                      //       to={{
                      //         pathname: `/product-details/${elem.product_id}`,
                      //       }}
                      //     >
                      //       <img
                      //         style={{ height: "200px" }}
                      //         src={elem.produt_image}
                      //         alt=""
                      //       />
                      //     </Link>
                      //     <p>
                      //       <small>{elem.product_name}</small>
                      //     </p>
                      //     <div className="price_box_cut">
                      //      <del>
                      // {/* <span>M.R.P: </span> */}
                      // {/* <span><i className="fa fa-inr"></i>{" "}{elem.Price}</span>
                    // </del> 
                    //     </div>
                    //       <div className="price_box">
                    //         <p>
                    //           Price: <i className="fa fa-inr"></i> {elem.Price}
                    //         </p>
                    //       </div>
                    //       <div> */}
                          //   {/* <button
                          //     className={"cart_btn"}
                          //     onClick={() =>
                          //       props.addingToCartFunction(
                          //         elem.product_id,
                          //         1,
                          //         elem.type_id
                          //       )
                          //     }
                          //   >
                          //     ADD TO CART{" "}
                          //     <i className="fa fa-shopping-bag ml-2"></i>
                          //   </button> */}
                          //   {/* <AddToCart className={"cart_btn"} styles={{}} productDetail={elem} /> */}
                          // {/* </div>
                      //   </div>
                      // </div> */}
                    );
                  })
                : "No result found."
              // (<div style={{position: 'absolute', left: '45%'}}>No search result found.</div>)
            }
        </div>
        { searchResult.length===0?(
         
        <div className="row">
            <div className="col-md-12 col-12 text-center">
              No result found.
            </div>
        </div>):""
        }
      </div>
    </section>
  );
}
