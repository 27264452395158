import React from 'react';
import { Link,useHistory } from "react-router-dom";
// <!-- Event snippet for Purchase conversion page --> 
// import {Helmet} from "react-helmet";
// import ReactGA from 'react-ga';
// ReactGA.initialize("AW-10847643476/OZr9CImlpLQDENTOx7Qo");

function SuccessSrcComp() {
  window.gtag('event', 'conversion', {
    send_to: 'AW-10847643476/OZr9CImlpLQDENTOx7Qo'
  });
  // ReactGA.pageview('conversion');
  return <>
  {/* <Helmet>
  <script> {` gtag('event', 'conversion', {{ 'send_to': 'AW-10847643476/OZr9CImlpLQDENTOx7Qo', 'transaction_id': '' }});  `} </script>
      </Helmet> */}
      
  <div className="container pt-5 mt-4">
 <div className="row pt-5 mt-5">
  <div className="col-md-12">
    <h2 style={{ textAlign: "center" }}> &#9989;</h2>
    <h1 style={{ textAlign: "center", color: "green" }}>
      {" "}
      Thank you
    </h1>
    <p style={{ textAlign: "center", color: "green" }}>
      {" "}
      your order has been successfully placed and is on the way
    </p>
  </div>
 </div>
 <div className="row">
  <div style={{ display: "flex" }} className="col-md-12">
    <div className="col-md-6 text-center" style={{ width: "50%" }}>
      <Link to="myorders">View Order</Link>
   </div>

    
   <div className="col-md-6 text-center" style={{ width: "50%" }}>
      <Link to="/">Continue Shopping....</Link>
    </div>
    
   </div>
</div>
 </div>
  </>;
}

export default SuccessSrcComp;
