import React from 'react'

function Createspace() {
  return (
    <div className="container">
      <div className="row heading-div mt-5 pt-4">
        <div className="col-md-12 text-center">
          <h1 className="heading mt-5">Why Create Space</h1>
        </div>
      </div>
      <div className="about"
        style={{ fontWeight: "20px", color: "black", fontFamily: "'Lato', sans-serif", }}
      >


        {/*   
      <div className="col-md-6  mt-3">
      <p className="mb-1">
      Our Essential Elements-
  </p>
     <li style={{listStyleType:"none",}}> Quality </li>
     <li style={{listStyleType:"none",}}> Affordability</li>
     <li style={{listStyleType:"none",}}>Functionality</li>
     <li style={{listStyleType:"none",}}>Aesthetic appeal</li> 
    </div> */}

        <div className="col-md-12">
          <p className="pt-3 mb-0">
            Our showroom is located at 178 Heera Rd., near DCM. Jaipur, 302021.Please note that the showroom has a limited range of products on display, but it gives you an idea of quality, plus you can physically select marbles, fabrics, etc. for your furniture.</p>
          <p className="pt-3 mb-0">
            We are predominately online-based and offer free delivery <b>throughout India to all major postcodes.</b>
            Our biggest markets are - Delhi NCR, Bangalore, Chennai, Hyderabad, Mumbai, Kochi, Pune & Jaipur.Our factory is on the outskirts of Jaipur, in the RICCO Industrial Area, Bagru.
          </p>

        </div>

        {/* <div className="col-md-10  ">
  <p className="pt-3 mb-0">
  Materials & Care-
  </p>
  
  Metal Care - Just use a soft cloth to wipe dust off. Use a liquid
  cleaner to remove any imprints. Products sold for outdoor use have
  special coating that can be used outdoors.
<p>
  Fabric - All the fabric is tested and has Martindale above 35000 rubs.
It can be cleaned by soap water and clean cloth.
</p>
<p>
Foam - We use high density foam that ensures good back support ensuring
a healthy lifestyle.
</p>
<p>
Wood - All the wood used is chemically treated to remove any bacteria.
Any wood except Teak Wood should be used indoors only.

</p>
<p>

Variation- Slight variation can always occur from the photos due to
lighting conditions, batch difference etc.
</p>
  
  </div> */}

      </div>
    </div>

  )
}

export default Createspace


