import axios from "axios"
import React, { useEffect, useState, useRef } from "react";
import { baseUrl } from "../baseURL";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { Style } from "@material-ui/icons";
import Pagination from "react-js-pagination";
export default function AllReviews(props) {
  const [ReviewsList, setReviewsList] = useState([]);
  const [items, setitems] = useState([]);
  const [sort, setsort] = useState('none');
  useEffect(() => {
    fetchReviews('desc');
  }, []);
  const fetchReviews = async (sort = "none") => {
    try {
      let response = await axios(
        baseUrl + "Apicontroller/get_all_reviews/?sort=" + sort
      );
      console.log("ReviewsList:", response);
      setReviewsList(response.data);
      setitems(response.data.reviews)
      setsort(sort)
    } catch {
      console.log("Issue in ReviewsList");
    }
  };
  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((elem) => (
            <>
              <div className="reviews">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span className="inti">{elem.intials}
                      {elem.verify == 1 ?
                        <span className="verify_buy">
                          <i className="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                        </span>
                        : null}
                    </span>
                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontWeight: 800, fontSize: '20px', marginLeft: '10px', color: '#29275b' }}>{elem.name}</span>
                      {elem.verify == 1 ?
                        <span className="veri_tag">Verified Buyer</span>
                        : null}
                    </p>
                  </div>
                  <div>
                    <span style={{ color: '#637381' }}>{elem.rv_date}</span>
                  </div>
                </div>
                <div className="mt-0">
                  <Ratings rate={elem.rating} />
                  {/* <ReactStars
                      value={parseInt(elem.rating)}
                      edit={false}
                      size={24}
                      activeColor="#ffd700"
                    /> */}
                </div>
                <p className="mt-0 mb-0" style={{ fontWeight: 400, fontSize: '17px', color: '#637381', }}>{elem.description}</p>
                <br></br>
                <span className="mt-0 mb-0" style={{ fontWeight: 600, fontSize: '17px', color: '#637381', cursor: 'pointer' }}>
                  <Link
                    to={{
                      pathname: `/product-details/${elem.product_url}`,
                      state: elem.product_url,
                    }}
                  > {elem.product_name}
                  </Link>
                </span>
              </div>
              <hr></hr>
            </>
          ))}
      </>
    );
  }
  function Ratings({ rate }) {
    // alert(rate)
    var indents = [];
    for (let i = 1; i <= rate; i++) {
      indents.push(<i className="fa fa-star"></i>);
    }
    if ((rate % 1) != 0) {
      indents.push(<i className="fa fa-star-half-o mt-1" style={{color:'#ffd006'}}></i>);
    }
    return indents;
  }
  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [Active, setActive] = useState(1);
    const [itemOffset, setItemOffset] = useState(0);
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);
    // Invoke when user click to request another page.
    const handlePageClick = (page) => {
      const newOffset = ((page - 1) * itemsPerPage) % items.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
      setActive(page);
    };
    return (
      <>
        <Items currentItems={currentItems} />
        <div className="pagination d-flex justify-content-center">
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={Active}
            itemsCountPerPage={10}
            totalItemsCount={ReviewsList.rv_count}
            pageRangeDisplayed={5}
            onChange={page => handlePageClick(page)}
          />
        </div>
      </>
    );
  }
  return (
    <div className="container mt-5" Style={{ textAlign: 'center', }}>
      <div className="row">
        <div className=" col-xs-12 col-md-12 mt-5"></div>
        <h1>
          Reviews
        </h1>
        <p>We have delivered furniture to over 1500 happy customers across India and have over 300+ 5 star reviews from happy customers.</p>
        <br>
        </br>
        <div>
          <div className="dropdown show">
            <span
              className="btn dropdown-toggle"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ color: 'white', background: 'rgb(0, 0, 0)' }}
            >
              Sort by <b>Date</b>
            </span>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <span style={{ cursor: 'pointer' }} onClick={() => { fetchReviews() }}
                className={`dropdown-item ${sort == 'none' ? "active" : ""}`}>None</span>
              <span style={{ cursor: 'pointer' }} onClick={() => { fetchReviews('asc') }} className={`dropdown-item ${sort == 'asc' ? "active" : ""}`}>Ascending</span>
              <span style={{ cursor: 'pointer' }} onClick={() => { fetchReviews('desc') }} className={`dropdown-item ${sort == 'desc' ? "active" : ""}`}>Descending</span>
            </div>
          </div>
          <br></br>
          <br></br>
          <hr></hr>
          <div className=" mt-5" >
            <h5 className=" line" style={{ color: 'black', }}><b>Product Reviews</b></h5>
            <div className="d-flex mt-2 px-4" >
              <span className="rate" style={{ fontWeight: 600, fontSize: '45px', marginRight: '5px', color: '#637381', }}>{Math.round(ReviewsList.avg * 10) / 10}</span>
              <div className="d-flex align-items-center ml-3">
                <Ratings rate={ReviewsList.avg} />
                <span className="ml-2 mt-2">{ReviewsList.rv_count} Reviews </span>
              </div>
            </div>
            <div className="" style={{ background: '#ffffff' }}>
              <PaginatedItems itemsPerPage={10} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
