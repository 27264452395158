import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { baseUrl } from "../baseURL";
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
export default function MyOrders(props) {
  const [orderList, setOrderList] = useState([]);
  const [temp_order_id, settemp_order_id]=useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal=(order_id)=> {
    setIsModalOpen(true);
    settemp_order_id(order_id)
  }
  useEffect(() => {
    const fetchOrders = async () => {
      const orderData = new FormData();
      orderData.append("token_id", localStorage.getItem("user_token"));
      orderData.append(
        "email_id",
        localStorage.getItem("create_outdoor_email")
      );
      orderData.append(
        "password",
        localStorage.getItem("create_outdoor_password")
      );
      try {
        const response = await axios.post(
          baseUrl + "Apicontroller/view_order/",
          orderData
        );
        console.log("view order", response);
  
        if (response.data.status === 200) {
          setOrderList(response.data.data);
        }
      } catch {
        console.log("Issue in view order");
      }
    };
    fetchOrders();
  }, []);
  const cancleOrder = async (id) => {
    const cancleOrderData = new FormData();
    cancleOrderData.append("token_id", localStorage.getItem("user_token"));
    cancleOrderData.append(
      "email",
      localStorage.getItem("create_outdoor_email")
    );
    cancleOrderData.append(
      "password",
      localStorage.getItem("create_outdoor_password")
    );
    cancleOrderData.append("order_id", id);
    for (let pair of cancleOrderData) {
      console.log("cancle order:", pair[0], pair[1]);
    }
    
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/cancel_order/",
        cancleOrderData
      );
      console.log("cancle order", response);
      if(response.data.status===200){
        props.changePopupText("Order Successfully canceled.")
      } else {
        props.changePopupText(response.data.message)
      }
    } catch {
      console.log("Issue in cancel order");
    }
  };
  if (
    localStorage.getItem("create_outdoor_email") !== "" ||
    localStorage.getItem("create_outdoor_name") !== "" ||
    localStorage.getItem("create_outdoor_password") !== ""
  ) {
    return (
      <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
        <div className="row">
        <Modal isOpen={isModalOpen} backdrop={true} style={{top:"40%"}}>
            <ModalHeader>
                Alert!
            </ModalHeader>
            <ModalBody>
                <p>Are you sure you want to cancel this order?</p>
            </ModalBody>
            <ModalFooter>
                <button className="btn" style={{background: 'rgb(0, 0, 0)', color: 'white'}} onClick={()=> cancleOrder(temp_order_id)}>Yes</button>
                <button className="btn" style={{background: 'rgb(0, 0, 0)', color: 'white'}} onClick={()=> setIsModalOpen(false)}>No</button>
            </ModalFooter>
        </Modal>
        </div>
          <div className="row">
            <div className="col-md-12 text-center heading-div">
              <h1 className="heading">My Orders</h1>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-12">  
              <p>
                Your selection <b>( 1 item )</b>
              </p>
            </div> */}
            <div className="col-md-12">
              <table className="cart_table">
                {
                  orderList ? (
                    orderList.map((elem, i) => {
                      // console.log("orderList, elem", orderList, elem);
                      return (
                        <>
                          <tr className="mb-4 mb-lg-0">
                            <th className="p-2">
                              <span>Order#{elem.order_id}</span>
                            </th>
                            <th className="p-2">
                              <span
                                className={
                                  "badge " + elem.order_status === "Placed"
                                    ? "badge-warning"
                                    : elem.order_status === "Canceled"
                                    ? "badge-danger"
                                    : elem.order_status === "Dispatched"
                                    ? "badge-success"
                                    : elem.order_status === "Delivered"
                                    ? "badge-secondary"
                                    : "badge-primary"
                                }
                                style={{ color: "white" }}
                              >
                                {elem.order_status}
                              </span>
                            </th>
                            <th className="p-2">{elem.order_date}</th>
                          </tr>
                          <tr className="mb-4 mb-lg-0">
                            {/* <td>final_amount: null</td> */}
                            <td>Payment Type: {elem.payment_type}</td>
                            <td>
                              Promocode discount: <i className="fa fa-inr"></i>{" "}
                              {elem.discount !== null ? elem.discount : 0}
                            </td>
                            <td>
                              Delivery Charge: <i className="fa fa-inr"></i>{" "}
                              {elem.delivery_charge  ? elem.delivery_charge
                                : 0}
                            </td>
                            <td>
                              Total Amount: <i className="fa fa-inr"></i>{" "}
                              {elem.final_amount}
                            </td>
                            <td></td>
                            <td>
                              <div className="d-flex flex-column text-center">
                                {/* <div className="m-1 text-left">
                                  {elem.cancel_status === 1 ? (
                                    <button
                                      onClick={() => openModal(elem.order_id)}
                                      className="btn"
                                      style={{
                                        background: "rgb(86, 86, 86)",
                                        color: "white",
                                      }}
                                    >
                                      <i className="fa fa-times-circle"></i>{" "}
                                      Cancel Order
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div> */}
                                <div className="m-1 text-left">
                                  <Link
                                    to={{
                                      pathname: `/order-details/${elem.order_id}`,
                                    }}

                                  >
                                    <button
                                      className="btn"
                                      style={{
                                        background: "rgb(0, 0, 0)",
                                        color: "white",
                                      }}
                                    >
                                      View Order
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td>No order placed</td>
                    </tr>
                  )
                  // (<div style={{position: 'absolute',left: '45%'}}>No order placed.</div>)
                }
              </table>
              {orderList.length === 0 ? (
                <div className="text-center">No order placed.</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <Redirect to="/" />;
  }
}
