import axios from "axios";
import React, {useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CartProducts from "./CartProducts";





import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { baseUrl } from "../baseURL";


export function Cart(props) {



  const token = props.token === "" ? "" : props.token;
  const email_id = props.email === "" ? "" : props.email;
  const password = props.password === "" ? "" : props.password;
  const [cartDetails, setCD] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  var [cartLength, setCL] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [temp_product_id, settemp_product_id] = useState("")
  const [temp_type_id, settemp_type_id] = useState("")
  const [temp_sample, settemp_sample] = useState("")

  // Get Cart Data function
  const linkTo = localStorage.getItem("create_outdoor_email")
  ? `/checkout`
  : `/register`; 
  const getCartData = async () => {
    try {
      var getCartData = new FormData();
      getCartData.append("token_id", token);
      getCartData.append("email_id", email_id);
      getCartData.append("password", password);
      // alert("getCartData", getCartData);
      for (var pair of getCartData.entries()) {
        console.log("getCartData is heres", pair[0] + ", " + pair[1]);
      }
      const response = await axios.post(
        baseUrl + "Apicontroller/get_cart_data",
        getCartData
      );
      console.log("Cart Data agaya", response.data);
      console.log("setSubTotal", response.data.subtotal);
      if (response.data.subtotal !== undefined) {
        setSubTotal(response.data.subtotal);
      } else {
        setSubTotal(0);
      }
      setCD(response.data.data);
    } catch {
      console.log("Issue in getting cart data");
    }
  };
  useEffect(() => {
    async function gatCartLength() {
      try {
        const data = await props.countCartLengthFunction();
        console.log("Length", data);
        if (data !== undefined) {
          setCL(data);
        }
      } catch {
        console.log("Issue in getting cart length.");
      }
    }
    gatCartLength();
    getCartData();
  }, [subTotal, email_id, password, token]);
  // open modal
  const openModal = (product_id, type_id,sample) => {
    setIsModalOpen(true);
    settemp_product_id(product_id)
    settemp_type_id(type_id)
    settemp_sample(sample)
  }
  // delete product in cart
  const removeFromCart = async (id, type_id,sample) => {
    console.log("Remove from cart");
    try {
      const data = {
        product_id: id,
        type_id,
        sample,
        email_id,
        password,
        token,
      };
      const removecartData = new FormData();
      removecartData.append("product_id", data.product_id);
      removecartData.append("type_id", type_id);
      removecartData.append("sample", sample);
      removecartData.append("email_id", email_id);
      removecartData.append("password", password);
      removecartData.append("token_id", token);
      for (let [key, value] of removecartData) {
        console.log("Cart ka data", key, ":", value);
      }
      // alert("Abhi remove cart call hua");
      const response = await axios.post(
        baseUrl + "Apicontroller/delete_cart_product/",
        removecartData
      );
      console.log("Hogaya remove", response.data.status);
      if (response.data.status === 200) {
        // alert(response.data.message);
        props.changePopupText("Success! product successfully deleted.");
        let responseOfCount = props.countCartLengthFunction();
        console.log("responseOfCount in delete", responseOfCount);
      } else {
        props.changePopupText(response.data.message);
      }
      setIsModalOpen(true);
    } catch {
      console.log("Issue in delete API");
    }
  };

  // update product in cart
  const updateCart = async (id, qty, type_id,sample) => {
    if (qty === 0) {
      props.changePopupText("Quantity must be greater than 0.");
      return;
    }
    // alert("Abhi hoga update")
    const data = {
      product_id: id,
      type_id,
      sample,
      email_id,
      password,
      token,
      quantity: qty,
    };
    const updateCartData = new FormData();
    updateCartData.append("product_id", data.product_id);
    updateCartData.append("type_id", type_id);
    updateCartData.append("sample", sample);
    updateCartData.append("email_id", email_id);
    updateCartData.append("password", password);
    updateCartData.append("token_id", token);
    updateCartData.append("quantity", qty);
    for (let [key, value] of updateCartData) {
      console.log("Update Cart ka data", key, ":", value);
    }
    try {
      // alert("Abhi remove cart call hua");
      const response = await axios.post(
        baseUrl + "Apicontroller/update_cart_product/",
        updateCartData
      );
      console.log("Update response", response);

      // console.log("Hogaya update", response);
      if (response.data.status === 200) {
        // alert(response.data.message);
        props.changePopupText("Success! cart successfully updated.");
        getCartData();
      } else {
        props.changePopupText(response.data.message);
      }
    } catch {
      console.log("Issue in update cart product");
    }
  };
  return (
    <section
      className="filter filtersection pt-lg-3 mt-md-0 mt-sm-0 pt-sm-0 accordion"
      id="accordionExample"
    >
      <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
        <div className="row">
          <Modal isOpen={isModalOpen} backdrop={true} style={{ top: "40%" }}>
            <ModalHeader>
              Alert!
            </ModalHeader>
            <ModalBody>
              <p>Are you sure you want to delete product?</p>
            </ModalBody>
            <ModalFooter>
              <button className="btn" style={{ background: 'rgb(0, 0, 0)', color: 'white' }} onClick={() => removeFromCart(temp_product_id, temp_type_id,temp_sample)}>Yes</button>
              <button className="btn" style={{ background: 'rgb(0, 0, 0)', color: 'white' }} onClick={() => setIsModalOpen(false)}>No</button>
            </ModalFooter>
          </Modal>
        </div>
        <div className="row heading-div">
          <div className="col-md-12 d-flex">
            <div className="text-center fs-2 col-md-4">
              <span>Cart</span>{" "}
              <i
                style={{ color: "rgb(0, 0, 0)" }}
                className="fa fa-check-circle"
                aria-hidden="true"
              ></i>
            </div>
            <div className="text-center fs-2 col-md-4">
              <span>Address</span>{" "}
              <i
                style={{ color: "rgb(0, 0, 0)" }}
                className="fa fa-circle"
                aria-hidden="true"
              ></i>
            </div>
            <div className="text-center fs-2 col-md-4">
              <span>Checkout</span>{" "}
              <i
                style={{ color: "rgb(0, 0, 0)" }}
                className="fa fa-circle"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>
              Your selection <b>( {cartLength} item )</b>
            </p>
          </div>
          <div className="col-md-9">
            <table className="cart_table">
              <tbody style={{ textAlign: "center" }}>
                <tr
                  className="mb-4 mb-lg-0 not_show"
                  style={{ background: "#edeee6" }}
                >
                  <th className="pt-2 pb-2"></th>
                  <th className="pt-2 pb-2"></th>
                  <th className="pt-2 pb-2">PRODUCT</th>
                  <th className="pt-2 pb-2">PRICE</th>
                  <th className="pt-2 pb-2">QUANTITY</th>
                  <th className="pt-2 pb-2"></th>
                  <th className="pt-2 pb-2">TOTAL PRICE</th>
                </tr>{" "}
                {cartDetails ? (
                  cartDetails.map((elem, i) => {
                    return (
                      <CartProducts
                        cartProductData={elem}
                        updateCart={updateCart}
                        openModal={openModal}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{ position: "absolute", left: "45%" }}
                    className="emptyCart"
                  >
                    Your cart is empty.
                  </div>
                )}
              </tbody>
            </table>
          </div>
          {cartLength !== 0 ? (
            <div className="col-md-3 mt-5 mt-lg-0">
              <table className="cart_table side_cart">
                <tbody>
                  <tr style={{ background: "#edeee6" }}>
                    <th className="pt-2 pb-2 pl-2 pr-2">
                      Order Summary <span>({cartLength} item)</span>
                    </th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>Subtotal</td>
                    <td>
                      <span><i className="fa fa-inr totl"></i> </span>
                      {subTotal}
                    </td>
                  </tr>

                  <tr></tr>
                  <tr>
                    <td className="bor_ra p-0" colspan="2">
                      {/* <a href="checkout.html"> */}
                      <Link
                        to={{
                          pathname: linkTo,
                          state: { token: token, email: email_id, password },
                        }}
                      >
                        <button className="cart_btn h-auto mob_btn">
                          <i className="fa fa-lock mr-3" aria-hidden="true"></i>
                          PROCEED TO CHECKOUT
                        </button>

                      </Link>

                      {/* </a> */}

                    </td>
                  </tr>
                </tbody>
              </table>
            
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
}
