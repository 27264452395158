import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { Link } from "react-router-dom";
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component";
import PlaceholderImage from "../Img/placeholder.webp";
import { baseUrl } from "../baseURL";
import MaximizeIcon from "@material-ui/icons/Maximize";
function Slider() {
  const [data, setData] = useState([]);
  const getslider = async () => {
    try {
      let response = await axios.get(baseUrl + "Apicontroller/get_slider");
      console.log("slider data:", response.data);
      if (response.data.status === 200) {
        setData(response.data.data);
      }
    } catch {
      console.log("Issue in getslider.");
    }
  };
  useEffect(() => getslider(), []);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li
        className={(active ? "active" : "inactive")}
        onClick={() => onClick()}
        style={{ fontSize: '2rem' }}
      >
        <MaximizeIcon style={{ fontSize: '2.5rem', color: 'white', opacity: active ? "1" : ".5" }} />
      </li>
    );
  };
  const arrowStyle = {
    background: "transparent",
    border: 0,
    color: "#fff",
    fontSize: "2.5rem",
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
    top: '45%'
  };
  const CustomLeft = ({ onClick }) => (
    <button className="position-absolute" onClick={onClick} style={arrowStyle}>
      <i className="fa fa-angle-left"></i>
    </button>
  );
  const CustomRight = ({ onClick }) => (
    <button className="position-absolute" onClick={onClick} style={{ ...arrowStyle, right: '0' }}>
      <i className="fa fa-angle-right"></i>
    </button>
  );
  return (
    <section
      className="filter pt-lg-2  mt-md-4 mt-0 mt-sm-3 pt-sm-0 accordion sld" style={{ paddingTop: '4rem!important' }}
    >
      <div className="w-100">
        <div id="main-slide" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <Carousel
              autoPlay={true}
              interval={6000}
              responsive={responsive}
              additionalTransfrom={0}
              arrows
              // autoPlaySpeed={3000}
              centerMode={false}
              className=""
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              showDots
              sliderClass=""
              slidesToSlide={1}
              swipeable
              customDot={<CustomDot />}
              customRightArrow={<CustomRight />}
              customLeftArrow={<CustomLeft />}
            >
              {data
                ? data.map((elem, i) => {
                  const imageUrl = window.innerWidth >= 650 ? elem.image : elem.image2;
                  return (
                    <div className="col-md-12 col-12 col-sm-12 p-0 m-0" key={i}>
                      {/* <link rel="preload" fetchpriority="high" as="image" href={imageUrl} className="slider_image"  style={{marginLeft: '-1.2rem'}} ></link> */}
                      <a href={elem.link}>
                      
                        <img  fetchpriority="high" className="slider_image" alt="slider" src={imageUrl} style={{marginLeft: '-1.2rem'}} 
                         srcSet={`${imageUrl}?q=70 1200w, 
                         ${imageUrl}?w=200&q=50 200w, 
                         ${imageUrl}?w=400&q=50 400w, 
                         ${imageUrl}?w=800&q=50 800w, 
                         ${imageUrl}?w=1024&q=70 1024w`}
                        />
                      </a>
                    </div>
                  );
                })
                : null}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
export default React.memo(Slider);
