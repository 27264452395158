import React from 'react'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router';
export default function Fail() {
  if(localStorage.getItem("create_outdoor_email")!=="" && localStorage.getItem("create_outdoor_email")!==null && localStorage.getItem("create_outdoor_email")!==undefined){
    return (
        <>
      {/* <br /> */}
      <div className="container pt-5 mt-5">
            <div className="row pt-5 mt-5">
          <div className="col-md-12">
            <h2 style={{ textAlign: "center" }}> &#x274C;</h2>
            <h1 style={{ textAlign: "center", color: "red" }}> Sorry</h1>
            <p style={{ textAlign: "center", color: "red" }}>
              {" "}
              Order is failed to be placed, try again with correct transaction details
            </p>
          </div>
        </div>
        <div className="row">
          <div style={{ display: "flex" }} className="col-md-12">
            <div className="col-md-6 text-center" style={{ width: "50%" }}>
              <Link to="myorders">View Order</Link>
            </div>

            {/* <a href="index.html"> */}
            <div className="col-md-6 text-center" style={{ width: "50%" }}>
              <Link to="/">Continue Shopping....</Link>
            </div>
            {/* </a> */}
          </div>
        </div>
      </div>
    </>
    );
  } else {
    return <Redirect to="/register" />;
  }
  }
  