import axios from "axios";
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../Img/placeholder.webp";
const Gallary = () => {
  const [gallaryData, setGD] = useState([]);
  useEffect(() => {
    const fetchGallaryData = async () => {
      try {
        const data = await axios(
          baseUrl + "Apicontroller/get_gallery"
        );
        setGD(Object.values(data.data.data));
        console.log("gallaryData", data.data.data, gallaryData);
      } catch {
        console.log("Issue in get gallary.")
      }
    }
    fetchGallaryData()
  }, []);
  return (
    // cat_gory
    <div className="row mt-4 p-2">
      {gallaryData.map((elem, i) => {
        console.log(elem);
        const imageUrl = window.innerWidth >= 650 ? elem.image : elem.image2;
        return (
          <div className="col col-lg-4 col-sm-12 col-12 p-2 text-center" key={i} style={{ marginBottom: '2rem' }}>
            <a href={elem.link} target="_blank" rel="noreferrer">
              <div>
              <LazyLoadImage src={imageUrl}
                placeholderSrc={PlaceholderImage}
                alt={'GAllery'+i}
              />
                {/* <img src={imageUrl} alt={elem.name} className="img-fluid" /> */}
                {/* <h4>{elem.name}</h4> */}
              </div>
              <h2 className="text-center p-0 gallarytext" style={{ fontSize: '1.2rem', textTransform: 'capitalize', marginTop: '-2.5rem', zIndex: 50, color: 'white' }}>{elem.name}</h2>
            </a>
          </div>
        );
      })}
    </div>
  );
};
export default React.memo(Gallary);
