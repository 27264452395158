import React, { useEffect } from "react";
import { Redirect } from "react-router";

export default function Profile(props) {
  useEffect(()=> {
    const email= localStorage.getItem("create_outdoor_email")
    const name= localStorage.getItem("create_outdoor_name")
    if(name==="" && email==="") {
      props.history.push("/register") 
    }
  }, [localStorage.getItem("create_outdoor_email"), localStorage.getItem("create_outdoor_name")])
  if(localStorage.getItem("create_outdoor_email")!=="" && localStorage.getItem("create_outdoor_email")!==null && localStorage.getItem("create_outdoor_email")!==undefined){
  return (
    <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
      <div className="row heading-div">
        <div className="col-md-12 text-center">
          <h1 className="heading">My Account</h1>
        </div>
      </div>
      <div className="main-body">
        {/* {/ <!-- Breadcrumb --> /} */}
        {/* <nav aria-label="breadcrumb" className="main-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Home</a></li>
              <li className="breadcrumb-item"><a href="javascript:void(0)">User</a></li>
              <li className="breadcrumb-item active" aria-current="page">User Profile</li>
            </ol>
          </nav> */}
        {/* {/ <!-- /Breadcrumb --> /} */}

        <div className="row gutters-sm">
          <div className="col-md-8">
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4" style={{width: '30%'}}>
                    <h6 className="mb-0" style={{color: 'black'}}>Full Name :-</h6>
                  </div>
                  <div className="col-sm-8 text-secondary" style={{width: '60%'}}>
                  {localStorage.getItem("create_outdoor_name")}
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-4" style={{width: '30%'}}>
                    <h6 className="mb-0" style={{color: 'black'}}>Email :-</h6>
                  </div>
                  <div className="col-sm-8 text-secondary" style={{width: '60%'}}>
                    {localStorage.getItem("create_outdoor_email")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
  } else {
    return <Redirect to="/" />
  }
}
