import axios from "axios";
import React, { useEffect, useState,Suspense  } from "react";
import { Link } from "react-router-dom";
import Slider from "./SliderComponent";
import Chair from "./ChairComponent";
import TestimonialComponent from "./TestimonialComponent";
import { baseUrl } from "../baseURL";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../Img/placeholder.webp";
const Gallary = React.lazy(() => import("./GallaryComponent"));
const ProductSlider = React.lazy(() => import("./ProductSliderComponent"));
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: [],
      mostpopularproducts: [],
      customBrochers: [],
      corporateBrochers: [],
      chairText: []
    };
  }
  componentDidMount() {
    const fetchSaleData = async () => {
      try {
        let response = await axios(
          baseUrl + "Apicontroller/get_sale"
        );
        console.log("sale data", response.data.data);
        this.setState({ sale: response.data.data });
      } catch {
        console.log("Issue in fetching sale data");
      }
    };
    const fetchMostPopularProductsData = async () => {
      try {
        let response = await axios(
          baseUrl + "Apicontroller/most_popular_products"
        );
        console.log("most popular products data", response.data.data);
        this.setState({ mostpopularproducts: response.data.data });
      } catch {
        console.log("Issue in fetching most popular products data");
      }
    };
    // const fetchCustomBrochers = async () => {
    //   try {
    //     let response = await axios(
    //       baseUrl + "Apicontroller/custom_banner_image"
    //     );
    //     console.log("customBrochers:", response.data.data);
    //     this.setState({ customBrochers: response.data.data });
    //   } catch {
    //     console.log("Issue in custom brochers");
    //   }
    // };
    // const fetchCorporateBrochers = async () => {
    //   try {
    //     let response = await axios(
    //       baseUrl + "Apicontroller/corporate_banner_image"
    //     );
    //     console.log("corporate brochers:", response);
    //     this.setState({ corporateBrochers: response.data.data });
    //   } catch {
    //     console.log("Issue in corporate brochers");
    //   }
    // };
    // fetchCorporateBrochers();
    // fetchCustomBrochers();
    fetchSaleData();
    fetchMostPopularProductsData();
  }
  render() {
    const paymentURl = window.innerWidth >= 650 ? "/payment_info.webp" : "/payment_info_mob.webp";
    return (
      <>
        <Slider />
        {/* <section className="mt-5">
          <div className="container">
            <div className="row heading-div">
              <div className="col-md-12 text-center">
                <h1 className="heading">Export / Corporate Order</h1>
              </div>
            </div>
            <div className="row">
              {this.state.customBrochers
                ? this.state.customBrochers.map((item, i) => (
                    <>
              <div className="col-md-6 text-center mb-3">
                <Link to="/corporate-order">
                  <img src={this.state.customBrochers.home_image} alt="" /></Link>
                <h5 className="mt-3">
                          <b>{this.state.customBrochers.name}</b>
                        </h5>
              </div>
              </>
                  ))
                : null}
              {this.state.corporateBrochers
                ? this.state.corporateBrochers.map((item, i) => (
                    <>
              <div className="col-md-6 text-center">
                <Link to="/export">
                  <img src={this.state.corporateBrochers.home_image} alt="" /></Link>
                <h5 className="mt-3">
                          <b>{this.state.corporateBrochers.name}</b>
                        </h5>
              </div>
              </>
                  ))
                : null}
            </div>
            <Stock />
          </div>
        </section> */}
        {/* <!-- top slider end -->
        <Stock />
<!-- categories start --> */}
        {/* <div className="cards-list">
          <div className="cards 2">
            <div style={{ textAlign: "center", marginTop: '3rem' }} >
              <span style={{ backgroundColor: 'rgb(241 122 74)', padding: '20px', borderRadius: '50%' }}><i className="fa fa-thumbs-up" style={{ color: 'white', fontSize: '27px' }} aria-hidden="true"></i></span>
            </div>
            <div className="mt-3" style={{ textAlign: "justify", padding: '10px' }}>
              <h5 style={{color: "black", textAlign: "center" }}><b>Quality Guaranteed</b></h5>
              <p> We use better raw materials & more comfortable larger seat sizes so you can have the peace of mind while ordering.
              </p>
            </div>
          </div>
          <div className="cards 2">
            <div style={{ textAlign: "center", marginTop: '3rem' }} >
              <span style={{ backgroundColor: 'rgb(241 122 74)', padding: '20px', borderRadius: '50%' }}><i className="fa fa-truck" style={{ color: 'white', fontSize: '27px' }} aria-hidden="true"></i></span>
            </div>
            <div className="mt-3" style={{ textAlign: "justify", padding: '10px' }}>
              <h5 style={{ color: "black", textAlign: "center" }}><b>Nationwide Delivery</b></h5>
              <p> Delivering throughout India (with 5 stars showing rating on google)
              </p>
            </div>
          </div>
          <div className="cards 2">
            <div style={{ textAlign: "center", marginTop: '3rem' }} >
              <span style={{ backgroundColor: 'rgb(241 122 74)', padding: '20px', borderRadius: '50%' }}><i className="fa fa-wrench" style={{ color: 'white', fontSize: '27px' }} aria-hidden="true"></i></span>
            </div>
            <div className="mt-3" style={{ textAlign: "justify", padding: '10px' }}>
              <h5 style={{ color: "black", textAlign: "center" }}><b>Customization</b></h5>
              <p> Fabric colour can always be changed as per choice. Dimensions can be modified to suit your needs.
              </p>
            </div>
          </div>
          <div className="cards 2">
            <div style={{ textAlign: "center", marginTop: '3rem' }} >
              <span style={{ backgroundColor: 'rgb(241 122 74)', padding: '20px', borderRadius: '50%' }}><i className="fa fa-check-circle" style={{ color: 'white', fontSize: '27px' }} aria-hidden="true"></i></span>
            </div>
            <div className="mt-3" style={{ textAlign: "justify", padding: '10px' }}>
              <h5 style={{ color: "black", textAlign: "center" }}><b>Top options</b></h5>
              <p> Scratch resistant imported vitrified tile (on top- recommended), Indian Marble included in price. Italian marble and onyx available for additional cost</p>
            </div>
          </div>
        </div> */}
        <section>
          <div className="container gallarySection mw-100">
            <div className="row heading-div">
              <div className="col-md-12 text-center">
                <h1 className="heading">
                  {/* OUR RANGE OF GARDEN, OUTDOOR & PATIO FURNITURE */}
                </h1>
              </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
            <Gallary />
            </Suspense>
            {/* <br /> */}
            {/* <Gallary /> */}
          </div>
        </section>
        {/* <section>
          <div className="container-fluid mt-3">
            <img src={paymentURl} alt="icons" className="img-fluid" />
          </div>
        </section> */}
        {/* <!-- categories end -->
<!-- our products start --> */}
        <section className="mt-5 pt-5">
          {/*<div className="container">*/}
          <div className="row heading-div ml-4">
            <div className="col-12 text-center mb-3 ">
              <h1 className="heading">Popular Products</h1>
              {/* <p>The furniture our customers love:</p> */}
            </div>
            {this.state.mostpopularproducts ? (
               <Suspense fallback={<div>Loading...</div>}>
            <ProductSlider
              products={this.state.mostpopularproducts}
              addingToCartFunction={this.props.addingToCartFunction}
              loader={this.props.loader}
              isMostPopular={true}
            />
            </Suspense>
            ) : ""}
          </div>
          {/*<Chair />*/}
          {/* </div>*/}
        </section>
        {/* <section className="mt-5 pt-5">
          <div className="container">
            <div className="row heading-div">
              <div className="col-md-12 text-center">
                <h1 className="heading">Sale</h1>
              </div>
            </div>
            {this.state.sale
              ? this.state.sale.map((item, i) => {
                const imageUrl = window.innerWidth >= 650 ? item.image : item.mob_image1;
                const imageUrl2 = window.innerWidth >= 650 ? item.image1 : item.mob_image2;
                return (
                  <>
                   <section className="mt-5 pt-5">
                  <div className="container"> 
                    <div className={"row mt-2 " + (i === 1 ? "flex-row-reverse" : "")}>
                      <div
                        className="col-md-6 text-center p-lg-0 p-md-0 pb-3"
                      >
                        <img src={imageUrl} alt="image1" className="img-fluid" />
                      </div>
                      <div
                        className="col-md-6 text-center p-lg-0 p-md-0 pb-3"
                      >
                        <img src={imageUrl2} alt="image2" className="img-fluid" />
                      </div>
                      <div
                        className="backgroundimagesize backgroundimagesize1 col-md-6 text-white pr-lg-0 d-flex align-items-center backgroundimg"
                        style={{ backgroundImage: `url(${item.image1})`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }}
                      >
                        <div className="p-3">
                          <h4 className="mt-3">
                             <b>{item.title}</b>
                          </h4>
                          <p>
                            {item.description}
                          </p>
                          <a className="view_btn p-2" href={item.link} target="_blank"><small>VIEW IN STOCK SETS</small></a>
                        </div>
                      </div>
                    </div>
                    </div>
                </section> 
                  </>
                )
              })
              : null}
          </div>
        </section> */}
        {/* <section className="mt-5 pt-5">
          <div className="container">
            <div className="row heading-div">
              <div className="col-md-12 text-center">
                <h1 className="heading">Testimonials</h1>
                <p>The furniture our customers love:</p> 
              </div>
            </div>
            <TestimonialComponent />
          </div>
        </section> */}
        {/* <!-- sale banner end -->
<!-- single banner start --> */}
        <section className="">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 text-center pr-lg-0">
              <LazyLoadImage src="assets/img/review.jpeg"
                placeholderSrc={PlaceholderImage}
                alt={'review'}
                style={{ width: '70%' }}
                className="img-fluid"
              />
                {/* <img src="assets/img/review.jpeg" className="img-fluid" style={{ width: '70%' }} /> */}
              </div>
              <div
                className="col-md-6  pl-lg-0 d-flex mar_15"
                style={{ alignItems: 'center' }}
              >
                <div style={{ padding: "0 15px 0 15px" }}>
                  <h4 >
                    <b>Client Reviews</b>
                  </h4>
                  <p style={{ marginTop: "30px" }}>
                    We have delivered furniture to over 1500 happy customers across India and have over 300+ 5 star reviews from happy customers.
                  </p>
                  <Link to="/AllReviews">  <button className="view_btn " style={{ bottom: "0", float: "right" }}>View Reviews</button></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
//   );
// }
export default React.memo(Home);
