import axios from "axios";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";

export default function Register(props) {
  const token = props.token === "" ? "" : props.token;
  // alert("token_id", token)
  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const loginData = new FormData(e.target);
      loginData.append("token", token);
      const response = await axios.post(
        baseUrl+"Users/login",
        loginData
      );
      console.log("login", response);
      if (response.data.status === 201 || response.data.code === 201) {
        document.querySelector("#loginError").innerHTML = response.data.message;
      } else if (response.data.status === 200 || response.data.code === 200) {
        document.querySelector("#loginError").innerHTML =
          "User successfully login";
        localStorage.setItem("create_outdoor_email", response.data.email);
        localStorage.setItem("create_outdoor_password", response.data.password);
        localStorage.setItem("create_outdoor_name", response.data.user_name);
        props.changePopupText("User successfully login")
        props.checkAndSetCredentials();
      }
    } catch {
      console.log("Issue in login");
    }
  };
  const registerUser = async (e) => {
    e.preventDefault();
    const registerData = new FormData(e.target);
    registerData.append("token", token);
    try {
      
      const response = await axios.post(
        baseUrl+"Users/user_register",
        registerData
      );
      console.log("Register", response);
      // if(response.status===200 || response.code===200) {
      //   document.querySelector("#registerError").innerHTML="User successfully registered & login";
      if (response.data.status === 201 || response.data.code === 201) {
        document.querySelector("#registerError").innerHTML =
          response.data.message;
      } else if (response.data.status === 200 || response.data.code === 200) {
        document.querySelector("#registerError").innerHTML =
          "User successfully registered & login";
        localStorage.setItem("create_outdoor_email", response.data.email);
        localStorage.setItem("create_outdoor_password", response.data.password);
        localStorage.setItem("create_outdoor_name", response.data.user_name);
        props.checkAndSetCredentials();
        props.changePopupText("User successfully register & login")
      }
    } catch {
      console.log("Issue in register");
    }
  };
  
  if (
    !localStorage.getItem("create_outdoor_email") ||
    !localStorage.getItem("create_outdoor_name") ||
    !localStorage.getItem("create_outdoor_password")
  ) {
    return (
      <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
          <div className="row">
            <div className="col-md-12 text-center mb-5">
              <h2 className="title mb-5">CUSTOMER LOGIN/SIGNUP</h2>

                      <Link
                        to={{
                          pathname: "/checkout",
                          state: { token: token},
                        }}
                      >
              <button
                  type="Button"
                  style={{
                    backgroundColor: "rgb(0,0,0)",
                    color: "white",
                    borderRadius: "0",
                  }}
                  className="btn"
                > <i className="fa fa-lock mr-3" aria-hidden="true"></i>
                  Proceed As Guest
                </button>
                </Link>
            </div>
            <div className="col-md-6">
              <h6>REGISTERED CUSTOMERS</h6>
              <hr />
              <p
                id="loginError"
                className="alert alert-secondary alert-dismissible fade show mb-4"
              >
                If you have an account, sign in with your email address.
              </p>
              <form
                id="loginform"
                className="form"
                onSubmit={(e) => loginUser(e)}
              >
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="mb-4"
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="mb-4"
                  minLength="6"
                  // maxLength="10"
                  required
                />
                <Link to="/forget-password">
                <p className="text-left ml-1" style={{color: "rgba(0, 0, 0, 0.3)", fontSize:".8rem", fontWeight: "400"}}>Forgot password?</p>
                </Link>
                <button
                  type="submit"
                  style={{
                    backgroundColor: "rgb(0,0,0)",
                    color: "white",
                    borderRadius: "0",
                  }}
                  className="btn"
                >
                  Login
                </button>
              </form>
            </div>
            <div className="col-md-6 mt-lg-0 mt-md-0 mt-5">
              <h6>NEW CUSTOMERS</h6>
              <hr />
              {/* <p className="font_monst gry_col mb-4">
              
            </p> */}
              <p
                id="registerError"
                className="alert alert-secondary alert-dismissible fade show mb-4"
              >
                Creating an account has many benefits: check out faster, keep
                more than one address, track orders and more.
              </p>
              <form className="form" onSubmit={(e) => registerUser(e)}>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  className="mb-4"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="mb-4"
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="mb-4"
                  minLength="6"
                  // maxLength="10"
                  required
                />

                <button
                  type="submit"
                  style={{
                    backgroundColor: "rgb(0,0,0)",
                    color: "white",
                    borderRadius: "0",
                  }}
                  className="btn"
                >
                  Create an account
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <Redirect to="/" />;
  }
}
