import React, { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from "./ProductCardComponent";
import { baseUrl } from "../baseURL";
// import { ProductConsumer } from "../context";
export default function AllProduct(props) {
  // const api_id = props.location.state;
  const api_id2 = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );
  console.log("api_id2", api_id2);
  const [Allproduct, setAP] = useState([]);
  // const [productMRP, setMRP] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState("");
  const [headingDescription, setHeadingDescription] = useState("");
  const [emptyMessage, setEmptyMessage] = useState("");
  const [FilterName, setFN] = useState({
    leadtime: [],
    type: [],
    seating: [],
    shape: [],
    feature: [],
  });
  const [leadtime, setLeadTime] = useState("");
  const [type, setType] = useState("");
  const [seating, setSeating] = useState("");
  const [shape, setShape] = useState("");
  const [feature, setFeature] = useState("");
  // const [filterApiInput, setFAI] = useState({leadtime:"", type:"" , seating:"", shape:"", feature:""});
  async function fetchAllProductData() {
    try {
      // console.log("api_id1", api_id);
      var subcategoryData = new FormData();
      subcategoryData.append("subcategory_id", api_id2);
      const data = await axios.post(
        baseUrl + "Apicontroller/get_all_products/",
        subcategoryData
      );
      console.log("AP: ", data);
      setEmptyMessage("");
      // setMRP(data.data.data[0].type[0].type_mrp);
      setSubcategoryName(data.data.subcategory);
      setHeadingDescription(data.data.text)
      setAP(data.data.data);
    } catch {
      console.log("Issue in all product");
    }
  }
  useEffect(() => {
    fetchAllProductData();
  }, [api_id2]);
  useEffect(() => {
    async function fetchFilterNameData() {
      try {
        const response = await axios(
          baseUrl + "Apicontroller/get_filter_name/"
        );
        console.log("Filter name:", response.data.data, response.data.status);
        if (response.data.status === 200) {
          setFN(response.data.data);
        }
        // setAP(response.data.data);
      } catch {
        console.log("Issue in filter name");
      }
    }
    fetchFilterNameData();
  }, [api_id2]);
  const setFilters = (e) => {
    // alert(e.target.checked);
    console.log("e k andar", e.target.attributes.filterid, e.target.id);
    const filterId = document
      .getElementById(e.target.id)
      .getAttribute("filterId");
    const filterName = document
      .getElementById(e.target.id)
      .getAttribute("filterName");
    // const filterApiInputTemp = {...filterApiInput};
    var filterApiInputTemp = {};
    if (filterName === "leadtime") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (leadtime === "") {
          setLeadTime(filterId);
        } else {
          let newdata = leadtime + "," + filterId;
          setLeadTime(newdata);
        }
      } else {
        if (leadtime !== "") {
          let prevArray = leadtime.split(",");
          let index = leadtime.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            leadtime.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setLeadTime(newdata);
        }
      }
    }
    if (filterName === "type") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (type === "") {
          setType(filterId);
        } else {
          let newdata = type + "," + filterId;
          setType(newdata);
        }
      } else {
        if (type !== "") {
          let prevArray = type.split(",");
          let index = type.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            type.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setType(newdata);
        }
      }
    }
    if (filterName === "seating") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (seating === "") {
          setSeating(filterId);
        } else {
          let newdata = seating + "," + filterId;
          setSeating(newdata);
        }
      } else {
        if (seating !== "") {
          let prevArray = seating.split(",");
          let index = seating.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            seating.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setSeating(newdata);
        }
      }
    }
    if (filterName === "shape") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (shape === "") {
          setShape(filterId);
        } else {
          let newdata = shape + "," + filterId;
          setShape(newdata);
        }
      } else {
        if (shape !== "") {
          let prevArray = shape.split(",");
          let index = shape.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            shape.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setShape(newdata);
        }
      }
    }
    if (filterName === "feature") {
      if (e.target.checked) {
        console.log("Add this filter api:", filterName, filterId);
        if (feature === "") {
          setFeature(filterId);
        } else {
          let newdata = feature + "," + filterId;
          setFeature(newdata);
        }
      } else {
        if (feature !== "") {
          let prevArray = feature.split(",");
          let index = feature.split(",").indexOf(filterId);
          if (index > -1) {
            prevArray.splice(index, 1);
          }
          console.log(
            "index when removing",
            index,
            feature.split(","),
            prevArray,
            prevArray.join()
          );
          let newdata = prevArray.join();
          setFeature(newdata);
        }
      }
    }
  };
  const applyingFilterAPI = async () => {
    if (
      leadtime !== "" ||
      type !== "" ||
      feature !== "" ||
      seating !== "" ||
      shape !== ""
    ) {
      console.log("Applying filter api");
      const appliedFilterData = new FormData();
      appliedFilterData.append("leadtime_id", leadtime);
      appliedFilterData.append("furniture_type_id", type);
      appliedFilterData.append("seating_id", seating);
      appliedFilterData.append("shape_id", shape);
      appliedFilterData.append("feature_id", feature);
      for (let pair of appliedFilterData.entries()) {
        console.log(pair[0], ":", pair[1]);
      }
      try {
        const response = await axios.post(baseUrl + "Apicontroller/filter");
        console.log("filtered product data", response);
        if (response.data.status === 201) {
          // alert(response.data.message);
          setAP([]);
          setEmptyMessage("No product available.");
        } else {
          console.log("filtered product data", response.data.data);
          setAP(response.data.data);
          setEmptyMessage("");
        }
      } catch {
        console.log("Issue in get filterd data");
      }
    } else {
      fetchAllProductData();
    }
  };
  const [sortType, setSortType] = useState("None");
  const sortProducts = (type) => {
    if(type!==sortType){
      setSortType(type)
      if(type==="Price - Low to High"){
        // console.log("Price - Low to High", Allproduct[0].type[0].type_price)
        const tempData = [...Allproduct];
        tempData.sort((a,b)=> a.type[0].type_price-b.type[0].type_price)
        // console.log("Sorted", tempData[1].type[0].type_price)
        setAP(tempData)
      } else if(type==="Price - High to Low") {
        // console.log("Price - Low to High", Allproduct[0].type[0].type_price)
        const tempData = [...Allproduct];
        tempData.sort((a,b)=> a.type[0].type_price-b.type[0].type_price)
        // console.log("Sorted", tempData[1].type[0].type_price)
        tempData.reverse()
        setAP(tempData)
      } else{
        fetchAllProductData()
      }
    } 
  }
  const [productsToShow, setProductsToShow] = useState(20);
  const showMoreProducts = () => {
    let totalNewProduct = productsToShow + 20;
    let num = Allproduct.length;
    if (totalNewProduct < num) {
      setProductsToShow(totalNewProduct);
    } else {
      setProductsToShow(num);
      document.getElementById("showmoreproducts").style.display = "none";
    }
  };
  return (
    // <ProductConsumer>
    //   {(value) => {
    // const { token, email, password } = value;
    // return (
    <>
      <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
          <div className="row">
            <div className="col-md-12 text-center heading-div">
              <h1 className="heading">{subcategoryName}</h1>
              <p>{headingDescription}</p>
            </div>
          </div>
          <div className="row">
            <div className="dropdown show ">
              <span
                className="btn dropdown-toggle clr"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ background: 'rgb(0, 0, 0)'}}
              >
                Sort by <b>{sortType}</b>
              </span>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <span className="dropdown-item" onClick={(e)=> sortProducts(e.target.innerHTML)}>None</span>
                <span className="dropdown-item" onClick={(e)=> sortProducts(e.target.innerHTML)}>Price - Low to High</span>
                <span className="dropdown-item" onClick={(e)=> sortProducts(e.target.innerHTML)}>Price - High to Low</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="filter_img mt-5">
        <div className="container">
          <div className="row">
            {Allproduct
              ? Allproduct.slice(0, productsToShow)?.map((elem, i) => {
                  return (
                    <>
                      <ProductCard
                        product_id={elem.product_id}
                        productimage={elem.image}
                        productname={elem.product_name}
                        product_url={elem.product_url}
                        tag={elem.tag}
                        mrp={elem.type[0].type_mrp}
                        price={elem.type[0].type_price}
                        addingToCartFunction={props.addingToCartFunction}
                        type_id={elem.type[0].type_id}
                        avg={elem.avg}
                        rvCount={elem.rv_count}
                      />
                    </>
                  );
                })
              : null}
          </div>
          <div className="row">
            {Allproduct?Allproduct.length > 20 ? (
              <div className="col-12 col-md-12 text-center">
                <button
                  className="btn"
                  id="showmoreproducts"
                  onClick={() => showMoreProducts()}
                  style={{ background: "rgb(0, 0, 0)", color: "white" }}
                >
                  Show more
                </button>
              </div>
            ) : null:null}
            <div className="col-12 col-md-12 text-center">{emptyMessage}</div>
          </div>
        </div>
      </section>
    </>
    //     );
    //   }
    //   }
    // </ProductConsumer>
  );
}
