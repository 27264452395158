import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { Toast } from "react-bootstrap";
import { baseUrl } from "../baseURL";


export default function Next(props) {
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cartDetails, setCD] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [calculatedData, setCalD] = useState({});
  const [showPopUp, setSPU] = useState(true);
  const [popUpMessage, setPUM] = useState("");
  const [inpPayOpt, setInpPayOpt] = useState("1");
  //  const [payment,setPayment] = useState("");
  // const [promoErrMessage, setPromoErrMessage] = useState("Apply promo code");
  const toggleShowPopUp = () => setSPU(!showPopUp);

  console.log('Hi, I am rendering...')

  var [promoCode, setPC] = useState("");
  var [cartLength, setCL] = useState(0);
  useEffect(() => {
    // Getting email, password and token from local storage
    const user_token = localStorage.getItem("user_token");
    const user_email_id = localStorage.getItem("create_outdoor_email");
    const user_password = localStorage.getItem("create_outdoor_password");

    // If login/Registered
    if (user_email_id && user_password) {
      // alert("Email and password are all set");
      setToken(user_token);
      setEmail(user_email_id);
      setPassword(user_password);
    }

    // If no token in local storage
    if (!user_token) {
      fetch(
        baseUrl + "/Apicontroller/Token/get_token"
      ).then((resp) => {
        resp.json().then((result) => {
          localStorage.setItem("user_token", result.token);
          setToken(result.token);
          console.log("token", token);
        });
      });
    }
    //  if token in local storage
    else {
      console.log("user_tok", user_token);
      console.log("token", token);
      // this.setState({ token: user_token });
      setToken(user_token);
    }
  }, [token, email, password]);
  // Get Cart Data function
  const getCartData = async () => {
    try {
      var getCartData = new FormData();
      getCartData.append("token_id", token);
      getCartData.append("email_id", email);
      getCartData.append("password", password);
      // alert("getCartData", getCartData);
      for (var pair of getCartData.entries()) {
        console.log("getCartData is heres", pair[0] + ", " + pair[1]);
      }
      const response = await axios.post(
        baseUrl + "Apicontroller/get_cart_data",
        getCartData
      );
      console.log("Cart Data agaya", response.data);
      console.log("setSubTotal", response.data.subtotal);
      if (response.data.subtotal !== undefined) {
        setSubTotal(response.data.subtotal);
      } else {
        setSubTotal(0);
      }
      setCD(response.data.data);
    } catch {
      console.log("Issue in getting cart data");
    }
  };
  useEffect(() => {
    async function getCartLength() {
      try {
        const data = await props.countCartLengthFunction();
        console.log("Length", data);
        if (data !== undefined) {
          setCL(data);
        }
        var payment_type_input = document.querySelector(
          'input[name="payment_type"]:checked'
        ).value;
        localStorage.setItem("payment_type_input", payment_type_input);
      } catch {
        console.log("Issue in getting cart length.");
      }
    }
    getCartLength();
    getCartData();
  }, [subTotal, email, password, token, cartLength]);
  useEffect(() => {
    const calculatedData = JSON.parse(
      localStorage.getItem("create_outdoor_calculations")
    );
    // console.log("cal",calculatedData);

    setCalD(calculatedData);
  }, [calculatedData]);
  const applyPromoCode = async () => {
    var promocodeInput = document.querySelector("#promocodeInput").value;
    // alert(promocodeInput);
    if (promocodeInput !== "") {
      // alert("hi from apply 2");
      // e.preventDefault()
      var applyPromoCodeData = new FormData();
      applyPromoCodeData.append("token_id", token);
      applyPromoCodeData.append("email", email);
      applyPromoCodeData.append("txn_id", calculatedData.txn_id);
      applyPromoCodeData.append("promocode", promocodeInput);
      applyPromoCodeData.append("password", password);

      // alert("getCartData", getCartData);
      for (var pair of applyPromoCodeData.entries()) {
        console.log("applyPromoCodeData is heres", pair[0] + ", " + pair[1]);
      }
      try {
        const response = await axios.post(
          baseUrl + "Apicontroller/apply_promocode",
          applyPromoCodeData
        );
        if (response.data.status === 201) {
          // alert(response.data.message);
          setSPU(true)
          setPUM(response.data.message)
          setTimeout(() => {
            setPUM("")
          }, 3000)
        } else if (response.data.status === 200) {
          // alert(JSON.stringify(response.data.data));
          calculatedData.sub_total = response.data.data.sub_total;
          calculatedData.total = response.data.data.total;
          calculatedData.promocode_id = response.data.data.promocode_id;
          calculatedData.charges = response.data.data.charges;
          calculatedData.advance_amount = response.data.data.advance_amount;
          calculatedData.promocode_discount =
            response.data.data.promocode_discount;
          const newData = JSON.stringify(calculatedData);
          localStorage.setItem("create_outdoor_calculations", newData);
          console.log("Apply Promo Code Response", response.data);
          setPC(promocodeInput);
          document.querySelector("#promocodeInput").value = "";
          // document.getElementById("promocodeBoxBefore").style.display="none";
          document.getElementById("promocodeBoxAfter").style.display =
            "table-row";
        }
      } catch {
        document.querySelector("#promocodeInput").value = "";
        // alert("Error! please try again later.");
        console.log("Issue in promo code api.");
        document.getElementById("promocodeBoxAfter").style.display =
          "table-row";
      }
    }
  };

  let radioChangeHandler = (e) => {
    //  alert(`hi, ${e.target.value}!`);
    localStorage.setItem("payment_type_input", e.target.value);
    setInpPayOpt(e.target.value)
  }

  const removePromocode = async () => {
    // alert("Remove Promo code");
    var removePromoCodeData = new FormData();
    removePromoCodeData.append("token_id", token);
    removePromoCodeData.append("email", email);
    removePromoCodeData.append("password", password);
    removePromoCodeData.append("txn_id", calculatedData.txn_id);
    removePromoCodeData.append("promocode_id", calculatedData.promocode_id);

    // alert("getCartData", getCartData);
    for (var pair of removePromoCodeData.entries()) {
      console.log("removePromoCodeData is heres", pair[0] + ", " + pair[1]);
    }
    try {
      const response = await axios.post(
        baseUrl + "Apicontroller/promocode_remove",
        removePromoCodeData
      );
      if (response.data.status === 201) {
        // alert(response.data.message);
        setPUM(response.data.message);
        setTimeout(() => {
          setPUM("")
        }, 3000)
      } else if (response.data.status === 200) {
        // alert(JSON.stringify(response.data.data));
        calculatedData.sub_total = response.data.data.sub_total;
        calculatedData.advance_amount = response.data.data.advance_amount;
        calculatedData.promocode_id = null;
        calculatedData.promocode_discount =
          response.data.data.promocode_discount;
        const newData = JSON.stringify(calculatedData);
        localStorage.setItem("create_outdoor_calculations", newData);
        console.log("Promo Code Response", response.data);
        document.getElementById("promocodeBoxBefore").style.display =
          "table-row";
        document.getElementById("promocodeBoxAfter").style.display = "none";
      }
    } catch {
      console.log("Issue in promo code api.");
    }
  };
  const paymentTypeHandle = (e) => {
    // alert(e.target.value)
    // console.log("paymentTypeHandle", e.target.id);
  };
  const checkout = async () => {
    // console.log("Props in checkout", props)

  };
  // if(localStorage.getItem("create_outdoor_email")!=="" && localStorage.getItem("create_outdoor_email")!==null && localStorage.getItem("create_outdoor_email")!==undefined )
  if (localStorage.getItem("user_token")) {
    return (<>
      {popUpMessage !== "" ? (
        <Toast
          className="popupboxInPromoCode"
          // style={{ top: "10rem",zIndex: "500",left: "3rem",fontSize: "1.2rem",position: "fixed" }}
          show={showPopUp}
          onClose={toggleShowPopUp}
        >
          <Toast.Header style={{ background: "rgb(0, 0, 0)", color: "white", marginTop: "15px" }}>
            <span>{popUpMessage}</span>
          </Toast.Header>
        </Toast>
      ) : (
        ""
      )}
      <section
        className="filter filtersection pt-lg-3 mt-md-0 mt-sm-5 pt-sm-0 accordion"
        id="accordionExample"
      >
        <div className="container mt-sm-5 pt-sm-5 mt-xs-5 pt-xs-5">
          <div className="row heading-div">
            <div className="col-md-12 d-flex">
              <div className="text-center fs-2 col-md-4">
                <span>Cart</span>{" "}
                <i
                  style={{ color: "rgb(0, 0, 0)" }}
                  className="fa fa-check-circle"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="fs-2 text-center col-md-4">
                <span>Address</span>{" "}
                <i
                  style={{ color: "rgb(0, 0, 0)" }}
                  className="fa fa-check-circle"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="fs-2 col-md-4 text-center">
                <span>Checkout</span>{" "}
                <i
                  style={{ color: "rgb(0, 0, 0)" }}
                  className="fa fa-check-circle"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>
                Your selection <b>( {cartLength} item )</b>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <table className="cart_table">
                <tbody style={{ textAlign: "center" }}>
                  <tr
                    className="mb-4 mb-lg-0 not_show"
                    style={{ background: "#edeee6" }}
                  >
                    {/* <th className="pt-2 pb-2"></th> */}
                    <th className="pt-2 pb-2"></th>
                    <th className="pt-2 pb-2">PRODUCT</th>
                    <th className="pt-2 pb-2">PRICE</th>
                    <th className="pt-2 pb-2">QUANTITY</th>
                    {/* <th className="pt-2 pb-2"></th> */}
                  </tr>{" "}
                  {cartDetails
                    ? cartDetails.map((elem, i) => {
                      return (
                        <tr className="mb-4 mb-lg-0">
                          {/* <td className="pl-1">
                                    <img
                                      onClick={() => removeFromCart(elem.product_id, elem.type_id)}
                                      style={{ width: "25px" }}
                                      src="assets/img/cut.png"
                                      alt=""
                                    />
                                  </td> */}
                          <td>
                            <img
                              className="pt-3 pb-3 "
                              src={elem.product_image}
                              alt=""
                            />
                          </td>
                          <td style={{ width: "228px" }}>
                            {elem.product_name}<br></br>{elem.sample == 1 ? <span style={{ fontSize: "12px" }}>(Fabric Sample)</span> : ""}
                          </td>
                          <td><span className="desctext" >Price:</span>
                            <span><i className="fa fa-inr rupay"></i> </span>
                            {elem.price}
                          </td>
                          <td><span className="desctext" >Quantity:</span>
                            <span className="quant">{elem.quantity}</span>
                          </td>
                          {/* <td></td> */}
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="col-md-4 mt-5 mt-lg-0">
              <table className="cart_table side_cart">
                <tr style={{ background: "#edeee6" }}>
                  <th className="pt-2 pb-2 pl-2 pr-2" colspan="3">
                    Order Summary <span>({cartLength} item)</span>
                  </th>
                  {/* <th></th> */}
                </tr>
                <tr>
                  <td>Price</td>
                  <td>
                    <span><i className="fa fa-inr pprice"></i> </span>
                    {calculatedData.total}
                  </td>
                </tr>

                {/* <tr>
                                <td>GST</td>
                                <td><span><i className="fa fa-inr"></i>	</span>50.00</td>
                            </tr> */}


                <tr>
                  <td colspan="4">
                    <input
                      type="radio"
                      id="COD"
                      name="payment_type"
                      value="1"
                      // checked={inpPayOpt === "1"}
                      // onChange={(e)=> checkout(e)}
                      onChange={(e) => radioChangeHandler(e)}
                    />{" "}
                    <label for="COD">COD</label>
                    <span className="ml-3" style={{ fontSize: "12px" }}>(Pay only <b>₹{calculatedData.advance_amount}</b> now as advance and rest pay later)</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <input
                      type="radio"
                      id="OP"
                      name="payment_type"
                      value="2"
                      // checked={inpPayOpt === "2"}
                      //  onChange={(e)=> checkout(e)}
                      onChange={(e) => radioChangeHandler(e)}
                    />{" "}
                    <label for="OP">ONLINE PAYMENT</label>
                  </td>
                </tr>


                <tr>
                  <td>Delivery Charge</td>
                  <td>
                    <span><i className="fa fa-inr dcharge"></i> </span>
                    {calculatedData.charges}
                  </td>
                </tr>
                <tr>
                  <td>Promocode Discount</td>
                  <td>
                    <span><i className="fa fa-inr pdiscout"></i> </span>
                    {calculatedData.promocode_discount}
                  </td>
                </tr>
                <tr>
                  <td>Order Total</td>
                  <td>
                    <span><i className="fa fa-inr ototal"></i> </span>
                    {calculatedData.sub_total}
                  </td>
                </tr>

                <tr id="promocodeBoxAfter" style={{ display: "none" }}>
                  <td>Promocode Applied</td>
                  <td>
                    <button
                      className="btn"
                      style={{ background: "rgb(0, 0, 0)", color: "white" }}
                    >
                      <i
                        onClick={() => removePromocode()}
                        className="fa fa-times-circle"
                      ></i>{" "}
                      {promoCode}
                    </button>
                  </td>
                </tr>
                <tr id="promocodeBoxBefore">
                  <td>
                    <input
                      type="text"
                      name="promocode"
                      id="promocodeInput"
                      // onChange={(e) => {
                      //   setPC(e.target.value);
                      // }}
                      defaultValue=""
                      placeholder="Promo Code"
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="applyPromoCodeBtn btn btn btn-primary"
                      style={{ background: "rgb(0, 0, 0)", color: "white" }}
                      onClick={() => applyPromoCode()}
                    >
                      Apply
                    </button>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="bor_ra p-0" colspan="3">
                    {/* {/ <a href="checkout.html"> /} */}
                    <Link to="/place">
                      <button
                        className="cart_btn h-auto mob_btn"
                        onClick={() => checkout()}
                        style={{ width: "100%", }}  >
                        <i className="fa fa-lock mr-3" aria-hidden="true"></i>SECURE
                        PAYMENT
                      </button>
                      { /*                     <button
                      className="cart_btn h-auto mob_btn"
                      onClick={() => checkout()}
                      style={{ width: "100%", background: "black" }}
                    >
<i className="fa fa-lock mr-3" aria-hidden="true"></i>ONLINE PAYMENT                    </button>*/}
                      {/* {/ </a> /} */}
                    </Link>
                  </td>
                </tr>
              </table>
              {/* <div className="row mt-4">
                <div className="col-5 m-auto">
                  <img src="../assets/img/norton_logo.png" />
                </div>
                <div className="col-5 m-auto">
                  <img src="../assets/img/ssl_logo.png" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
    );
  } else {
    return <Redirect to="/register" />;
  }
}
