import React from 'react'

function PrivacyPolicyComp() {
    return (
        <div className='container'>
             <div className="row heading-div mt-5 pt-4">
        <div className="col-md-12 text-center">
          <h1 className="heading mt-5">Privacy& Policy</h1>
        </div>
      </div>
      <div>
      <div className="col-md-10 ml-3">
      <h4 className="pt-1 mb-0 mt-3">
      Respecting Your Privacy
        </h4>
        </div>
        <div className="col-md-10 ml-3">
        <p className="pt-1 mb-0 mt-1">
        Create Spaces (Reva Creation) is committed to respect your privacy. Therefore we set the highest ethical standards to protect your sensitive information. This privacy policy describes the type of information we collect, why we will collect it and with whom we will share it.
          </p>
          <p className="pt-1 mb-0 mt-1">
          We value the trust you have paced in us, please read the document regarding our privacy policy.  If you have any queries about our privacy policy, please contact our Customer Support.
          </p>
          </div>
          <div className="col-md-10 ml-3">
      <h4 className="pt-1 mb-0 mt-3">
      Type of information we collect
        </h4>
        </div>
        <div className="col-md-10 ml-3">
        <p className="pt-1 mb-0 mt-1">
        1. We may collect your contact details, such as your name, email address, phone number, postal address and delivery address (if different). 
          </p>
          <p className="pt-1 mb-0 mt-1">
         2. We may require your banking details such as credit card number, card holder name, expiration date and CVV or other details necessary for Internet banking. 
          </p>
          <p className="pt-1 mb-0 mt-1">
          <p className="pt-1 mb-0 mt-1">
         3. We may require your banking details such as credit card number, card holder name, expiration date and CVV or other details necessary for Internet banking. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         4.We may also collect your demographic information (gender, age, income) to conduct optional online surveys to asses our services. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         5. We will collect your session and log in information, which include your IP address, operating system, browser type, browser language, date and time of request. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         6. We will also collect sign in information for third party accounts shared on our website such as, Facebook, Twitter, Pinterest etc. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         7. We shall ask for email communication by any user, for product inquiry, order and transaction details, for any complaints and disputes etc. 
          </p>
          </p>
          </div>
          <div className="col-md-10 ml-3">
      <h4 className="pt-1 mb-0 mt-3">
      How we use or share it
        </h4>
        </div>
        <div className="col-md-10 ml-3">
        <p className="pt-1 mb-0 mt-1">
        1. Our affiliates and partners that are involved in buying and delivering process, shall use your information. In case of a merger or acquisitions, other business entities may use your information. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         2. An aggregated form of information may be used to understand the consumer behaviour so that we can improve the shopping experience. Our business and marketing activities may also use the demographical profiles of our users. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         3.To tell you about deals, offers, new launches, shipment status, informational and promotional content, we will use your contact information. There is an opt-out alternative for such services. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         4. Our cookie policy is used to customize your experience, we may use the information stored in your browser to let you see the recently viewed items, your wish list, cart features etc. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         5. To understand the consumer behaviour, buying preferences and to analyse how user interaction is being forwarded, we will use traffic flow and session login information. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         6. User engagement and time spent by each user on our site accessed with the help your IP address and traffic source information. We may use it to measure the performance. 
          </p>
          <p className="pt-1 mb-0 mt-1">
         7. User information may be required in case of legal disputes, regulatory or statutory requirements, troubleshoot problems, collect fees owed, detect or protect against errors, prevent abuse of our services, prevent violation of rights of third parties, enforce our terms and conditions. 
          </p>
          </div>
          <div className="col-md-10 ml-3">
      <h4 className="pt-1 mb-0 mt-3">
      Cookie Policy
        </h4>
        </div>
        <div className="col-md-10 ml-3">
        <p className="pt-1 mb-0 mt-1">
        For enhanced shopping experience, Create Spaces (Reva Creation) uses cookies to store your browsing information. Cookies do not save your PII (personally identifiable information), though you can change the cookie settings as per your preferences. By using the website, we believe that you consent with the cookie policy.
          </p>
          </div>
          <div className="col-md-10 ml-3">
      <h4 className="pt-1 mb-0 mt-3">
      User Consent
        </h4>
        </div>
        <div className="col-md-10 ml-3">
        <p className="pt-1 mb-0 mt-1">
        When you access the website or fill in your details to buy the products or services offered at website, Create Spaces (Reva Creation) will accept it as your consent with the privacy policy. For user who are not comfortable with the clause and conditions to share their personally identifiable information, it is requested to abort the use of the website. We also provide an opt-out option to our registered users to unsubscribe all the non-essential mails and communication from us and our partners
          </p>
          </div>
        </div>
        </div>
    )
}

export default PrivacyPolicyComp
