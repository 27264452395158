import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../baseURL";
export default function Footer(props) {
  const subscribe = async () => {
    // alert("fviu")
    const subscriptionEmail =
      document.getElementById("subscriptionEmail").value;
    const subscribeData = new FormData();
    subscribeData.append("email", subscriptionEmail);
    try {
      const response = await axios.post(
        baseUrl + "/Apicontroller/subscribe_us",
        subscribeData
      );
      console.log("Subscribe: ", response);
      if (response.data.status === 200) {
        props.changePopupText("you have successfully subscribed for our newsletter");
      } else {
        props.changePopupText("Already subscribed");
      }
    } catch {
      console.log("Issue in subscribe api");
    }
  };
  const paymentURl = window.innerWidth >= 650 ? "/razor.png" : "/razor_mob.png";
  return (
    <footer className="mt-5 footer">
      <div className="container-fluid my__footer" id="myfooterlink">
        <a href="https://wa.me/918233406386" target="_blank" rel="noreferrer" className="whatsappIcon">
          <i className="fa fa-whatsapp whtIcon" aria-hidden="true"></i>
        </a>
        {/* <a href="https://wa.me/917728936121 " target="_blank" rel="noreferrer" className="whatsappIcon">
                <i className="fa fa-whatsapp whtIcon" aria-hidden="true"></i>
                </a> */}
        <div className="row ">
          <div className="col-md-5">
            <h4>
              <b>Subscribe to receive our monthly newsletter</b>
            </h4>
            <div className="mt-2 mb-5 d-flex align-items-center chng">
              <input
                type="email"
                name="email"
                id="subscriptionEmail"
                placeholder="Enter your email address"
              />
              <button className="view_btn ml-2" onClick={() => subscribe()}>
                Subscribe
              </button>
            </div>
          </div>
          <div className="col-md-7 cont">
            <h6 className="mb-0">
              All transactions are secure and encrypted
            </h6>
            <img src={paymentURl} alt="icons" className="img-fluid" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <h5>Our Story</h5>
            <ul className="pl-0" style={{ listStyle: 'none' }}>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/Gallary2">Image Gallery</Link>
              </li>
              <li>
                <Link to="/createspace">Why Create Spaces</Link>
              </li>
              {/* <li> 
                        <a href="#">
                            Brochure
                        </a>
                    </li> */}
              {/* <li>
                        <a href="#">
                            Careers
                        </a>
                    </li> */}
              {/* <li>
                        <a href="#">
                            Sustainability
                        </a>
                    </li> */}
            </ul>
          </div>
          <div className="col-md-2">
            <h5>HELP</h5>
            <ul className="pl-0" style={{ listStyle: 'none' }}>
              {/* <li>
                        <a href="#">
                        Deliveries & Returns
                        </a>
                    </li> */}
              <li>
                <Link to="/shippingreturn">Shipping, Return & Refunds</Link>
              </li>
              <li>
                <Link to="/term">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/privacy&policy">Privacy & Policy</Link>
              </li>
              {/*<li>
                        <a href="#">
                            Your Privacy &amp; Data
                        </a>
                    </li>*/}
              {/*<li>
                        <a href="#">
                            Business Enquiries
                        </a>
                    </li>*/}
              {/* <li>
                        <a href="#">
                            0% Finance
                        </a>
                </li>*/}
            </ul>
          </div>
          <div className="col-md-2">
            <h5>Information</h5>
            <ul className="pl-0" style={{ listStyle: 'none' }}>
              <li>
                <Link to="/export">Bulk Order</Link>
                {/* <a href="custom_order.html"></a> */}
              </li>
              {/*<li>
                    <Link to='/corporate-order'>
                    Corporate/Trade
                    </Link>
                         <a href="corporate.html">
                            Orders
                        </a> 
                    </li>*/}
              <li>
                <Link to="/corporate-order">Corporate/Trade Orders</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
              <li>
                <Link to="/Showroom">Showroom</Link>
              </li>
              {/* <li>
                             Projects & Testimonials
                    </li> */}
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Contact Us</h5>
            <ul className="pl-0" style={{ listStyle: 'none' }}>
              <li style={{ color: 'white' ,marginLeft:"63px" }}>Reva Creation</li>
              <li style={{ color: 'white' }}>GST No. : 08BYRPB8122J1Z5 </li>
              <li style={{ color: 'white' }}>Showroom Address: 178 Heera Rd., near DCM. Jaipur, 302021 </li>
              <li style={{ color: 'white' }}>(Please call us before visiting to ensure showroom is open on the day and time of visit)</li>
              <li style={{ color: 'white' }}>Factory Address: G-77 RICCO Industrial Area, Bagru, Jaipur  </li>
              <li style={{ color: 'white' }}>(Please book appointment before factory visit)</li>
              <li>
                <Link to="">Email-info@createspaces.in</Link>
              </li>
              <li>
                <Link to=''>
                  Mob.:- +91 8233406386
                </Link>
                {/* <Link to=''>
                    Mob.-7728936121
                    </Link> */}
              </li>
              {/* <li>
                             Projects & Testimonials
                    </li> */}
            </ul>
          </div>
          <div className="col-md-3 ml-auto">
            <h5>Social media links</h5>
            <ul className="pl-0 d-flex justify-content-start" style={{ listStyle: 'none' }}>
              <li>
                <a href="https://www.instagram.com/createluxuryspaces/">
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li style={{ marginLeft: 20 }}>
                <a href="https://www.facebook.com/createluxuryspaces">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              {/* <li>
                <a href="https://wa.me/919620986386">
                <i className="fa fa-whatsapp" aria-hidden="true"></i>
                </a>
              </li> */}
            </ul>
            {/* <!-- <div className="row  mt-5">
                    <div className="col-sm-6">
                    <a href="custom">
                        <div className="f_button1">
                             <button type="submit" className="btn btn-new">Custom Order</button>
                        </div>
                    </a>
                    </div>
                    <div className="col-sm-6">
                        <a href="corporate.html">
                            <div className="f_button2">
                                 <button type="submit" className="btn btn-new">corporate Order</button>
                            </div>
                        </a>
                    </div>
                </div> --> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-3">
            <div className="payment-methods d-flex">
              <p className="mb-0" style={{ color: '#fff' }}> © Copyright {(new Date().getFullYear())} CREATE SPACES</p>
              
              {/* } <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_visa.svg" alt="Visa" loading="lazy" />
                     <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_mastercard.svg" alt="Master card" loading="lazy" />
                     <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_maestro.svg" alt="Maestro" loading="lazy" />
            <img src="https://www.modafurnishings.co.uk/static/version0.0.0.114/frontend/Fluid/blank/en_GB/images/payment_methods/card_visa_electron.svg" alt="Visa Electron" loading="lazy" /> */}
            </div>
          </div>
          <div className="col-md-9 d-flex align-items-center justify-content-lg-end">
            <a href="https://www.fineoutput.com" target="_blank" rel="noopener noreferrer">
              <p className="mb-0">Developed by Fineoutput Technology</p>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
